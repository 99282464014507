<template>
  <div>
    <van-popup
        v-model:show="isShowDeposit"
        round
        class="gameListDialog"
        :safe-area-inset-bottom="true"
        position="bottom"
        @close="close"
        >
        <van-pull-refresh v-model="isload" :disabled="isRefreshDisable" @refresh="onRefresh">
        <div class="next liveRoomDeposit">
            <div class="title">
                <span>{{$t('my.menuList.recharge')}}</span>
                <div class="fixed" @click="close">
                    <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        p-id="12456" width="0.3rem" height="0.3rem">
                        <path
                            d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                            fill="#fff" p-id="12457"></path>
                    </svg>
                </div>

                <div class="gold flexCenterBL">
                    <img src="../../assets/img/gold.png" />
                    <span>{{ member && member.goldCoin ? numFormat(member.goldCoin) : '0' }}</span>
                </div>

                <div class="goldFont">{{$t('my.finance.myGold')}}</div>

                <!-- 文字滚动 -->
                <div class="noticeBar">
                    <van-notice-bar background="#f8b782" :left-icon="require('../../assets/img/my/next/noticeIcon.png')" speed="35" color="#c41f1a">
                        <!-- 需要数据获取拆分成数组 -->
                        <span v-for="(item, index) in notice" :key="`${index}--notice-home`" class="noticeBarItem" @click="showCont(item)" >{{item.content}}</span>
                    </van-notice-bar>
                </div>
            </div>

            <div class="rechargeItem" v-if="bankList.length > 0">
                <h2 class="noPad"><span></span>{{ $t('my.finance.payWay') }}</h2>
                <ul>
                    <li v-for="(item, index) in bankList" :key="`${index}--bankList`" :class="activeBank == index ? 'activeBank' : ''"
                        @click="bankItemClick(item, index)">
                        <img class="typeIcon" v-real-img="item.channelImage" />
                        <span>{{ item.channelVtName }}</span>
                        <img v-show="activeBank == index" class="icon" src="../../assets/img/record/activeBtn.png" />

                        <div class="hotFont" :class="`hotType${item.logs}`">{{ getHotType(item.logs) }}</div>
                    </li>
                </ul>
            </div>

            <!-- 银行卡 -->
            <div v-if="rechangeType == 9" style="background-color: #f6f6f6;">
                <div class="rechargeItem">
                    <h2><span></span>{{ $t('my.finance.depositAmount') }}</h2>

                    <div class="moneyBoxs" v-show="isEditMoney">
                        <van-field v-model.trim="money" id="moneyBox" label="₫" label-width="0.2rem"  @input="changeMoney" autocomplete="off" clearable
                            :placeholder="`${$t('my.finance.depositAmountTips')}`" />
                    </div>

                    <div class="fontTip">
                        <p class="noPad">{{$t('my.finance.amountRange')}}:
                            {{ numFormat(lowest) }}-{{ numFormat(highest) }}，{{$t('my.finance.amountRangeTips')}}</p>
                    </div>

                    <div class="addCardsTip">
                        <span v-for="(item, index) in numList" :key="item" class="btn" :class="numActive == index ? 'activeBtn' : ''"
                            @click="numItemClick(item, index)">
                            <!-- <img v-show="numActive == index" class="select" src="../../assets/img/record/activeBtn.png" /> -->
                            <p>{{ numFormat(item) }}đ</p>
                            <p>
                                <img class="gold" src="../../assets/img/gold.png" />
                                {{ numFormat(item / rechageRate) }}
                            </p>
                        </span>
                    </div>

                    <div class="bankDiscount flexCenter">
                        <img src="../../assets/img/my/next/bankDiscount.png" />
                        {{$t('my.finance.rechargeReward')}} {{bankList[activeBank].reward}} % , {{$t('my.finance.willReceive')}} {{ getGiftMenoy }} đ
                    </div>

                    <div class="barTitle">
                        <div class="flexCenter">
                            <div v-for="(item, index) in companyInfo" :key="`${index}--bar`" class="item" @click="companyInfoActive=item"
                                :class="{ 'active': companyInfoActive.cardNo && companyInfoActive.cardNo == item.cardNo }">
                                {{$t('my.finance.companyChargeCard')}} {{ index+1 }}
                            </div>
                        </div>
                    </div>

                    <div v-if="companyInfoActive.isShowInfo" class="subTitle">{{$t('my.finance.bankCardInfoCompany')}}</div>

                    <div class="bankCardInfoBox active" v-if="companyInfoActive.isShowInfo">
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.bankNo') }}： <span>{{ companyInfoActive.cardNo }}</span></div>
                            <div @click="copyClick(companyInfoActive.cardNo)">{{$t('common.copy')}}</div>
                        </div>
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.payeeName') }}：<span>{{ companyInfoActive.trueName }}</span></div>
                            <div @click="copyClick(companyInfoActive.trueName)">{{$t('common.copy')}}</div>
                        </div>
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.bank') }}： <span>{{ companyInfoActive.bankName }}</span></div>
                            <div @click="copyClick(companyInfoActive.bankName)">{{$t('common.copy')}}</div>
                        </div>
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.remitterMessage') }}： <span>{{ member.uid }}</span></div>
                            <div @click="copyClick(member.uid)">{{$t('common.copy')}}</div>
                        </div>
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.branch') }}： <span>{{ companyInfoActive.bankSub }}</span></div>
                        </div>

                        <img  class="bankActive" src="../../assets/img/record/activeBtn.png" />
                    </div>

                    <div v-if="companyInfoActive.isShowCode" class="subTitle">{{ $t('my.finance.bankCardCode') }}</div>

                    <div class="bankCardCodeBox" v-if="companyInfoActive.isShowCode">
                        <div class="bankCardCode" ref="bankCardCode">
                            <img class="bankCode"  v-real-img="companyInfoActive.code">
                        </div>
                        <img @click="saveImage(companyInfoActive.code)" class="download" src="../../assets/img/my/next/download.png">
                    </div>

                    <!-- <div v-for="(item, index) in companyInfo" :key="`${index}--companyInfo-myDeposit`" 
                        @click="companyInfoActive=item"
                        class="bankCardInfoBox" :class="{ 'active': companyInfoActive.cardNo == item.cardNo }">
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.bankNo') }}： <span>{{ companyInfoActive.cardNo == item.cardNo ? item.cardNo : '*****' }}</span></div>
                            <div @click="copyClick(item.cardNo)">{{$t('common.copy')}}</div>
                        </div>
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.payeeName') }}：<span>{{ companyInfoActive.cardNo == item.cardNo ? item.trueName : '*****' }}</span></div>
                            <div @click="copyClick(item.trueName)">{{$t('common.copy')}}</div>
                        </div>
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.bank') }}： <span>{{ companyInfoActive.cardNo == item.cardNo ? item.bankName : '*****' }}</span></div>
                            <div @click="copyClick(item.bankName)">{{$t('common.copy')}}</div>
                        </div>
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.remitterMessage') }}： <span>{{ companyInfoActive.cardNo == item.cardNo ?  member.uid : '*****' }}</span></div>
                            <div @click="copyClick(member.uid)">{{$t('common.copy')}}</div>
                        </div>
                        <div class="bankCardInfoBoxItem flexBetween">
                            <div>{{ $t('my.finance.branch') }}： <span>{{ companyInfoActive.cardNo == item.cardNo ? item.bankSub : '*****' }}</span></div>
                        </div>

                        <img v-show="companyInfoActive.cardNo == item.cardNo" class="bankActive" src="../../assets/img/record/activeBtn.png" />
                    </div> -->

                    <h2><span></span>{{$t('my.finance.rechargeSteps')}}</h2>
                    <div class="attention">
                        <p> {{$t('my.finance.rechargeStepsDetail')}} </p>
                    </div>

                    <div v-show="!isCanDeposit" class="confirmBtn">{{ $t('my.finance.nowDeposit') }}</div>
                    <div v-show="isCanDeposit" @click="goRechange" class="confirmBtn hasValue">{{ $t('my.finance.nowDeposit')}}</div>

                    <div class="warning">
                        {{ $t('my.finance.bankRechargeTips')}}
                    </div>
                </div>
            </div>

            <!-- 虚拟币充值 -->
            <div style="background-color: #f6f6f6;" v-else-if="rechangeType == 8">
                <div class="rechargeItem">
                    <h2><span></span>{{ $t('my.finance.selectPaymentChannel')}}</h2>
                    <div class="UItem">
                        <span v-for="(xitem, index) in companyUsdtInfo" :key="`${index}--companyUsdtInfo`"
                            @click="activeAisle = index" class="btn" :class="activeAisle == index ? 'activeBtn' : ''">
                            {{ xitem.bankName }}
                        </span>
                    </div>

                    <div v-for="(xitem, index) in companyUsdtInfo" :key="`${index}--companyUsdtInfo-bottom`">
                        <div v-show="activeAisle == index">
                            <h2>{{ $t('my.finance.USDTAdress')}}</h2>
                            <div class="UadressBox flexBetween">
                                <div>{{ xitem.cardNo }}</div>
                                <div @click="copyClick(xitem.cardNo)">{{ $t("common.copy") }}</div>
                            </div>

                            <h2>{{ $t('my.finance.USDTCode')}}</h2>
                            <div v-if="xitem.cardNo" style="text-align: center;" ref="creditQrCodeShare">
                                <qrcode-vue id="qrcodeBox" 
                                    :size="qrcodeVue.size"
                                    :bgColor="qrcodeVue.bgColor"
                                    :fgColor="qrcodeVue.fgColor" 
                                    :value="xitem.cardNo"></qrcode-vue>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="rechargeItem">
                    <p class="warning">{{ $t('my.finance.USDTTips')}}</p>
                    <h2><span></span>{{ $t('my.finance.bill')}}</h2>
                    <div class="moneyBoxs moneyBoxsU">
                        <van-field v-model.trim="money" label="USDT" label-width="1.1rem" id="moneyBox" @input="changeMoney" autocomplete="off" clearable
                            :placeholder="`${$t('my.finance.depositAmountTips')}`" />
                    </div>

                    <div class="fontTip">
                        <p class="noPad">{{ $t('my.finance.aboutReceiptAmount') }}：{{ receiptAmount }} {{ $t("my.finance.currency") }}</p>
                        <p>{{ $t('my.finance.amountRange')}}:{{ numFormat(lowest) }}-{{ numFormat(highest) }}</p>
                        <p>{{ $t('my.finance.exchangeRate1') }}：1USDT≈ {{ rechageRate }} {{ $t("my.finance.currency") }} ,{{ $t("my.finance.exchangeRate") }}</p>
                    </div>

                    <div class="flexCenter adressBox">
                        <div>{{ $t('my.finance.paymentAddress') }}</div>
                        <van-field v-model.trim="bankUserUsdtInfo.cardNo" autocomplete="off" clearable
                            :placeholder="`${$t('my.finance.paymentAddressTips')}`" />
                    </div>
                    <div class="flexCenter adressBox">
                        <div>{{ $t('my.finance.transferOrder') }}</div>
                        <van-field v-model.trim="bankUserUsdtInfo.thirdOrderNo" autocomplete="off" clearable
                            :placeholder="`${$t('my.finance.transferOrderTips')}`" />
                    </div>

                    <div class="attention">
                        <div class="flexCenter fontTipTitle">
                            <img src="../../assets/img/my/next/attention.png" />Attention
                        </div>
                        <p>{{ $t('my.finance.USDTDetail') }} <span>TRC20</span> {{ $t('my.finance.USDTDetail01') }} <span>TRC20</span>{{ $t('my.finance.USDTDetail02') }}</p>
                        <p>{{ $t('my.finance.USDTDetail1') }} <span>{{ money }}</span> {{ $t('my.finance.USDTDetail2') }}</p>
                        <p>{{ $t('my.finance.USDTDetail3') }}</p>
                    </div>
                    <div v-show="!isCanDeposit || !bankUserUsdtInfo.cardNo || !bankUserUsdtInfo.thirdOrderNo" class="confirmBtn">{{ $t('my.finance.nowDeposit') }}</div>
                    <div v-show="isCanDeposit && bankUserUsdtInfo.cardNo && bankUserUsdtInfo.thirdOrderNo" @click="goRechange" class="confirmBtn hasValue">{{ $t('my.finance.nowDeposit')}}</div>
                </div>
            </div>

            <!-- 充值卡 -->
            <div v-else-if="rechangeType == 7" style="background-color: #f6f6f6;">
                <div class="rechargeItem">
                    <div class="addCardsTip">
                        <span v-for="(item, index) in numList" :key="item" class="btn" :class="numActive == index ? 'activeBtn' : ''"
                            @click="numItemClick(item, index)">
                            <p>{{ numFormat(item) }}</p>
                            <p>
                                <img class="gold" src="../../assets/img/gold.png" />
                                {{ numFormat(item / rechageRate) }}
                            </p>
                        </span>
                    </div>

                    <div class="cardRecharge">
                        <van-field v-model.trim="serial" autocomplete="off" clearable
                            :placeholder="`${$t('my.finance.serialNoTips')}`" />
                        <van-field v-model.trim="rechangeCardNum" autocomplete="off" clearable
                            :placeholder="`${$t('my.finance.rechangeNoTips')}`" />
                    </div>

                    <div class="rechageCardTypeBox">
                        <div class="rechageCardTypeTitle">{{ $t('my.finance.rechangeType') }}</div>
                        <van-radio-group v-model="rechangeCardActive" direction="horizontal">
                            <van-radio v-for="(item, index) in supportCardArr" icon-size="0.2rem" :key="`${index}-rechangeCardType`" :name="item" checked-color="#ee0a24">{{ item }}</van-radio>
                        </van-radio-group>
                    </div>

                    <h2><span></span>{{ $t('my.finance.depositAmount') }}</h2>
                    <div class="moneyBoxs" v-show="isEditMoney">
                        <van-field v-model.trim="money" id="moneyBox" label="₫" label-width="0.2rem"  @input="changeMoney" autocomplete="off" clearable
                            :placeholder="`${$t('my.finance.depositAmountTips')}`" />
                    </div>

                    <div v-show="!isCanDeposit" class="confirmBtn">{{ $t('my.finance.nowDeposit') }}</div>
                    <div v-show="isCanDeposit" @click="rechargeCard" class="confirmBtn hasValue">{{ $t('my.finance.nowDeposit')
                    }}</div>

                    <div class="warning">
                        <div v-html="bankList[activeBank].remark"></div>
                        <!-- <p class="noPad">{{ $t('my.finance.singleDepositAmount') }}:
                            {{ numFormat(lowest) }}-{{ numFormat(highest) }}</p>
                        <p>{{ $t('my.finance.aboutReceiptAmount') }}<span>{{ receiptAmount }}
                            </span>{{ $t('my.finance.currency') }}</p>
                        <p>{{ $t('my.finance.exchangeRate1') }} 1 X {{ $t('my.finance.currency') }} ≈
                            {{ digitalNumber(1 * rechageRate) }}</p>
                        <p>{{ $t('my.finance.receiptRuleTips') }}</p> -->
                    </div>
                </div>
            </div>

            <!-- 三方充值 -->
            <div v-else style="background-color: #f6f6f6;">
                <div v-if="bankList.length > 0" class="rechargeItem">
                    <div v-show="supportBankArr.length > 0">
                        <h2><span></span>{{ $t('my.finance.selectBank') }}</h2>
                        <van-field disabled @click="isShowCard = true" v-model.trim="supportBankActive" right-icon="arrow"
                            :placeholder="`${$t('my.finance.pleaseSelectBank')}`" />
                    </div>

                    <h2><span></span>{{ $t('my.finance.depositAmount') }}</h2>
                    <div class="moneyBoxs" v-show="isEditMoney">
                        <van-field v-model.trim="money" id="moneyBox" label="₫" label-width="0.2rem"  @input="changeMoney" autocomplete="off" clearable
                            :placeholder="`${$t('my.finance.depositAmountTips')}`" />
                    </div>

                    <div class="fontTip">
                        <p class="noPad">{{$t('my.finance.amountRange')}}:
                            {{ numFormat(lowest) }}-{{ numFormat(highest) }}，{{$t('my.finance.amountRangeTips')}}</p>
                    </div>
                    
                    <div class="addCardsTip">
                        <span v-for="(item, index) in numList" :key="item" class="btn" :class="numActive == index ? 'activeBtn' : ''"
                            @click="numItemClick(item, index)">
                            <!-- <img v-show="numActive == index" class="select" src="../../assets/img/record/activeBtn.png" /> -->
                            <p>{{ numFormat(item) }}</p>
                            <p>
                                <img class="gold" src="../../assets/img/gold.png" />
                                {{ numFormat(item / rechageRate) }}
                            </p>
                        </span>
                    </div>

                    <div v-show="!isCanDeposit" class="confirmBtn">{{ $t('my.finance.nowDeposit') }}</div>
                    <div v-show="isCanDeposit" @click="ThreePartyRecharge" class="confirmBtn hasValue">{{
                        $t('my.finance.nowDeposit') }}</div>

                    <div class="warning">
                        <div v-html="bankList[activeBank].remark"></div>
                        <!-- <p class="noPad">{{ $t('my.finance.singleDepositAmount') }}:
                            {{ numFormat(lowest) }}-{{ numFormat(highest) }}</p> -->
                        <!-- <p>{{ $t('my.finance.aboutReceiptAmount') }}<span>{{ receiptAmount }}
                            </span>{{ $t('my.finance.currency') }}</p>
                        <p>{{ $t('my.finance.exchangeRate') }} 1{{ $t('my.finance.currency') }} ≈
                            {{ digitalNumber(1 * rechageRate) }} </p>
                        <p>{{ $t('my.finance.receiptRuleTips') }}</p> -->
                    </div>
                </div>
            </div>

            <!-- 银行选择 -->
            <van-popup v-model:show="isShowCard" position="bottom">
                <van-picker show-toolbar :columns="supportBankArr" @confirm="onConfirm"
                    :confirm-button-text="`${$t('common.finish')}`" :cancel-button-text="`${$t('common.cancel')}`"
                    @cancel="isShowCard = false" />
            </van-popup>
        </div>
        </van-pull-refresh>
    </van-popup>

    <div style="display: none;" id="captcha"></div>

    <otherWeb></otherWeb>
  </div>
</template>
<script>
import { payList, cardOrder, thirdOrder, getBankList, bankOrder, usdtOrder } from "@/api/index";
import { advert } from "@/api/config";
import QrcodeVue from "qrcode.vue";
import html2canvas from "html2canvas";
import { mapState } from 'vuex';
import utils from "@/utils/index";
import captcha from '@/pages/mixin/configCaptcha';
import otherWeb from "@/pages/components/otherWeb.vue";
import md5 from 'js-md5';
export default {
    name: "liveRoomDeposit",
    components: {
        otherWeb,
        QrcodeVue 
    },
    mixins: [captcha],
    data() {
        return {
            bankList: [], //充值方式
            activeBank: 0, //当前选择的充值方式下标
            activeAisle: 0,
            numList: [], //金额列表
            numActive: -1, //当前选择的金额下标
            money: "",  // 输入金额
            rechangeType: -1,
            supportBankArr: [], //银行列表
            supportCardArr: ['VIETTEL', 'MOBIFONE', 'VINAPHONE'],
            supportBankActive: "", //当前选择的银行
            isShowCard: false, //选择银行弹框是否展示
            // rechangeCardType: ["VIETTEL", "MOBIFONE", "VINAPHONE"], //充值卡类型
            rechangeCardActive: "", //当前选择的充值卡类型
            serial: "", //serial号
            rechangeCardNum: "", //充值卡号码
            symbolActive: "", //选择的货币
            notice: [], //文字滚动
            qrcodeVue: {
                size: 120,
                bgColor: '#fff',
                fgColor: '#000',
                value: '', //二维码地址
            },
            bankUserUsdtInfo: {
                cardNo: "",
                thirdOrderNo: ""
            },
            companyInfoActive: {},
            companyInfo: [], //用户绑定的银行卡
            companyUsdtInfo: [], //用户绑定的U地址
            isShowDeposit: false, // 弹框是否展示
            isLoadBanks: false,
            isRefreshDisable: false,
            scrollTop: 0
        }
    },
    computed: {
        ...mapState(['member', 'entry', 'baseInfo', 'isLoadMember']),
        isEditMoney() {
            if (this.bankList[this.activeBank] && this.bankList[this.activeBank].printStatus === 0) {
                return true
            }
            return false;
        },
        rechageRate() {
            let rate = 0;
            if (this.rechangeType == 8) {
                if (this.bankList.length > 0 && this.bankList[this.activeBank] && this.bankList[this.activeBank].rate) {
                    rate = this.bankList[this.activeBank].rate;
                }
            } else {
                rate = this.baseInfo.currency;
            }

            return rate;
        },
        lowest() {
            let lowest = this.bankList[this.activeBank] && this.bankList[this.activeBank].lowest ? this.bankList[this.activeBank].lowest : 0;
            return lowest;
        },
        highest() {
            let highest = this.bankList[this.activeBank] && this.bankList[this.activeBank].highest ? this.bankList[this.activeBank].highest : 0;
            return highest;
        },
        isCanDeposit() {
            let flag = false;
            if (this.money) {
                let str = this.money.toString().replace(/[^\d]/g, '');

                if (str > 0 && str >= this.lowest && str <= this.highest) {
                    flag = true;
                }
            }

            return flag;
        },
        receiptAmount() {
            let amount = "0";
            if (this.money) {
                let money = this.money.toString().replace(/[^\d]/g, '');

                if (this.rechangeType == 8) {
                    amount = this.numFormat(this.digitalNumber(money * this.rechageRate));
                } else {
                    amount = this.numFormat(this.digitalNumber(money / this.rechageRate))
                }

            }
            return amount;
        },
        getGiftMenoy() {
            let amount = "0", reward = this.bankList.length> 0 && this.bankList[this.activeBank] && this.bankList[this.activeBank].reward ? this.bankList[this.activeBank].reward : 0;
            if (this.money) {
                let money = this.money.toString().replace(/[^\d]/g, '');

                amount = this.numFormat(this.digitalNumber(money * reward / 100));

            }
            return amount;
        },
        isload() {
            let flag = false;

            if (this.isLoadMember && this.isLoadBanks) {
                flag = true;
            }

            return flag;
        }
    },
    watch: {
        scrollTop(newval) {
            if (newval > 0) {
                this.isRefreshDisable = true
            } else {
                this.isRefreshDisable = false
            }
        }
    },
    created() {
        this.getPayList();
        this.getAdvert();

        let that = this;
        setTimeout(()=> {
            that.isShowDeposit = true;
        }, 10);
    },
    mounted() {
        if (document.getElementById('captcha')) {
            this.userCaptcha('confirmOrder');
        }

        setTimeout(() => {
            // 解决滚动条与下拉的冲突
            const vantList = document.querySelector('.liveRoomDeposit');
            if (vantList) {
                this.resolveConflict(vantList)
            } 
        }, 100);
        
    },
    methods: {
        onRefresh() {
            this.$store.dispatch('refreshUserInfo');
            this.getPayList();
            this.getAdvert();
        },
        /**
         * @description: 下拉刷新和滚动事件冲突
         * @param {*}
         * @return {*}
         */
        resolveConflict(val) {
            const vantList = val
            vantList.addEventListener('touchmove', () => {
                this.scrollTop = vantList.scrollTop
            })
            vantList.addEventListener('touchend', () => {
                this.scrollTop = vantList.scrollTop
            })
        },
        resetItem() {
            this.money = "";
            this.numActive = -1;
            this.supportBankActive = "";

            if (this.rechangeType == 7) {
                this.serial = "";
                this.rechangeCardNum = "";
                this.rechangeCardActive = "";
            }

            if (this.rechangeType == 8) {
                this.bankUserUsdtInfo.cardNo = "";
                this.bankUserUsdtInfo.thirdOrderNo = "";
            }
        },
        // 关闭
        close() {
            this.isShowDeposit = false;
            let that = this;
            setTimeout(()=> {
                that.$emit('colsePopup', 'showDeposit')
            }, 1000);
        },
        // 复制
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        // 获取文字滚动
        getAdvert() {
            //   /** 横幅广告 */
            // HKAdvertType_Banner = 1,
            // /** 次级横幅广告 */
            // HKAdvertType_ListBanner = 2,
            // /** 首页飘屏文字广告 */
            // HKAdvertType_HotText = 3,
            // /** 进房文字广告 */
            // HKAdvertType_LiveText = 4,
            // /** 飘屏广告 */
            // HKAdvertType_Flutter = 5,
            // /** 房间活动 */
            // HKAdvertType_LiveActive = 6,

            // HKAdvertType_LiveImg = 7,
            // /** 游戏banner */
            // HKAdvertType_Game = 8,
            // /** 充值通告*/
            // HKAdvertType_topUp = 9,
            // /** 首存*/
            // HKAdvertType_firstDeposit = 10,
            this.notice = [];
            advert().then(res => {
                if (res.code === 0 || res.code === '0') {
                    let notice = [];
                    res.data.forEach((item) => {
                        if (item.type == 9) {
                            notice.push(item);
                        }
                    });
                    this.notice = JSON.parse(JSON.stringify(notice));
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        // 文字滚动点击事件
        showCont(item) {
            this.$dialog({
                // title: this.$t('common.hint'),
                message: item.content,
                className: "confirmDialog",
                confirmButtonText: this.$t('common.cancel'),
                confirmButtonColor: "#fa7c87"
            }).then(() => {
                // this.$router.push('/mine/mail');//地址待确认是否需要带参
            })
        },
        // 获取银行卡通道列表
        getBankInfo() {
            let params = {
                uid: this.member.uid
            }
            this.companyInfo = [];
            this.companyUsdtInfo = [];
            getBankList(params).then(res => {
                if (res.code == 0) {
                    // 银行卡 取值
                    let companyInfo = res.data.filter(item => {
                        return item.type == 7
                    });
                    this.companyInfo = JSON.parse(JSON.stringify(companyInfo));

                    if (companyInfo.length > 0) {
                        this.companyInfoActive = companyInfo[0];
                    }

                    let usdtIndex = this.bankList.findIndex(item => {
                        return item.type == 8
                    });

                    let companyUsdtInfo = res.data.filter(item => {
                        return item.type == 29
                    });

                    if (usdtIndex != -1 && this.bankList[usdtIndex].supportBankArr.length > 0 && companyUsdtInfo.length > 0 ) {
                        let supportChennel = companyUsdtInfo.filter(item => this.bankList[usdtIndex].supportBankArr.includes(item.bankName))
                        this.companyUsdtInfo = supportChennel;
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            })
        },
         //保存图片
        saveImage(link) {
            if (!link) return;
            let img = new Image(), that = this;
            img.setAttribute('crossOrigin', 'Anonymous')
            img.onload = function(){
                let canvas = document.createElement('canvas')
                let context = canvas.getContext('2d')
                canvas.width = img.width
                canvas.height = img.height
                context.drawImage(img, 0, 0, img.width, img.height)
                let url = canvas.toDataURL('images/png')
                let a = document.createElement('a')
                let event = new MouseEvent('click')
                a.download = name || that.$t('my.finance.QRCode')
                a.href = url
                a.dispatchEvent(event)
            }
            img.src = link + '?v=' + Date.now()
            // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
            // html2canvas(this.$refs.bankCardCode, {
            //     backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
            //     useCORS: true, //支持图片跨域
            //     scale: 1, //设置放大的倍数
            // }).then((canvas) => {
            //     // 把生成的base64位图片上传到服务器,生成在线图片地址
            //     let url = canvas.toDataURL("image/png"); // toDataURL: 图片格式转成 base64
            //     this.imgUrl = url;
            //     //将图片下载到本地
            //     let a = document.createElement("a"); // 生成一个a元素
            //     let event = new MouseEvent("click"); // 创建一个单击事件
            //     a.download = name || this.$t('my.finance.QRCode'); // 设置图片名称没有设置则为默认
            //     a.href = this.imgUrl; // 将生成的URL设置为a.href属性
            //     a.dispatchEvent(event); // 触发a的单击事件
            // });
        },
        // 输入金额添加千位符
        changeMoney() {
            let box = document.getElementById('moneyBox');
            if (this.money) {
                let start = box.selectionStart;
                let str = this.money.toString().replace(/[^\d]/g, '');
                let old = this.money;
                this.money = this.numFormat(str);
                if (this.money.length > 20) {
                    let str1 = this.money.substring(0, 21).toString().replace(/[`,`]/g, '');
                    this.money = this.numFormat(str1);
                }

                if (this.numFormat(str).length > old.length && this.numFormat(str).length - old.length == 1) {
                    setTimeout(() => {
                        box.setSelectionRange(start + 1, start + 1);
                    }, 0);
                }

                if (this.numFormat(str).length == old.length) {
                    setTimeout(() => {
                        box.setSelectionRange(start, start);
                    }, 0);
                }
            }
        },
        // 匹配 火 热 推荐
        getHotType(log) {
            switch (String(log)) {
                case '1':
                    return this.$t('my.finance.hotType.hot');
                case '2':
                    return this.$t('my.finance.hotType.recommend');
                case '3':
                    return this.$t('my.finance.hotType.fad');
                case '4':
                    return this.$t('my.finance.hotType.rapidly');
                default:
                    return '';

            }
        },
        // 排序
        compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        },
        // 选择充值卡
        rechangeCardItemClick(item) {
            this.rechangeCardActive = item;
        },
        // 选择银行弹框确认
        onConfirm(val) {
            this.supportBankActive = val;
            this.isShowCard = false;
        },
        // 选择银行
        supportBankItem(item) {
            this.supportBankActive = item;
        },
        // 获取所有支付方式
        getPayList(val) {
            this.isLoadBanks = true;
            payList(val).then(res => {
                this.isLoadBanks = false;
                if (res.code === 0 || res.code === '0') {
                    let arr = JSON.parse(JSON.stringify(res.data));
                    arr.forEach(item => {
                        if (item.supportBank) {
                            item.supportBankArr = item.supportBank.split(",");
                        } else {
                            item.supportBankArr = [];
                        }

                        if (item.product) {
                            let product = JSON.parse(JSON.stringify(item.product.split(","))), configPayProducts = [];
                            product.forEach(xitem => {
                                configPayProducts.push(xitem.replace(/[^\d]/g, " ").trim());
                            });
                            item.configPayProducts = configPayProducts;
                            item.productArr = product;
                        } else {
                            item.configPayProducts = [];
                            item.productArr = [];
                        }
                        if (item.remark) item.remark = item.remark.replace(/\n/g,'<br/>');
                    });
                    this.bankList = JSON.parse(JSON.stringify(arr));
                    this.money = "";
                    this.numActive = -1;
                    this.supportBankActive = "";
                    if (arr.length > 0) {
                        this.numList = arr[0].configPayProducts;
                        this.supportBankArr = arr[0].supportBankArr;
                        this.rechangeType = arr[0].type;
                    }

                    this.getBankInfo();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                this.isLoadBanks = false;
                console.log(err);
            });
        },
        bankItemClick(item, index) {
            this.money = "";
            this.activeBank = index;
            this.numList = this.bankList[index].configPayProducts;
            this.supportBankArr = this.bankList[index].supportBankArr;
            this.rechangeType = item.type;
            this.numActive = -1;
            this.supportBankActive = "";

            if (item.type == 7 || item.type == 8) {
                this.getBankInfo();
            }
        },
        numItemClick(item, index) {
            this.numActive = index;
            this.money = this.numFormat(item);
            // this.money = item/this.bankList[this.activeBank].rate;
        },
        // 充值
        goRechange() {
            let that = this;
            this.$dialog.confirm({
                // title: "重要的提示", 
                message: `${this.$t('my.finance.submitAfterTransfer')}`,
                showCancelButton: true,
                confirmButtonText: `${this.$t('my.finance.notRecharged')}`,
                cancelButtonText:  `${this.$t('my.finance.recharged')}`,
                closeOnClickOverlay: true,
                className: "confirmDialog",
                confirmButtonColor: "#fa7c87"
            })
                .then(() => {
                    that.confirmGetCode('confirmOrder');
                })
                .catch(() => {
                    // on cancel
                });
        },
        // 银行卡/ usdt 充值
        confirmOrder() {
            if (!this.member) {
                this.$router.push("/login");
            }

            let money = this.money.toString().replace(/[^\d]/g, '');
            if (money < this.lowest || money > this.highest) {
                this.$toast(`${this.$t('my.finance.singleDepositAmount')}: ${this.lowest}-${this.highest}`);
                return;
            }

            let flag = false;
            if (money.length < 4 && this.rechangeType == 8) {
                flag = true;
            }

            if (money.length >= 4 && this.rechangeType == 8) {
                let str = money.toString().substring((money.length - 3), money.length);
                if (str != '000') {
                    flag = true;
                }
            }

            if (flag) {
                this.$toast(this.$t("my.finance.moneyEndTips"));
                return;
            }

            let params = {
                uid: this.member.uid,
                payFlag: this.bankList[this.activeBank].payFlag,
                payType: this.bankList[this.activeBank].type
            }, fun = null;

            if (this.bankList[this.activeBank].printStatus === 0) {
                params.code = 1;
            } else {
                params.code = this.numList[this.numActive].code;
            }

            if (this.rechangeType == 9) {
                fun = bankOrder;
                params.bankId = this.companyInfoActive.bankId;
                params.trueRmb = money;
                params.sectionGold = (money/this.rechageRate);
            } else {
                fun = usdtOrder;
                params.bankId = this.companyUsdtInfo[this.activeAisle].bankId;
                params.cardNoCostomer = this.bankUserUsdtInfo.cardNo;
                params.sectionGold = (money*this.rechageRate);
                params.rechargeMoneyUsdt = money;
                params.notice = "1";
            }

            this.$store.commit("setState", { isLoadForLoad: true });
            fun(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.resetItem();
                    this.successBox();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(err => {
                this.$store.commit("setState", { isLoadForLoad: false });
                console.log(err);
            });
        },
        // 充值成功后弹框
        successBox() {
            this.$dialog({
                className: "confirmDialog",
                confirmButtonText: this.$t('common.confirm'),
                confirmButtonColor: "#fa7c87",
                message: `${this.$t("my.finance.bankRechargeSuccess")}`
            }).then(() => {
                // on close
            });
        },
        // 充值卡充值
        rechargeCard() {
            if (!this.member) {
                this.$router.push("/login");
            }

            if (this.rechangeCardActive == 'VIETTEL' && (this.serial.length != 11 && this.serial.length != 13 && this.serial.length != 14)) {
                this.$toast(`${this.$t('my.finance.serialNoTips1')}`);
                return;
            }

            if (this.rechangeCardActive == 'MOBIFONE' && this.serial.length != 15) {
                this.$toast(`${this.$t('my.finance.serialNoTips3')}`);
                return;
            }

            if (this.rechangeCardActive == 'VINAPHONE' && this.serial.length != 14) {
                this.$toast(`${this.$t('my.finance.serialNoTips2')}`);
                return;
            }

            if (this.rechangeCardActive == 'VIETTEL' && (this.rechangeCardNum.length != 13 && this.rechangeCardNum.length != 15)) {
                this.$toast(`${this.$t('my.finance.rechangeNoTips1')}`);
                return;
            }

            if (this.rechangeCardActive == 'MOBIFONE' && this.rechangeCardNum.length != 12) {
                this.$toast(`${this.$t('my.finance.rechangeNoTips3')}`);
                return;
            }

            if (this.rechangeCardActive == 'VINAPHONE' && this.rechangeCardNum.length != 12 && this.rechangeCardNum.length != 14) {
                this.$toast(`${this.$t('my.finance.rechangeNoTips2')}`);
                return;
            }

            let money = this.money.toString().replace(/[^\d]/g, '');
            let flag = false;
            if (money.length < 4) {
                flag = true;
            }

            if (money.length >= 4) {
                let str = money.toString().substring((money.length - 3), money.length);
                if (str != '000') {
                    flag = true;
                }
            }

            if (flag) {
                this.$toast(this.$t("my.finance.moneyEndTips"));
                return;
            }

            if (money < this.lowest || money > this.highest) {
                this.$toast(`${this.$t('my.finance.singleDepositAmount')}: ${this.lowest}-${this.highest}`);
                return;
            }
            let gold = (((money*1)*(this.bankList[this.activeBank].reward*1)/100) + (money*1)) / this.rechageRate,
                that = this;

            this.$dialog.confirm({
                message: `${this.$t('my.finance.thirdOrderTips')} ${this.money} ${this.$t('my.finance.thirdOrderTips1')} ${this.numFormat(this.digitalNumber(gold))} ${this.$t('my.finance.currency')}`,
                showCancelButton: true,
                confirmButtonText: `${this.$t('common.confirm')}`,
                cancelButtonText:  `${this.$t('common.cancel')}`,
                closeOnClickOverlay: true,
                className: "confirmDialog",
                confirmButtonColor: "#fa7c87"
            })
            .then(() => {
                that.gocardOrder(money);
            })
            .catch(() => {
                // on cancel
            });
        },
        // 充值卡充值调用接口
        gocardOrder(money) {
            let uid = localStorage.getItem('uid') || utils.guid(),
            params = {
                payFlag: this.bankList[this.activeBank].payFlag,
                payType: this.bankList[this.activeBank].type,
                sectionGold: this.digitalNumber(money / this.rechageRate),
                trueRmb: money,
                time: new Date().getTime() + (1000 * 60 * 30),
                pin: this.rechangeCardNum, //充值卡 pin
                serial: this.serial, //充值卡 serial
                type: this.rechangeCardActive, //充值卡类型
                currency: this.bankList[this.activeBank].currency,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid(),
            }
            if (this.bankList[this.activeBank].printStatus === 0) {
                params.code = 1;
            } else {
                params.code = this.numList[this.numActive].code;
            }

            this.$store.commit("setState", { isLoadForLoad: true });
            cardOrder(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (res.data) this.$toast(res.data),this.resetItem();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(err => {
                this.$store.commit("setState", { isLoadForLoad: false });
                console.log(err);
            });
        },
        // 三方充值
        ThreePartyRecharge() {
            if (!this.member) {
                this.$router.push("/login");
            }

            let money = this.money.toString().replace(/[^\d]/g, '');
            let flag = false;
            if (money.length < 4) {
                flag = true;
            }

            if (money.length >= 4) {
                let str = money.toString().substring((money.length - 3), money.length);
                if (str != '000') {
                    flag = true;
                }
            }

            if (flag) {
                this.$toast(this.$t("my.finance.moneyEndTips"));
                return;
            }

            if (money < this.lowest || money > this.highest) {
                this.$toast(`${this.$t('my.finance.singleDepositAmount')}: ${this.lowest}-${this.highest}`);
                return;
            }

            let gold = (((money*1)*(this.bankList[this.activeBank].reward*1)/100) + (money*1)) / this.rechageRate,
                that = this;

            this.$dialog.confirm({
                message: `${this.$t('my.finance.thirdOrderTips')} ${this.money} ${this.$t('my.finance.thirdOrderTips1')} ${this.numFormat(this.digitalNumber(gold))} ${this.$t('my.finance.currency')}`,
                showCancelButton: true,
                confirmButtonText: `${this.$t('common.confirm')}`,
                cancelButtonText:  `${this.$t('common.cancel')}`,
                closeOnClickOverlay: true,
                className: "confirmDialog",
                confirmButtonColor: "#fa7c87"
            })
            .then(() => {
                that.gothirdOrder(money);
            })
            .catch(() => {
                // on cancel
            });
        },
        // 三方充值调用接口
        gothirdOrder(money) {
            let uid = localStorage.getItem('uid') || utils.guid(),
            params = {
                payFlag: this.bankList[this.activeBank].payFlag,
                payType: this.bankList[this.activeBank].type,
                sectionGold: (money / this.rechageRate),
                trueRmb: money,
                time: new Date().getTime() + (1000 * 60 * 30),
                supportBank: this.supportBankActive,
                currency: this.bankList[this.activeBank].currency,
                uid: this.member.uid,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid()
            }
            if (this.bankList[this.activeBank].printStatus === 0) {
                params.code = 1;
            } else {
                params.code = this.numList[this.numActive].code;
                // params.code = this.bankList[this.activeBank].productArr[this.numActive];
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            thirdOrder(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (res.data.thirdType == 1) {
                        // window.open(payUrl);
                        // window.location.href = res.data.payUrl;
                        this.resetItem();
                        this.$store.commit("setState", { otherWebUrl: res.data.payUrl, isShowOtherWeb: true });
                    }

                    if (res.data.thirdType == 3 && res.data.payUrl) {
                        this.$dialog({
                            className: "confirmDialog",
                            confirmButtonText: this.$t('common.confirm'),
                            confirmButtonColor: "#fa7c87",
                            message: res.data.payUrl
                        }).then(() => {
                            // on close
                        });
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(err => {
                this.$store.commit("setState", { isLoadForLoad: false });
                console.log(err);
            });
        }
    }
}
</script>


<style lang="scss" scoped>
.liveRoomDeposit {
    height: 100vh;
    background-color: #f9f9f9;
    padding-top: 3.4rem;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &.next .title {
        height: 3.4rem;
        background-image: url("../../assets/img/rechargeIcon/bg.png");
        background-repeat: no-repeat;
        background-size: 100% 2.5rem;
        color: #fff;
        background-color: #f9f9f9;

        .gold {
            line-height: 1;
            margin-top: 0.4rem;
            font-size: 0.5rem;

            img {
                width: 0.44rem;
                height: 0.44rem;
                margin-right: 0.2rem;
            }
        }

        .goldFont {
            line-height: 1;
            margin-top: 0.2rem;
            font-size: 0.28rem;
        }

        .title_ellipsis img{
            width: 0.4rem;
        }
    }

    .noticeBar {
        text-align: left;
        margin-top: 0.24rem;
        width: calc(100% - 0.56rem);
        border-radius: 0.1rem;
        overflow: hidden;
        margin: 0.4rem auto 0;

        .noticeBarItem {
            display: inline-block;
            margin-left: 0.2rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .rechargeItem {
        width: 100%;
        background: #fff;
        margin-bottom: 0.2rem;
        text-align: left;
        padding-bottom: 0.2rem;

        h2 {
            height: 0.8rem;
            line-height: .8rem;
            font-size: .30rem;
            color: #414655;
            font-weight: 700;
            margin: 0 0.28rem;
            width: calc(100% - 0.28rem);
            display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
            display: -moz-box; /* Firefox 17- */
            display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
            display: -moz-flex; /* Firefox 18+ */
            display: -ms-flexbox; /* IE 10 */
            display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
        
            align-items:center;
            -webkit-align-items:center;
            box-align:center;
            -moz-box-align:center;
            -webkit-box-align:center;

            &.noPad {
                border-color: #fff;
            }

            span {
                margin-right: 0.1rem;
                width: 3px;
                display: inline-block;
                height: 17px;
                background-color: #f872a0;
                border-radius: 0.1rem;
            }
        }

        ul {
            width: 100%;
            padding: 0.2rem 0.28rem 0;
            vertical-align: top;

            li {
                width: calc(25% - 0.15rem);
                height: 2.04rem;
                min-height: 2.04rem;
                border: 2px solid #f6f6f6;
                margin-bottom: 0.2rem;
                border-radius: 0.1rem;
                position: relative;
                // overflow: hidden;
                color: #8b8b8b;
                display: inline-block;
                margin-right: 0.15rem;
                padding-top: 0.2rem;
                vertical-align: top;

                &.activeBank {
                    border: 2px solid #f770ab;

                    span {
                        color: #333;
                    }
                }

                &:nth-child(4n) {
                    margin-right: 0;
                }

                .typeIcon {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin: 0 auto 0.2rem;
                    display: block;
                }

                .hotFont {
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    min-width: 0.56rem;
                    // height: 0.42rem;
                    color: #fff;
                    font-size: 0.1rem;
                    text-align: center;
                    line-height: 0.42rem;
                    padding: 0 0.05rem;

                    &.hotType1 {
                        background: url('../../assets/img/rechargeIcon/1.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }

                    &.hotType2 {
                        background: url('../../assets/img/rechargeIcon/2.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }

                    &.hotType3 {
                        background: url('../../assets/img/rechargeIcon/3.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }

                    &.hotType4 {
                        background: url('../../assets/img/rechargeIcon/4.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }
                }

                .icon {
                    position: absolute;
                    bottom: -2px;
                    right: -2px;
                    width: 0.32rem;
                    height: 0.32rem;
                }

                span {
                    display: block;
                    text-align: center;
                    font-size: .2rem;
                    color: #414655;
                    overflow: hidden;
                    word-break: break-all;
                    margin-top: 0.02rem;
                }
            }
        }

        .UItem {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 0.2rem 0 0 0.28rem;
            color: #a5a9b3;
            font-size: .24rem;
            line-height: .34rem;
            .btn {
                cursor: pointer;
                // width: 1.58rem;
                width: calc(33.3% - 0.2rem);
                border-radius: 0.1rem;
                text-align: center;
                color: #333;
                background-color: #f6f6f6;
                font-size: 0.27rem;
                margin-right: 0.2rem;
                position: relative;
                // overflow: hidden;
                font-weight: 500;
                font-family: DinProMedium;
                -webkit-transition: all .3s;
                transition: all .3s;
                margin-bottom: 0.2rem;
                display: inline-block;
                padding: 0.2rem 0;
                vertical-align: middle;

                &.activeBtn {
                    color: #fff;
                    background-color: #fa7c87;
                }
            }
        }

        .UadressBox {
            padding: 0 0.28rem;
            margin-bottom: 0.2rem;

            div:nth-child(1) {
                width: 5rem;
                padding: 0.1rem;
                border-radius: 0.1rem;
                border: 1px solid #c9c9c9;
            }

            div:last-child {
                background-color: #fa7c87;
                color: #fff;
                padding: 0.05rem 0.3rem;
                border-radius: 0.05rem;
            }
        }

        .addCardsTip {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 0.2rem 0 0 0.28rem;
            color: #a5a9b3;
            font-size: .24rem;
            line-height: .34rem;

            .btn {
                cursor: pointer;
                // width: 1.58rem;
                width: calc(33.3% - 0.2rem);
                border-radius: 0.1rem;
                border: 1px solid #eee;
                text-align: center;
                color: #414655;
                font-size: 0.27rem;
                margin-right: 0.2rem;
                position: relative;
                // overflow: hidden;
                font-weight: 500;
                font-family: DinProMedium;
                -webkit-transition: all .3s;
                transition: all .3s;
                margin-bottom: 0.2rem;
                display: inline-block;
                padding: 0.3rem 0;
                vertical-align: middle;

                &.activeBtn {
                    color: #f770ab;
                    border: 1px solid #f770ab;
                }

                &.activeBtn {
                    color: #fff;
                    border: 1px solid #f770ab;
                    background-color: #f770ab;
                }

                .select {
                    width: 0.32rem;
                    height: auto;
                    position: absolute;
                    bottom: -0.02rem;
                    right: -0.02rem;
                    z-index: 1;
                }

                .icon {
                    display: inline-block;
                    width: 0.36rem;
                    height: 0.36rem;
                    margin-right: 0.08rem;
                    vertical-align: middle;
                }

                .gold {
                    width: 0.24rem;
                }
            }

            &.rechageCard .btn {
                font-size: 0.2rem;
                width: 2.2rem;
            }
        }

        .bankDiscount {
            padding: 0.1rem 0.2rem;
            color: #f872a0;
            font-size: 0.24rem;
            background-color: #ffeaf2;
            margin: 0 auto;
            width: calc(100vw - 0.56rem);

            img {
                width: 0.32rem;
                height: 0.32rem;
                margin-right: 0.2rem;
            }
        }

        .bankCardInfoBox {
            margin: 0.4rem auto;
            width: calc(100vw - 0.56rem);
            padding: 0rem 0.2rem 0.4rem;
            border-radius: 0.2rem;
            position: relative;
            border: 1px solid #eee;

            &.active {
                border: 1px solid #f872a0;
            }

            .bankCardInfoBoxItem {
                margin-top: 0.4rem;
                
                div {
                    flex: auto;
                }

                div:nth-child(2) {
                    flex: auto;
                    padding: 0.1rem 0.2rem;
                    border-radius: 0.4rem;
                    background-color: #ff97a0;
                    color: #fff;
                    font-size: 0.2rem;
                    max-width: 1.3rem;
                }
            }

            .bankActive {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0.48rem;
            }
        }

        .subTitle {
            margin-left: 0.24rem;
            color: #333;
            font-weight: bold;
            font-size: 0.28rem;
        }

        .bankCardCodeBox {
            position: relative;
            padding: 0.2rem 0.24rem 0.8rem 0.24rem;
            text-align: center;

            .bankCardCode {
                width: 2.4rem;
                height: 2.4rem;
                margin: 0 auto;

                .bankCode {
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }

            .download {
                position: absolute;
                right: 0.24rem;
                bottom: 0.8rem;
                width: 0.5rem;
                height: 0.5rem;
            }
        }

        .moneyBoxs {
            margin-right: 0.2rem;
        }

        .confirmBtn {
            margin: 0.21rem auto 0;
        }

        .adressBox {
            padding: 0 0.28rem;
            margin-bottom: 0.16rem;

            div:first-child {
                white-space: nowrap;
            }
        }

        .rechageCardTypeBox {
            text-align: center;
            margin: 0.4rem 0.2rem;

            .rechageCardTypeTitle {
                background-color: #e5e5e5;
                padding: 0.22rem 0;
                border-top-left-radius: 0.1rem;
                border-top-right-radius: 0.1rem;
            }

            .van-radio-group--horizontal {
                justify-content: center;
                background-color: #f1f1f1;
                padding: 0.22rem 0;
                color: #333;
                font-size: 0.24rem;

                .van-radio {
                    flex: 1;
                    justify-content: center;
                }
            }
        }

        .fontTip {
            padding: 0 0.28rem;
            margin-bottom: 0.3rem;
            text-align: left;

            p {
                margin-top: 0.1rem;
                font-size: 0.25rem;
                color: #788597;

                &.noPad {
                    margin-top: 0.01rem;
                }

                span {
                    margin: 0 0.1rem;
                    font-size: 0.27rem;
                    color: #eb457e;
                }
            }
        }

        .attention {
            padding: 0 0.28rem;
            margin-bottom: 0.3rem;
            text-align: left;

            .fontTipTitle {
                margin: 0.4rem 0 0.24rem;
                color: #333;

                img {
                    width: 0.54rem;
                    height: 0.44rem;
                    margin-right: 0.2rem;
                }
            }

            p {
                margin-top: 0.2rem;
                font-size: 0.28rem;
                color: #788597;

                &.noPad {
                    margin-top: 0.01rem;
                }

                span {
                    margin: 0 0.1rem;
                    font-size: 0.27rem;
                    color: #eb457e;
                }
            }
        }

        .danger {
            padding: 0 0.28rem;
            color: #c41f1a;
            margin-top: 0.4rem;
        }
        .barTitle {
            width: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
            background: #fff;
            height: 0.96rem !important;
            scroll-behavior: smooth;
            z-index: 10;
            padding: 0 0.28rem;
            margin-top: 0.2rem;
            margin-bottom: 0.3rem;

            /* 隐藏滚动条 */
            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */

            &::-webkit-scrollbar {
            width: 0;      /* Safari,Chrome 隐藏滚动条 */
            height: 0;     /* Safari,Chrome 隐藏滚动条 */
            display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
            }

            div .item {
                text-align: center;
                height: 0.76rem;
                padding: 0.2rem 0.35rem;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 0.76rem;
                box-sizing: border-box;
                color: #666666;
                font-size: 0.28rem;
                margin-top: 0.2rem;
                border-radius: 5px;
                border: 1px solid #999;
                margin-right: 10px;

                &.active {
                    color: #fff;
                    background: -webkit-linear-gradient(left, #ffb3b2, #ffb3b2);
                    background: linear-gradient(90deg, #ffb3b2, #ffb3b2);
                    border: 1px solid #ffb3b2;
                }
            }
        }
    }

    .warning {
        color: #f770ab;
        font-size: 0.2rem;
        margin-bottom: 0.05rem;
        padding: 0.4rem 0.28rem 0;
    }
}
</style>

<style lang="scss">
.liveRoomDeposit {
    .confirmDialog {
        .van-dialog__header {
            font-size: 0.35rem;
            font-weight: bold;
            color: #333;
            padding: 0.4rem 0;
        }

        .van-dialog__footer {
            border-top: 1px solid #EBEFF1;
            font-size: 0.35rem;
        }
    }

    .currencyModel {
        .van-action-sheet__header {
            font-size: 0.34rem;
            background-color: #F9F9F9;
            font-weight: bold;
        }

        .van-action-sheet__close {
            font-size: 0.34rem;
        }

        .bankListCont {
            margin-bottom: 0.3rem;
            min-height: 3.2rem;

            .bankListCont_item {
                padding: 0.35rem 0;
                margin: 0 0.28rem;
                border-bottom: 1px solid #EBEFF1;

                &:last-child {
                    border-color: #fff;
                }

                .bankListCont_item_left {
                    text-align: left;
                    color: #788597;
                    font-size: 0.32rem;
                }

                .select {
                    width: 0.4rem;
                }
            }
        }
    }

    .van-notice-bar {
        height: 0.6rem !important;
    }

    .moneyBoxs {
        .van-cell {
            border-bottom: 1px solid #d2d2d2;
            padding-bottom: 0rem;
            margin-left: 0.28rem;
            padding-left: 0;
            margin-bottom: 0.2rem;
            margin-right: 0.2rem;
            width: calc(100% - 0.4rem);

            label {
                font-size: 0.4rem;
                color: #333;
                line-height: 0.57rem;
            }
        }

        &.moneyBoxsU {
            .van-cell label {
                line-height: 0.5rem;
            }
        }
    }

    .adressBox {
        .van-cell {
            border: 1px solid #e5e5e5;
            padding: 0.1rem;
            border-radius: 0.1rem;
            margin-left: 0.2rem;
        }
    }

    .cardRecharge {
        margin: 0 0.2rem;
        padding-top: 0.1rem;

        .van-cell {
            border: 1px solid #e5e5e5;
            border-radius: 0.1rem;
            padding: 0.1rem 0.2rem;
            margin-top: 0.2rem;
        }
    }
}
</style>
