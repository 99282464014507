<template>
    <div class="userInfo" :style="[{ backgroundImage: `url(${backgroundImageUrl})`}]">
        <div class="title">
            <div class="fixed" @click="goOtherPage('/mine')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.35rem" height="0.35rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path> : 
                </svg>
            </div>
        </div>

        <div class="userDetail">
            <div class="userName">{{ member.nickname }}</div>

            <div class="flexCenter identity">
                <div class="nobleLevel">
                    <img v-real-img="getImg(member.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                    <span :class="{'small':member.userLevel<10}">{{ member.userLevel ? member.userLevel : 0 }}</span>
                </div>

                <div v-if="member && member.badgeShowList && member.badgeShowList.length > 0 && member.badgeShowList.indexOf(2) != -1" class="nobleLevel">
                    <img v-real-img="getImg(member.anchorLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                    <span :class="{'small':member.anchorLevel<10}">{{member.anchorLevel}}</span>
                </div>
                <img v-if="member && member.sex==2" src="../../assets/img/my/icon/woman.png" />
                <img v-else src="../../assets/img/my/icon/man.png" />

                <div class="userIcon" v-if="member && member.badgeShowList && member.badgeShowList.length > 0">
                    <img v-for="(item, index) in member.badgeShowList" :key="`${index}--my-userInfo`" v-real-img="item.logoUrl"/>
                </div>
                
            </div>

            <div class="flexCenter interactive">
                <div>
                    {{ $t('my.follows') }}
                    <span>{{ member.follows }}</span>
                </div>

                <div>
                    {{ $t('my.fans') }}
                    <span>{{ member.fans }}</span>
                </div>
            </div>

            <div class="userInfodetail">
                <div class="userInfodetailTitle">
                    {{ $t('my.userInfo') }}
                </div>

                <div>
                    <span>ID: </span> {{ member.uid }} <span @click="copyClick(member.uid)" class="copyBtn">{{ $t('common.copy') }}</span>
                </div>

                <div>
                    <span>{{ $t('my.city') }}: </span> {{ member.city }}
                </div>

                <div>
                    <span>{{ $t('my.sign') }}: </span> {{ member.signature }}
                </div>
            </div>

            <div @click="goOtherPage('/mine/userInfo/edit')" class="eidt">
                <img src="../../assets/img/my/icon/editIcon.png" />
                {{ $t('common.eidt') }}
            </div>
        </div>
    </div>
</template>
  
<script>
import utils from "@/utils/index";
export default {
    created() {
        this.$store.commit("setState", { entry: this.$route.path });
    },
    computed: {
        member() {
            return this.$store.state.member;
        },
        backgroundImageUrl() {
            if (this.member && this.member.avatar) {
                return this.member.avatar;
            }
            return require('../../assets/img/errorImg/2.png');
        }
    },
    methods: {
        goOtherPage(path) {
            this.$router.push(path);
        },
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        // 匹配用户等级图片
        getImg(val) {
            if (val <= 10) {
                return require("../../assets/img/noble/level/level_1-10.png");
            }

            if (10 < val && val <= 20) {
                return require("../../assets/img/noble/level/level_11-20.png");
            }

            if (20 < val && val <= 30) {
                return require("../../assets/img/noble/level/level_21-30.png");
            }

            if (30 < val && val <= 40) {
                return require("../../assets/img/noble/level/level_31-40.png");
            }

            if (40 < val && val <= 50) {
                return require("../../assets/img/noble/level/level_41-50.png");
            }

            if (50 < val && val <= 60) {
                return require("../../assets/img/noble/level/level_51-60.png");
            }

            if (60 < val && val <= 70) {
                return require("../../assets/img/noble/level/level_61-70.png");
            }

            if (70 < val && val <= 80) {
                return require("../../assets/img/noble/level/level_71-80.png");
            }

            if (80 < val && val <= 90) {
                return require("../../assets/img/noble/level/level_81-90.png");
            }

            if (90 < val) {
                return require("../../assets/img/noble/level/level_91-105.png");
            }
        },
    }
}
</script>
  
<style lang="scss" scoped>
.userInfo {
    background-image: url('https://oss-bw03.pzgkw.com/avatar/1920230426195314.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
    padding-top: 4.08rem;
    font-size: 0.28rem;

    .title {
        position: fixed;
        top: 0px;
        left: 0.28rem;
        z-index: 10;
        font-size: 0.32rem;
        text-align: center;
        height: 0.88rem;
        line-height: 0.88rem;
        color: #414655;
        width: 100%;
        text-align: left;
    }

    .userDetail {
        background-color: #fff;
        border-top-left-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
        height: calc(100vh - 4.08rem);
        padding: 0.4rem 0.28rem;
        text-align: left;

        .userName {
            font-size: 0.44rem;
            color: #333;
            margin-bottom: 0.14rem;
        }

        .identity {
            &>img {
                height: 0.32rem;
                margin-right: 0.1rem;
            }

            .nobleLevel {
                position: relative;
                height: 0.35rem;
                margin-right: 0.1rem;

                img {
                    width: 0.7rem;
                    height: 0.34rem;
                }

                span {
                    position: absolute;
                    right: 0.05rem;
                    color: #fff;
                    line-height: 0.35rem;
                    font-size: 0.2rem;

                    &.small {
                        right: 0.12rem;
                    }
                }
            }

            .userIcon img {
                height: 0.32rem;
                margin-right: 0.1rem;
            }
        }

        .interactive {
            color: #626872;
            margin-top: 0.32rem;

            div {
                margin-right: 0.4rem;

                span {
                    font-size: 0.44rem;
                    color: #333;
                }
            }
        }
    }

    .userInfodetail {
        color: #626872;
        margin-top: 0.6rem;

        .userInfodetailTitle {
            font-size: 0.36rem;
            color: #333;
            margin-bottom: 0.4rem;
        }

        div:not(.userInfodetailTitle) {
            margin-bottom: 0.3rem;
        }

        span {
            margin-right: 0.2rem;

            &.copyBtn {
                background-color: #fa7c87;
                color: #fff;
                padding: 0.04rem 0.2rem;
                font-size: 0.2rem;
                margin-left: 0.4rem;
                border-radius: 0.1rem;
            }
        }

    }

    .eidt {
        line-height: 0.88rem;
        height: 0.88rem;
        max-width: 3rem;
        font-size: 0.32rem;
        color: #fff;
        text-align: center;
        background: #fa7c87;
        border-radius: 0.8rem;
        padding: 0 0.5rem;
        position: absolute;
        bottom: 0.4rem;
        left: 0;
        right: 0;
        vertical-align: middle;
        margin: 0 auto;

        img {
            vertical-align: middle;
            margin-right: 0.05rem;
        }
    }
}
</style>