<template>
    <div class="next inviteExchange">
        <div class="title">
            <span> {{$t('my.invite.inviteRedemption')}} </span>
            <div class="fixed" @click="goOtherPage('/mine/invite')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
            </div>
            <!-- <div @click="goOtherPage('/mine/invite/record/exchange')" class="title_ellipsis">
                {{$t('my.invite.exchangeRecord')}}
            </div> -->
        </div>

        <div class="rechargeItem">
            <h2>{{$t('my.invite.enterExchangeAount')}}</h2>
            <div class="flexBetween">
                <van-field v-model.trim="money" @keyup="money=money.replace(/[^\d]/g,'')" 
                    :left-icon="require('../../../assets/img/gold.png')"
                    autocomplete="off" clearable :placeholder="`${$t('my.invite.enterExchangeAount')}`"/>
                <!-- <div @click="allMoeny" style="width: 3rem;text-align: right;padding-right: 0.28rem;">{{$t('common.fullAmount')}}</div> -->
            </div>

            <div class="flexBetween">
                <div class="padDoule">{{$t('my.invite.currentBalance')}}<span>{{detailData.balance}}</span></div>
                <div class="all" @click="allMoeny">{{$t('common.fullAmount')}}</div>
            </div>
            

            <div v-show="!money || money <= 0 || parseFloat(money) > detailData.balance" class="confirmBtn">{{$t('common.submit')}}</div>
            <div v-show="money && money > 0 && parseFloat(money) <= detailData.balance" class="confirmBtn hasValue" @click="userExchange">{{$t('common.submit')}}</div>
        </div>
    </div>
</template>

<script>
import { userExchange } from "@/api/index";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            money: ""
        }
    },
    computed: {
        ...mapState(['member', 'baseInfo', 'detailData'])
    },
    methods: {
        // 全部金额
        allMoeny() {
            this.money = this.detailData.balance ? Math.floor(this.detailData.balance): 0;
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 兑换金币
        userExchange() {
            let params = {
                amount: this.money
            }
            userExchange(params).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('common.submitSuccess'));
                    this.$router.push('/mine/invite/record/exchange')
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.inviteExchange {
    padding-top: 0.88rem;

    .title .title_ellipsis {
        font-size: 0.28rem;
        color: #626872;
    }

    .rechargeItem {
        width: 100%;
        background: #fff;
        text-align: left;
        padding: 0.2rem 0;

        h2 {
            height: 0.8rem;
            line-height: .8rem;
            font-size: .28rem;
            color: #414655;
            font-weight: 700;
            margin-left: 0.28rem;
            width: calc(100% - 0.28rem);

            span {
                font-size: 0.27rem;
                color: #C41F1A;
                margin-right: 0.1rem;
            }
        }

        .padDoule {
            padding: 0.2rem 0.28rem;
            color: #626872;

            span {
                color: #fa7c87;
            }
        }

        .all {
            color: #fa7c87;
            padding: 0.2rem 0.28rem;
        }

        .confirmBtn {
            margin: 0.21rem auto 0;
        }
    }
}
</style>
<style lang="scss">
.inviteExchange {
    .van-icon__image {
        width: 0.24rem;
        margin-top: 0.06rem;
    }
}
</style>