<template>
  <div class="next withdrawNext">
    <div class="title">
        <span>  {{ $t("my.finance.goldWithdrawal") }} </span>
        <div class="fixed" @click="goBack">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
    </div>
    
    <div class="withdrawNextBox">
        <div class="withdrawNextTitle">{{ $t("my.finance.withdrawWay") }}</div>
        <!-- 有卡样式 -->
        <div v-if="bankSelect.trueName" @click="getBankInfo(true)" class="userWithdrawInfo flexBetween">
            <div class="flexCenterJustify userWithdrawInfoLeft">
                <img v-real-img="bankSelect.logs" :errorimg="require(`../../../assets/img/errorImg/2.png`)"/>
                <div>
                    <p>{{ bankSelect.trueName }}</p>
                    <p>{{ getCardNo(bankSelect.cardNo) }}</p>
                </div>
            </div>
            <div>
                <img src="../../../assets/img/my/right.png" />
            </div>
        </div>

        <!-- 无卡样式 -->
        <div v-else class="noCard" @click="$router.push('/mine/addCards/addBankCards')">
            <img src="../../../assets/img/my/bankIcon.png" />
            <div>{{ $t("my.finance.bindBnkFirstTips") }}</div>

            <img class="goNext" src="../../../assets/img/my/right.png" />
        </div>

        <div class="marBor"></div>

        <div class="withdrawNextTitle">{{ $t("my.finance.withdrawalMoney") }}</div>

        <div class="withdrawInputBox">
            <van-field label-width="0.3rem" label-align="right" v-model.trim="money" :label="baseInfo.currencySymbol" 
                id="moneyBox" @input="changeMoney" autocomplete="off" :placeholder="`${$t('my.finance.withdrawalMoneyTips')}`" clearable/>
            <div class="flexBetween tipsBox">
                <div>{{ $t("my.finance.canWithdrawn") }}：{{ numFormat(statementObj.goinCoin*baseInfo.currency) }} {{ baseInfo.currencySymbol }}</div>
                <div @click="all">{{ $t("common.all") }}</div>
            </div>

            <van-field v-if="ishaveCashPwd" v-show="isWithdrawCodeOpen" v-model.trim="withdrawCode" autocomplete="off" :placeholder="`${$t('my.addCard.withdrawPasswordTips1')}`" 
            :right-icon="require('../../../assets/img/eyeOpen.png')" left-icon="lock"  @click-right-icon="isWithdrawCodeOpen=!isWithdrawCodeOpen"/>

            <van-field v-if="ishaveCashPwd" v-show="!isWithdrawCodeOpen" v-model.trim="withdrawCode" autocomplete="off" :placeholder="`${$t('my.addCard.withdrawPasswordTips1')}`" 
            :right-icon="require('../../../assets/img/eyeCloseR.png')" left-icon="lock" type="password" @click-right-icon="isWithdrawCodeOpen=!isWithdrawCodeOpen"/>
        
            <div v-else @click="$router.push('/mine/addCards/addBankCards')" class="setPwdBtn">
                {{ $t("my.finance.setWithdrawPwd") }}
                <van-icon name="lock" />
            </div>
        </div>
    </div>

    <div v-show="!canWithdraw" class="confirmBtn">{{ $t("my.finance.withdrawal") }}</div>
    <div v-show="canWithdraw" @click="showWithdrawDialog=true" class="confirmBtn hasValue">{{ $t("my.finance.withdrawal") }}</div>

    <!-- 银行卡列表 -->
    <van-popup v-model:show="showBank" position="bottom">
        <div v-if="userBankList.length > 0" class="withdrawBankBox">
            <div v-for="(item, index) in userBankList" :key="`${index}-withdrawNext-index`"
             class="userWithdrawInfo flexBetween" :class="{'active': item.cardNo == bankSelect.cardNo}" @click="changeBank(item)">
                <div class="flexCenterJustify userWithdrawInfoLeft">
                    <img v-real-img="item.logs" :errorimg="require(`../../../assets/img/errorImg/2.png`)"/>
                    <div>
                        <p>{{ item.trueName }}</p>
                        <p>{{ getCardNo(item.cardNo) }}</p>
                    </div>
                </div>
                <div>
                    
                </div>
                <img v-show="item.cardNo == bankSelect.cardNo" class="activeIcon" src="../../../assets/img/my/next/selected.png" />
            </div>
            <div @click="goAddBankCard" class="confirmBtn hasValue">{{ $t("my.finance.addBank") }}</div>
        </div>
    </van-popup>

     <!-- 提现确认金额 -->
     <van-dialog v-model:show="showWithdrawDialog" 
      show-cancel-button confirm-button-Color="#ff97a0" cancel-button-Color="#515151"
        :confirmButtonText="$t('common.confirm')"
        :cancelButtonText="$t('common.cancel')"
        @confirm="withdraw">
        <div v-if="showWithdrawDialog" class="registerNumDialog">
          <p>{{ $t("my.finance.confirmWithdraw") }}</p>
          <p>{{ baseInfo.currencySymbol }} {{ money }}</p>
        </div>
      </van-dialog>

      <load></load>
  </div>
</template>

<script>
import { bankSelected, bankInfo, withdraw, statement, switchBank } from "@/api/index";
import { mapState } from 'vuex';
import load from "@/components/load.vue";
import utils from "@/utils/index";
export default {
    components: {
        load
    },
    data() {
        return {
            money: "",
            withdrawCode: "",
            isWithdrawCodeOpen: false,
            showBank: false,
            showWithdrawDialog: false,
            bankSelect: {},
            statementObj: {},
            userBankList: [], //用户绑定银行卡列表
            bankCount: 0,
        }
    },
    computed: {
        ...mapState(['member', 'baseInfo']),
        canWithdraw() {
            if (this.money) {
                let money = this.money.toString().replace(/[^\d]/g,'');
                if (money && this.withdrawCode && this.bankSelect.trueName 
                && this.statementObj.goinCoin && money <= (this.statementObj.goinCoin*this.baseInfo.currency)) {
                    return true
                }
            }
            
            return false
        },
        ishaveCashPwd() {
            let flag = false;
            if (this.userBankList && this.userBankList.length > 0 && this.userBankList[0].ishaveCashPwd) {
                flag = true;
            }

            return flag;
        }
    },
    created() {
        this.getBankSelected();
        this.statement();
        this.$store.commit("setState", { isLoadForLoad: false });
        this.getBankInfo();
    },
    methods: {
        // 银行卡处理
        getCardNo(cardNo) {
            return utils.bankCardHide(cardNo);
        },
        goAddBankCard() {
            if (this.bankCount > this.userBankList.length) {
                this.$router.push('/mine/addCards/addBankCards')
            } else {
                this.$toast(this.$t('my.addCard.beyondNumTips'))
            }
        },
        // 切换银行卡
        changeBank(item) {
            let params = {
                bankName: item.bankName,
                cardNo: item.cardNo,
                uid: this.member.uid
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            switchBank(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.getBankSelected();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(()=> {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        goBack() {
            this.$router.push('/mine/finance/withdraw');
        },
        // 输入金额添加千位符
        changeMoney() {
            let box = document.getElementById('moneyBox');
            if (this.money) {
                let start = box.selectionStart;
                let str = this.money.toString().replace(/[^\d]/g, '');
                let old = this.money;
                this.money = this.numFormat(str);
                if (this.money.length > 20) {
                    let str1 = this.money.substring(0, 21).toString().replace(/[`,`]/g, '');
                    this.money = this.numFormat(str1);
                }

                if (this.numFormat(str).length > old.length && this.numFormat(str).length - old.length == 1) {
                    setTimeout(() => {
                        box.setSelectionRange(start + 1, start + 1);
                    }, 0);
                }

                if (this.numFormat(str).length == old.length) {
                    setTimeout(() => {
                        box.setSelectionRange(start, start);
                    }, 0);
                }
            }
        },
        // 当前用户选择
        getBankSelected() {
            let params = {
                uid: this.member.uid
            }
            bankSelected(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.bankSelect = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 获取银行卡列表
        getBankInfo(flag) {
            if (this.userBankList.length > 0) return this.showBank = true;
            let params = {
                uid: this.member.uid
            }
            bankInfo(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.userBankList = res.data;
                    if (flag) {
                        this.showBank = true;
                    }

                    if (res.data.length > 0) {
                        this.bankCount = res.data[0].bankCount;
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 用户账户资金详情
        statement() {
            statement().then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.statementObj = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 提现
        withdraw() {
            let money = this.money.toString().replace(/[^\d]/g,'');

            let flag = false;
            if (money.length < 4) {
                flag = true;
            }

            if (money.length >= 4) {
                let str = money.toString().substring((money.length - 3), money.length);
                if (str != '000') {
                    flag = true;
                }
            }

            if (flag) {
               this.$toast(this.$t("my.finance.moneyEndTips")); 
               return;
            }

            let params = {
                uid: this.member.uid,
                cash: money,
                currency: this.baseInfo.currencySymbol,
                goldCoin: (Math.floor((money/this.baseInfo.currency)*100))/100,
                cardId: this.bankSelect.cardId,
                cardType: 1,
                cashPassword: this.withdrawCode,
                type: 1
            }

            withdraw(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('common.submitSuccess'));
                    this.$router.push("/mine/finance/withdraw");
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        all() {
            this.money = this.numFormat(this.statementObj.goinCoin*this.baseInfo.currency);
        }
    }
}
</script>

<style lang="scss" scoped>
.withdrawNext {
    background-color: #fff;
    font-size: 0.28rem;
    padding-top: 0.88rem;

    .withdrawNextTitle {
        background-color: #fff;
        color: #333;
        line-height: 0.92rem;
        text-align: left;
        padding: 0 0.28rem;
    }

    .marBor {
        width: 100%;
        height: 0.2rem;
        background-color: #f9f9f9;
    }

    .withdrawNextBox {

        .userWithdrawInfo {
            padding: 0.6rem 0.2rem;
            width: calc(100% - 0.56rem);
            margin: 0.2rem auto 0.4rem;
            background-color: #f1f1f1;
            border-radius: 0.1rem;
            text-align: left;

            .userWithdrawInfoLeft {
                img {
                    width: 0.84rem;
                    height: 0.84rem;
                    margin-right: 0.2rem;
                }

                p:nth-child(1) {
                    margin-bottom: 0.2rem;
                }
            }

            img {
                width: 0.44rem;
                height: 0.44rem;
            }
        }

        .noCard {
            position: relative;
            padding: 0.4rem 0;
            width: calc(100% - 0.56rem);
            margin: 0.2rem auto 0.4rem;
            background-color: #f1f1f1;
            border-radius: 0.1rem;

            .goNext {
                position: absolute;
                right: 0.4rem;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 0.44rem;
                height: 0.44rem;
            }

            div {
                color: #666;
                margin-top: 0.2rem;
            }
        }

        .withdrawInputBox {
            padding: 0 0.28rem;

            .setPwdBtn {
                border-radius: inherit;
                line-height: 0.88rem;
                color: #c41f1a;
                border-bottom: 1px solid #f5f5f5;
                position: relative;
                margin-top: 0.3rem;

                i {
                    position: absolute;
                    left: 0.24rem;
                    top: 0;
                    line-height: 0.88rem;
                    display: inline-block;
                    font-size: 0.4rem;
                    color: #333;
                }
            }
        }

        .tipsBox {  
            font-size: 0.24rem;
            color: #333;

            div:nth-child(1) {
                color: #ff97a0;
            }
        }
    }

    .withdrawBankBox {
        .userWithdrawInfo {
            padding: 0.4rem 0.2rem;
            width: calc(100% - 0.56rem);
            margin: 0.2rem auto 0.4rem;
            border: 1px solid #999;
            border-radius: 0.1rem;
            text-align: left;
            position: relative;

            &.active {
                border-color: #ff97a0;
            }

            .activeIcon {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0.4rem;
                height: 0.4rem;
            }

            .userWithdrawInfoLeft {
                img {
                    width: 0.84rem;
                    height: 0.84rem;
                    margin-right: 0.2rem;
                }

                p:nth-child(1) {
                    margin-bottom: 0.2rem;
                }
            }
        }

        .confirmBtn {
            width: calc(100% - 0.56rem);
            margin: 0.4rem auto;
        }
    }

    .confirmBtn {
        width: calc(100% - 2.4rem);
        margin: 0.4rem auto;
    }
}
</style>
<style lang="scss">
.withdrawNext {
    .van-cell {
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 0.2rem;

        .van-field__label {
            font-size: 0.4rem;
            color: #333;
        }
    }


    .registerNumDialog {
        padding: 0.36rem 0.2rem 0.44rem;
        background: -webkit-linear-gradient(left,#fa7c87,#fc9799);
        background: linear-gradient(90deg,#fa7c87,#fc9799);
        color: #fff;
        font-size: 0.28rem;

        p:nth-child(1) {
        font-size: 0.32rem;
        margin-bottom: 0.52rem;
        }
    }
}
</style>
