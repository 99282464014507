<template>
    <div class="blacklist next">
      <div class="title">
        <span> {{$t('my.systemSet.blacklist')}} </span>
        <div class="fixed" @click="goOtherPage('/mine/set')">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666" p-id="12457"></path></svg>
        </div>
      </div>

      <div v-if="list.length > 0" class="powderBox">
        <div class="flexBetween powderItem" v-for="(item, index) in list" :key="`${index}--blacklist`">
            <div class="flexCenter">
                <img class="powderAvatar" @click="goUserDetail(item)" v-real-img="item.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)" />
                <span>{{ item.nickname }}</span>
                
                <div class="nobleLevel">
                    <img v-real-img="getImg(item.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                    <span :class="{'small':item.userLevel<10}">{{ item.userLevel ? item.userLevel : 0 }}</span>
                </div>
            </div>
            <div @click="userReject(item.uid)" class="focusOn interactiveBtn">
                {{$t('my.systemSet.pullOut')}}
            </div>
        </div>
      </div>

        <div v-if="list.length == 0" class="noData">
            <img src="../../assets/img/record/noData.png" />
            <p>{{$t('common.noData')}}</p>
        </div>

        <userInfo v-if="showUserInfo" @colsePopup="colsePopup" @refresh="refresh"></userInfo>
    </div>
  </template>
  
  <script>
  import { blacklist, userReject } from "@/api/index";
  import userInfo from "@/pages/components/userInfo.vue";
  export default {
    components: {
        userInfo
    },
    data() {
      return {
        list: [], //列表字段待填充
        page: 0,
        showUserInfo: false
      }
    },
    created() {
        this.blacklist();
    },
    methods: {
        // 点击用户头像
        goUserDetail(item) {
            let obj = JSON.parse(JSON.stringify(item));
            obj.isSelf = false; // 是否是查看自己的资料
            this.$store.commit("setState", { betPramas: obj });
            this.showUserInfo = true;
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 黑名单列表
        blacklist() {
            let param = {
                page: 0 //逻辑待添加
            }
            blacklist(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.list = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch( () => {})
        },
        // 拉出
        userReject(uid) {
            let param = {
                uid,
                isReject: false
            }
            userReject(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.blacklist();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch( () => {})
        },
        // 匹配用户等级图片
        getImg(val) {
            if (val <= 10) {
                return require("../../assets/img/noble/level/level_1-10.png");
            }

            if (10 < val && val <= 20) {
                return require("../../assets/img/noble/level/level_11-20.png");
            }

            if (20 < val && val <= 30) {
                return require("../../assets/img/noble/level/level_21-30.png");
            }

            if (30 < val && val <= 40) {
                return require("../../assets/img/noble/level/level_31-40.png");
            }

            if (40 < val && val <= 50) {
                return require("../../assets/img/noble/level/level_41-50.png");
            }

            if (50 < val && val <= 60) {
                return require("../../assets/img/noble/level/level_51-60.png");
            }

            if (60 < val && val <= 70) {
                return require("../../assets/img/noble/level/level_61-70.png");
            }

            if (70 < val && val <= 80) {
                return require("../../assets/img/noble/level/level_71-80.png");
            }

            if (80 < val && val <= 90) {
                return require("../../assets/img/noble/level/level_81-90.png");
            }

            if (90 < val) {
                return require("../../assets/img/noble/level/level_91-105.png");
            }
        },
        // 关闭用户资料弹框
        colsePopup() {
            this.showUserInfo = false;
        },
        // 刷新列表
        refresh() {
            // this.blacklist();
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .blacklist {
    padding-top: 0.88rem;

    .powderBox {
        .powderItem {
            padding: 0 0.28rem;
            height: 1.28rem;
            background-color: #fff;
            border-bottom: 1px solid #f9f9f9;

            &:last-child {
                border-color: #fff;
            }

            img {
                margin-right: 0.2rem;
            }

            span {
                margin-right: 0.2rem;
            }
            .powderAvatar {
                width: 0.92rem;
                height: 0.92rem;
                border-radius: 50%;
            }

            .interactiveBtn {
                line-height: 0.52rem;
                height: 0.52rem;
                color: #fff;
                text-align: center;
                width: 1.68rem;
                border-radius: 0.8rem;
                font-size: 0.24rem;

                &.focusOn {
                    background: -webkit-linear-gradient(left,#fa7c87,#fc9799);
                    background: linear-gradient(90deg,#fa7c87,#fc9799);
                }
            }

            .nobleLevel {
                position: relative;
                height: 0.35rem;
                margin-right: 0.1rem;

                img {
                    width: 0.7rem;
                    height: 0.34rem;
                }

                span {
                    position: absolute;
                    right: 0.05rem;
                    color: #fff;
                    line-height: 0.35rem;
                    font-size: 0.2rem;

                    &.small {
                        right: 0.12rem;
                    }
                }
            }
        }
    }
  }
  </style>