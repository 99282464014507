<template>
    <div class="next findPassword">
        <div class="title">
            <span> {{ $t('login.forgetPsw') }} </span>
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.35rem" height="0.35rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="findPasswordBox">
            <van-field v-model.trim="code" :maxlength="4" autocomplete="off" :placeholder="$t('register.entryCodeTips')">
                <template #button>
                    <van-button color="#ff97a0" @click="confirmGetCode('sendVcode')" v-show="isGetCode" round size="small"
                        type="primary">{{ $t('common.getCode') }}</van-button>
                    <van-button v-show="!isGetCode" disabled color="#ff97a0" round size="small"
                        type="primary">{{ getCodeVal }}</van-button>
                </template>
            </van-field>
            <p class="pswTip">{{ $t('login.findPassword.codeSend') }} {{ showAccount }}</p>

            <p class="sexLoginPsw">{{ $t('login.findPassword.setNewPassword') }}</p>

            <van-field v-model.trim="password" v-show="isPassword" autocomplete="off" :maxlength="12"
                :placeholder="$t('login.findPassword.userNewPasswordTips')" :right-icon="require('../assets/img/eyeOpen.png')"
                @click-right-icon="isPassword = !isPassword" />
            <van-field v-model.trim="password" v-show="!isPassword" autocomplete="off" type="password" :maxlength="12"
                :placeholder="$t('login.findPassword.userNewPasswordTips')" :right-icon="require('../assets/img/eyeCloseR.png')"
                @click-right-icon="isPassword = !isPassword" />
            <van-field v-model.trim="confirmPassword" v-show="isConfirmPassword" autocomplete="off" :maxlength="12"
                :placeholder="$t('register.confirmNewPassword')" :right-icon="require('../assets/img/eyeOpen.png')"
                @click-right-icon="isConfirmPassword = !isConfirmPassword" />
            <van-field v-model.trim="confirmPassword" v-show="!isConfirmPassword" :maxlength="12" autocomplete="off" type="password"
                :placeholder="$t('register.confirmNewPassword')" :right-icon="require('../assets/img/eyeCloseR.png')"
                @click-right-icon="isConfirmPassword = !isConfirmPassword" />
            <p class="pswTip">{{ $t('login.findPassword.moreThan6Tips') }}</p>

            <div @click="confirm" :class="{ 'hasValue': isCanGo }" class="infoConfirm confirmBtn">{{ $t('common.confirm') }}
            </div>
        </div>
        <div style="display: none;" id="captcha"></div>
    </div>
</template>

<script>
import md5 from 'js-md5';
import utils from "@/utils/index";
import { sendVcode, sendEmailVcode, resetEmailPwd, resetPhonePwd } from "@/api/index";
import captcha from '@/pages/mixin/captcha';
export default {
    mixins: [captcha],
    data() {
        return {
            code: "",
            password: "",
            confirmPassword: "",
            isPassword: false,
            isConfirmPassword: false,
            account: "",

            isGetCode: true,
            getCodeVal: this.$t('common.getCode'),
            timer: null,
            type: 1, //1手机 2邮箱
            fromPath: ""
        }
    },
    computed: {
        isCanGo() {
            if (this.code && this.password) {
                return true;
            }
            return false;
        },
        showAccount() {
            let account = this.account;
            let phonerReg = /^0\d{9}$/,
                emailReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            if(phonerReg.test(account)) {
                account = utils.phoneHide(account);
            }

            if(emailReg.test(account)) {
                account = utils.emailHide(account);
            }

            return account;
        }
    },
    created() {
        if (this.$route.query.username) {
            this.account = this.$route.query.username;
            let emailReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

            if(emailReg.test(this.account)) {
                this.type = 2;
            }
        }
        this.userCaptcha('sendVcode');
    },
    beforeRouteEnter(to, from, next) {
        next((e) => {
        e.fromPath = from.path;
        });
    },
    methods: {
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 返回逻辑
        goBack() {
            let arr = ['/login', '/mine/setting/password'],
            index = arr.findIndex(item => {
                return this.fromPath.indexOf(item)
            })
            if (index != -1) {
                this.goOtherPage(this.fromPath);
            } else {
                this.goOtherPage('/login');
            }
        },
        // 确认
        confirm() {
            if (this.password.length < 6 || this.password.length > 12)  {
                this.$toast(this.$t('login.findPassword.limitPwdLength'));
                return;
            }

            if (this.password != this.confirmPassword) {
                this.$toast(this.$t('login.findPassword.twiceSame'));
                return;
            }

            let fun = null,
            param = {
                vcode: this.code,
                password: this.password
            }

            if (this.type == 1) {
                param.mobile = this.account;
                fun = resetPhonePwd;
            } else {
                param.email = this.account;
                fun = resetEmailPwd;
            }

            fun(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let dlInfo = {
                        account: this.account
                    }
                    localStorage.setItem('dlInfo', JSON.stringify(dlInfo));
                    this.$toast(this.$t('common.eidtSuccess'));
                    this.$router.push('/login');
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {})
        },
        // 获取验证码
        sendVcode() {
            if (!this.isGetCode) return;
            let uid = localStorage.getItem('uid') || utils.guid(),
            param = { 
                mobile: this.account,
                type: 3,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid()
            }, 
            fun = null;

            if (this.type == 1) {
                fun = sendVcode;
            } else {
                fun = sendEmailVcode;
            }

            fun(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('common.getCodeSuccess'));
                    let time = 179;
                    let that = this;
                    this.isGetCode = false;
                    this.timer = setInterval(() => {
                        if (time > 0) {
                            time--;
                            that.getCodeVal = time + "s";
                        } else {
                            clearInterval(this.timer);
                            that.getCodeVal = this.$t('common.getCode');
                            that.isGetCode = true;
                        }
                    }, 1000);
                } else {
                    clearInterval(this.timer);
                    this.getCodeVal = this.$t('common.getCode');
                    this.isGetCode = true;
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                clearInterval(this.timer);
                this.getCodeVal = this.$t('common.getCode');
                this.isGetCode = true;
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.findPassword {
    color: #333;
    padding-top: 0.88rem;
    background-color: #fff;

    .findPasswordBox {
        padding: 0.2rem 0.28rem 0;
        text-align: left;

        .infoConfirm {
            margin: 0.8rem auto 0;
        }

        .sexLoginPsw {
            margin-top: 0.6rem;
        }

        .pswTip {
            font-size: 0.24rem;
            margin-top: 0.1rem;
        }
    }
}</style>
<style lang="scss">.findPassword {
    .van-cell {
        border-bottom: 1px solid #ebeff1;
        padding-left: 0 !important;
    }
}</style>