<template>
    <div class="next setting_next_edit">
        <div class="title">
            <span> {{$t('common.eidt')}} {{ type == 1 ? $t('my.nickName') : $t('my.signature') }} </span>
            <div class="fixed" @click="goOtherPage('/mine/userInfo/edit')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.35rem" height="0.35rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>

            <div @click="save" class="title_ellipsis">
                {{$t('my.save')}}
            </div>
        </div>

        <van-cell-group inset>
            <!-- 长度动态 -->
            <van-field v-model="message" rows="2" autosize type="textarea" :maxlength="maxlength" :placeholder="tips"
                show-word-limit />
        </van-cell-group>
    </div>
</template>
  
<script>
import { modifyInfo } from "@/api/index";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            message: "",
            type: 1,
            maxlength: 10,
            tips: ""
        }
    },
    computed: {
        ...mapState(['member', 'baseInfo']),
    },
    created() {
        // 昵称 签名，此条件签名 默认昵称
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
            this.maxlength = 30;
            this.message = this.member.signature ? this.member.signature : "";
            this.tips = this.$t('my.signatureTips');
        } else {
            this.message = this.member.nickname ? this.member.nickname : "";
        }
    },
    methods: {
        // 保存
        save() {
            let params = {};
            if (this.type == 1) {
                params.nickname = this.message;

                let that = this;
                this.$dialog.confirm({
                    message: `${this.$t('my.editUserNameTips')} ${this.baseInfo.updateNikeNameMoney} ${this.$t('my.editUserNameTips2')} `,
                    className: "confirmDialog",
                    showCancelButton: true,
                    confirmButtonText: `${this.$t('common.confirm')}`,
                    cancelButtonText:  `${this.$t('common.cancel')}`,
                    closeOnClickOverlay: true,
                    confirmButtonColor: "#fa7c87"
                }).then(() => {
                    that.modifyInfo(params);
                }).catch(() => {
                    // on cancel
                });
                
            } else {
                params.signature = this.message;
                this.modifyInfo(params);
            }

            
        },
        // 修改个人资料
        modifyInfo(params) {
            modifyInfo(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('common.eidtSuccess'));
                    this.$store.dispatch('refreshUserInfo');
                    this.goOtherPage('/mine/userInfo/edit');
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log("catch");
            })
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
    }
}
</script>

<style lang="scss" scoped>
.setting_next_edit {
    background-color: #fff;

    .title_ellipsis {
        color: #eb457e;
    }
}
</style>
  
<style lang="scss">
.setting_next_edit .van-cell{
    background-color: #f5f5f5 !important;
}
</style>