<template>
  <div class="next invite">
    <div class="title">
        <span> {{$t('my.invite.title')}} </span>
        <div class="fixed" @click="goOtherPage('/mine')">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
    </div>

    <div class="inviteTop">
        <img src="../../../assets/img/my/next/banner.png" />
    </div>

    <div class="inviteData bgBox">
        <div class="flexCenter">
            <div class="inviteData_item">
                <div class="num">{{total.allProfit ? numFormat(digitalNumber(total.allProfit)) : '0'}}</div>
                <div class="font">
                    {{$t('my.invite.totalRevenue')}}
                    <van-popover class="questionPopover" v-model:show="showPopover" theme="dark" placement="top-start">
                        <div class="questionTips flexBetween">
                            <div>
                                <p>{{$t('my.invite.invitationBonus')}}</p>
                                <p>{{total.bindRebate ? numFormat(digitalNumber(total.bindRebate)) : '0'}} {{$t('my.finance.currency')}}</p>
                            </div>

                            <div>
                                <p>{{$t('my.invite.firstDepositBonus')}}</p>
                                <p>{{total.investRebate ? numFormat(digitalNumber(total.investRebate)) : '0'}} {{$t('my.finance.currency')}}</p>
                            </div>
                        </div>
                        <template #reference>
                            <van-icon color="#fa7c87" name="question-o" />
                        </template>
                    </van-popover>
                </div>
            </div>
            <div class="inviteData_item">
                <div class="num">{{total.shareCount ? numFormat(total.shareCount) : 0}}</div>
                <div class="font">{{$t('my.invite.sharePeople')}}</div>
            </div>
        </div>

        <div class="flexCenterBL">
            <div @click="goExchange" class="btndefult">{{$t('my.invite.shareRedemption')}}</div>
            <div @click="goWithdraw" class="btn">{{$t('my.invite.shareWithdrawal')}}</div>
        </div>

        <div @click="$router.push('/mine/invite/record/withdraw')" class="withdrawRecord">{{$t('my.invite.withdrawalRecords')}}</div>
    </div>

    <div class="inviteData">
        <div class="inviteCont">
            <div>{{ $t('my.invite.cont1') }} {{ numFormat(total.shareProfit) }}đ {{ $t('my.invite.cont11') }} {{ total.shareFee }}%{{ $t('my.invite.cont12') }}</div>
            <div>{{ $t('my.invite.cont2') }} {{ numFormat(total.promotionTopupMin) }}đ {{ $t('my.invite.cont21') }}</div>
            <div>{{ $t('my.invite.cont3') }} {{ total.promotionWithdrawFee }} % {{ $t('my.invite.cont31') }}  {{ numFormat(total.promotionWithdrawMin) }}đ {{ $t('my.invite.cont32') }}</div>
            <div>{{ $t('my.invite.cont4') }}</div>
            <div>{{ $t('my.invite.cont5') }}</div>
        </div>
    </div>

    <div class="inviteData minH">
        <div class="flexCenterBL inviteTitle">
            <span>{{$t('my.invite.invitationRecord')}}</span>
        </div>

        <div v-if="inviteTalbe.length>0" class="invitePeople">
            <!-- <div class="flexBetween item tableHead">
                <div>{{$t('my.nickName')}}</div>
                <div>{{$t('my.invite.registrationTime')}}</div>
            </div> -->

            <div v-for="(item, index) in inviteTalbe" :key="`${index}--inviteTalbe`" class="flexBetween item">
                <div class="flexCenter">
                    <span>{{ index+1 }}</span>
                    <img v-real-img="item.avatar" :errorimg="require(`../../../assets/img/errorImg/2.png`)"/>
                    {{item.nickname}}
                </div>
                <div>
                    ID:{{item.uid}}
                </div>
            </div>
            
        </div>

        <div v-else class="noData">
            <img src="../../../assets/img/record/noData.png"/>
            <p>{{$t('common.noData')}}</p>
        </div>
    </div>

    <div class="inviteFooter">
        <div @click="goSpreadTools" class="btn">{{$t('my.invite.nowInvita')}}</div>
    </div>

    <van-dialog :className="'downDialog'" v-model:show="showCodeBg" :closeOnClickOverlay="true" :showConfirmButton="false">
        <div v-if="showCodeBg" class="dialogCont" ref="creditQrCodeShare">
            <img src="../../../assets/img/my/next/shareCode.png" />
            <div class="qrcodeBg">
                <qrcode-vue id="qrcodeBox" 
                        :size="qrcodeVue.size"
                        :bgColor="qrcodeVue.bgColor"
                        :fgColor="qrcodeVue.fgColor" 
                        :value="shareUrl"></qrcode-vue>
            </div>

            <div class="flexBetween btnBox">
                <div @click="copyClick(shareUrl)">{{$t('my.invite.copyLink')}}</div>
                <div @click="saveImage">{{$t('my.invite.download')}}</div>
            </div>
        </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { shareLog } from "@/api/index";
import { userIndex } from "@/api/promotion";
import utils from "@/utils/index";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
import html2canvas from "html2canvas";
export default {
    components: {
        QrcodeVue 
    },
    data() {
        return {
            inviteTalbe: [],
            total: {},
            content: "",
            show: false,
            qrcodeVue: {
                size: 120,
                bgColor: '#fff',
                fgColor: '#000'
            },
            showPopover: false,
            showCodeBg: false,
            // 链接获取逻辑待添加
            shareUrl: ""
        }
    },
    computed: {
        ...mapState(['member', 'baseInfo']),
    },
    created() {
        this.shareUrl = this.baseInfo.shareUrl + `?puid=${this.member.uid}`;
        this.$store.commit("setState", { suEntry: this.$route.path });
        this.getTotal();
        this.shareLog();
    },
    methods: {
        // 点击弹框出现
        goSpreadTools() {
            // this.$store.commit("setState", { detailData: this.total });
            // this.$router.push('/mine/invite/spreadTools')
            this.showCodeBg = true;
        },
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm DD-MM-YYYY');
            }

            return "";
        },
        // 分享提现 
        goWithdraw() {
            this.$store.commit("setState", { detailData: this.total });
            this.$router.push('/mine/invite/withdraw');
        },
        // 兑换X币
        goExchange() {
            let detailData = JSON.parse(JSON.stringify(this.total));
            detailData.balance = (detailData.balance / this.baseInfo.currency);
            this.$store.commit("setState", { detailData });
            this.goOtherPage('/mine/invite/exchange');
        },
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 总收益
        getTotal() {
            // 各种余额以及分享人数(总收益,充值返利收益,邀请好友收益) 字段待确认
            let params = {
                uid: this.member.uid
            }
            userIndex(params).then(res => {
                if (res.code == 0) {
                    if (res.data) {
                        this.total = res.data;
                    } else {
                        this.$toast(this.$t('common.noData'));
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        // 邀请记录
        shareLog() {
            let params = {
                uid: this.member.uid,
                page: 0
            }
            shareLog(params).then(res => {
                if (res.code == 0) {
                    this.inviteTalbe = res.data;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        // 下载图标
        downLoadboxShow(url) {
            this.shareUrl = url;
            this.showCodeBg = false;
        },
        //保存图片
        saveImage() {
            // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
            html2canvas(this.$refs.creditQrCodeShare, {
                backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
                useCORS: true, //支持图片跨域
                scale: 1, //设置放大的倍数
            }).then((canvas) => {
                // 把生成的base64位图片上传到服务器,生成在线图片地址
                let url = canvas.toDataURL("image/png"); // toDataURL: 图片格式转成 base64
                this.imgUrl = url;
                //将图片下载到本地
                let a = document.createElement("a"); // 生成一个a元素
                let event = new MouseEvent("click"); // 创建一个单击事件
                a.download = name || this.$t('my.finance.QRCode'); // 设置图片名称没有设置则为默认
                a.href = this.imgUrl; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            });
        },
        // 邀请规则  // id 1 相关条款 2隐私政策 3 联系我们 4邀请规则
        // termsConfig() {
        //     termsConfig(4).then(res => {
        //         if (res.code == 0) {
        //             this.content = res.data.configContent;
        //         } else {
        //             if (res.msg) this.$toast(res.msg);
        //         }
        //     }).catch(err => {
        //         console.log(err);
        //     });
        // }
    }
}
</script>

<style lang="scss" scoped>
.invite {
    padding-top: 0.88rem;
    padding-bottom: 2rem;
    height: auto;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);
    }

    .inviteTop {
        width: 100%;
        height: 5.47rem;
        text-align: center;

        img {
            width: 100%;
            height: 5.47rem;
        }
    }

    .inviteData {
        margin: 0.2rem 0.28rem 0.2rem;
        background: #fff;
        padding: 0.2rem 0;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        &.minH {
            min-height: 6rem;
            position: relative;
        }

        .withdrawRecord {
            margin: 0.4rem 0 0.3rem;
            color: #e63434;
        }

        &.bgBox {
            // background: -webkit-linear-gradient(left,#4A5E88,#243050);
            // background: linear-gradient(90deg,#4A5E88,#243050);
            border-radius: 0.2rem;

            .inviteData_item .font {
                color: #626872;

                i {
                    font-size: 0.32rem;
                }
            }
        }

        .inviteData_item {
            flex: 1;

            .num {
                color: #fa7c87;
                font-size: 0.55rem;
            }

            .font {
                margin-top: 0.2rem;
                color: #626872;
                font-size: 0.2rem;
                position: relative;

                img {
                    width: 0.28rem;
                    position: absolute;
                    bottom: 0;
                    margin-left: 0.05rem;
                }
            }
        }

        .inviteTitle {
            img {
                width: 1.98rem;
                height: 0.2rem;
            }

            span {
                margin: 0 0.28rem;
                font-size: .3rem;
                color: #333;
            }
        }

        .linkBox {
            border-radius: 0.08rem;
            width: 6.38rem;
            height: 0.88rem;
            margin: 0.44rem auto 0;
            overflow: hidden;

            .inputBox {
                width: 4.78rem;
                height: 0.88rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: .88rem;
                padding-left: 0.2rem;
                font-size: .24rem;
                color: #575452;
                background-color: #eeeff4;
                text-align: left;
            }

            .copyLink {
                width: 1.6rem;
                height: 0.88rem;
                text-align: center;
                line-height: .88rem;
                font-size: 0.28rem;
                background-image: -webkit-linear-gradient(353deg,#6996FF,#127dff 100%);
                background-image: linear-gradient(97deg,#6996FF,#127dff 100%);
                color: #fff;
            }
        }

        .inviteCont {
            text-align: left;
            padding: 0 0.28rem;
            margin: 0.28rem 0;
            color: #bf8414;

            p {
                margin-bottom: 0.32rem;
            }
        }
    }

    .invitePeople {
        margin-top: 0.38rem;
        padding: 0 0.28rem;
        position: relative;

        .item {
            padding-bottom: 0.44rem;
            color: #626872;

            &.tableHead {
                color: #333;
            }

            img {
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
                margin-right: 0.1rem;
            }

            span {
                margin-right: 0.3rem;
            }
        }

    }

    .btn {
        width: 2.4rem;
        height: 0.64rem;
        line-height: .64rem;
        background-color: #fa7c87;
        border: 1px solid #fa7c87;
        text-align: center;
        border-radius: 0.4rem;
        color: #fff;
        font-size: .28rem;
        margin: 0.4rem auto 0;
    }

    .btndefult {
        width: 2.4rem;
        height: 0.64rem;
        line-height: .64rem;
        text-align: center;
        border-radius: 0.4rem;
        color: #fa7c87;
        font-size: .28rem;
        margin: 0.4rem auto 0;
        border: 1px solid #fa7c87;
    }

    .inviteFooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        padding: 0.28rem;
        background-color: #fff;
        box-shadow: 0 -0.04rem 0.2rem 0 #e8edf0;
    
        .btn {
            width: 100%;
            height: 0.88rem;
            line-height: .88rem;
            border-radius: 0.4rem;
            margin: 0rem auto;
        }
    }

    .dialogCont {
        width: 5.76rem;
        height: 9rem;
        margin: auto;
        position: relative;

        img {
            width: 5.8rem;
            position: relative;
            z-index: 1;
        }
        .qrcodeBg {
            position: absolute;
            bottom: 2.05rem;
            left: 0.28rem;
            z-index: 2;
            width: calc(100% - 0.56rem);
            text-align: center;
        }

        .btnBox {
            font-size: 0.28rem;

            div {
                width: 50%;
                text-align: center;
                padding: 0.24rem 0 0.2rem;
                margin-top: -0.1rem;

                &:nth-child(1) {
                    color: #fff;
                    background-color: #fa7c87;
                }

                &:last-child {
                    color: #fa7c87;
                    background-color: #fff;
                }
            }
        }
    }
}

.questionTips {
    font-size: 0.24rem;
    padding: 0.2rem;

    div:nth-child(1) {
        padding-right: 0.44rem;
        border-right: 1px solid #fff;
        text-align: center;
    }

    div:nth-child(2) {
        padding-left: 0.44rem;
        text-align: center;
    }

    p:nth-child(2) {
        font-size: 0.28rem;
        margin-top: 0.12rem;
    }
}
</style>

<style lang="scss">
.downDialog {
    &.van-dialog {
        background: transparent;
    }
}

.questionPopover {
    &.van-popover--dark {
        .van-popover__arrow {
            color: #00000080 !important;
        }

        .van-popover__content {
            background-color: #00000080 !important;
        }
    }
}
</style>