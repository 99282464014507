<template>
  <div class="next">
    <div class="title">
        <span> {{$t('my.systemSet.title')}} </span>
        <div class="fixed" @click="goOtherPage('/mine')">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
    </div>

    <div class="item">
        <div @click="goOtherPage('/mine/set/blackList')" class="editPsw flexBetween border">
            <span>{{$t('my.systemSet.blacklist')}}</span>
            <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5184" width="0.26rem" height="0.26rem"><path d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z" p-id="5185" fill="#788597"></path></svg>
        </div>
    </div>

    <div class="item">
        <div @click="goOtherPage('/mine/about')" class="editPsw flexBetween border">
            <span>{{$t('my.systemSet.userAgreement')}}</span>
            <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5184" width="0.26rem" height="0.26rem"><path d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z" p-id="5185" fill="#788597"></path></svg>
        </div>
    </div>

    <div class="item">
        <div @click="goOtherPage('/mine/deviceManagement')" class="editPsw flexBetween border">
            <span>{{$t('my.systemSet.deviceManagement')}}</span>
            <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5184" width="0.26rem" height="0.26rem"><path d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z" p-id="5185" fill="#788597"></path></svg>
        </div>
    </div>

    <div @click="quit" class="item marginTop">
        <div class="editPsw border flexCenterBL">
            <span>{{$t('my.systemSet.loginOut')}}</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        member() {
            return this.$store.state.member;
        },
        needCashPassword() {// 是否设置取款密码
            if (this.member && this.member.needCashPassword) {
                return true;
            }

            return false;
        },
        // baseInfo() {
        //     return this.$store.state.baseInfo;
        // },
    },
    created() {
        this.$store.commit("setState", { entry: this.$route.path });
    },
    methods: {
        goOtherPage(path) {
            this.$router.push(path);
        },
        quit() {
            this.$dialog.confirm({
                message: this.$t('my.systemSet.loginOutTips'),
                className: "noTItleDialog",
                confirmButtonColor: "#fa7c87",
                confirmButtonText: this.$t('common.sure'),
                cancelButtonText: this.$t('common.cancel'),
                overlay: true
            })
            .then(() => {
                // 登出逻辑需要编辑
                this.$store.commit("setState", { member: null });
                sessionStorage.removeItem("token");
                this.$router.push("/login");
                this.$store.dispatch("loginOutTm");
            })
            .catch(() => {
                // on cancel
            });
        },
        goSetFundPsw() {
            if (!this.member) {
                this.goOtherPage('/login');
            }

            // if (this.member && !this.member.phone) {
            //     this.$dialog.confirm({
            //         title: '手机绑定',
            //         message: '为了保障您的账户安全，需要您绑定手机号码后方可设置取款密码',
            //         confirmButtonColor: "#fa7c87",
            //         confirmButtonText: "确定",
            //         overlay: true
            //     })
            //     .then(() => {
            //         this.goOtherPage('/mine/userInfo/phone');
            //     })
            //     .catch(() => {
            //         // on cancel
            //     });
            // }

            if (this.member && this.member.phone) {
                this.$store.commit("setState", { setPasswordEntry: "" });
            }

            this.goOtherPage('/mine/setting/fundPsw');
        }
    }
}
</script>

<style lang="scss" scoped>
.next {
    background-color: #f8f8f8;
    font-size: 0.28rem;
    color: #333;
    .item {
        position: relative;
        padding-left: 0.3rem;
        height: 1rem;
        background-color: #fff;
        vertical-align: middle;
        overflow: hidden; 

        &.marginTop {
            margin-top: 0.2rem;
        }

        .editPsw {
            width: 100%;
            height: 100%;
            padding-right: 0.28rem;

            &.border {
                border-bottom: 1px solid #f2f2f2;
            }

            .noSet {
                color: #a5a9b3;
                font-size: .28rem;
                margin-right: 0.2rem;
            }
        }
    }
}
</style>
