<template>
  <div class="next register">
    <!-- 输入手机号 或 邮箱地址 及 校验 -->
    <div v-show="activeStep===0">
      <div class="title">
        <span>{{ $t("register.title") }}</span>
        <div class="fixed" @click="goOther('/login')">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
      </div>
      
      <!-- <van-tabs v-model:active="active" title-inactive-color="#969696" animated color="#fa7c87" title-active-color="#fa7c87" line-width="1.2rem" line-height="2px">
        <van-tab :title="$t('register.phoneRegister')">
          <p class="registerSuTitle">{{ $t("register.suPhoneTitle") }}</p>
          <van-field v-model.trim="registerForm.phone" :maxlength="10" autocomplete="off" @keyup="registerForm.phone=registerForm.phone.replace(/[^\d]/g,'')" clearable :placeholder="$t('register.entryPhoneTip')"/>
          <div @click="nextStep" class="nextStep confirmBtn hasValue">{{ $t("common.nextStep") }}</div>
        </van-tab>
        <van-tab :title="$t('register.emailRegister')">
          <p class="registerSuTitle">{{ $t("register.suEmailTitle") }}</p>
          <van-field v-model.trim="registerForm.email" autocomplete="off" clearable :placeholder="$t('register.entryEmailTip')"/>
          <div @click="nextStep" class="nextStep confirmBtn hasValue">{{ $t("common.nextStep") }}</div>
        </van-tab>
      </van-tabs> -->

      <div class="registerTitleBox">
        <p class="registerSuTitle">{{ $t("register.suPhoneTitle") }}</p>
        <van-field v-model.trim="registerForm.phone" :maxlength="10" autocomplete="off" @keyup="registerForm.phone=registerForm.phone.replace(/[^\d]/g,'')" clearable :placeholder="$t('register.entryPhoneTip')"/>
        <div @click="nextStep" class="nextStep confirmBtn hasValue">{{ $t("common.nextStep") }}</div>
      </div>

      <div style="display: none;" id="captcha"></div>
    </div>

    <!-- 确认是否是正确 手机 或 邮箱弹框  userCaptcha-->
    <van-dialog v-model:show="showDialog" show-cancel-button confirm-button-Color="#fa7c87" cancel-button-Color="#515151"
      @confirm="confirmAccount"
      :confirmButtonText="$t('common.confirm')"
        :cancelButtonText="$t('common.cancel')">
      <div v-show="active == 0" class="registerNumDialog">
        <p>{{ registerForm.phone }}</p>
        <p>{{ $t('register.confirmPhoneTips') }}</p>
      </div>

      <div v-show="active == 1" class="registerNumDialog">
        <p>{{ registerForm.email }}</p>
        <p>{{ $t('register.confirmEmailTips') }}</p>
      </div>
    </van-dialog>

    <!-- 获取验证码步骤 -->
    <div v-show="activeStep===1">
      <div class="title">
        <span>{{ $t('my.addCard.code') }}</span>
        <div class="fixed" @click="activeStep = 0">
          <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
      </div>

      <div class="getCodeBox">
        <p>{{ $t('register.verificationCodeSent') }}</p>
        <p>{{ active == 0 ? registerForm.phone : registerForm.email }}</p>

        <van-field v-model.trim="registerForm.code" autocomplete="off" :maxlength="4" :placeholder="`${$t('register.entryCodeTips')}`"/>
        <div @click="confirmAccount" class="codeBox">
          <div>{{ getCodeVal }}</div>
        </div>

        <div @click="codeValidate" class="nextStep confirmBtn hasValue">{{ $t("common.nextStep") }}</div>
      </div>
    </div>

    <!-- 完善个人信息 -->
    <div v-show="activeStep===2">
      <div class="title">
        <span>{{ $t('register.perfectPersonalInfo') }}</span>
        <div class="fixed" @click="activeStep = 1">
          <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.35rem" height="0.35rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
      </div>

      <div class="infoBox">
        <div class="infoPhoneBoxS">
          <div @click="showAvatar=true" class="infoPhoneBox">
            <img v-real-img="avatar" :errorimg="require(`../assets/img/errorImg/2.png`)"/>
            <van-icon class="photograph" name="photograph" size="0.36rem" color="#fa7c87" />
          </div>
          <!-- <van-uploader :before-read="beforeRead" :after-read="afterRead">
            <div class="infoPhoneBox">
              <img v-real-img="avatar" :errorimg="require(`../assets/img/errorImg/2.png`)"/>
              <van-icon class="photograph" name="photograph" size="0.36rem" color="#fa7c87" />
            </div>
          </van-uploader> -->
        </div>

        <van-field v-model.trim="registerForm.nickname" :maxlength="10" :label="`${$t('my.nickName')}`" autocomplete="off" :placeholder="`${$t('my.nickNameTips')}`"/>

        <div class="sex">
          <span>{{ $t('my.sex') }}</span>
          <div class="radiovan">
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio icon-size="0.28rem" checked-color="#fa7c87" :name="1">{{ $t('my.man') }}</van-radio>
              <van-radio icon-size="0.28rem" checked-color="#fa7c87" :name="2">{{ $t('my.woman') }}</van-radio>
            </van-radio-group>
          </div>
        </div>

        <p class="sexLoginPsw">{{ $t('login.findPassword.setNewPassword') }}</p>

        <van-field  v-model.trim="registerForm.password" :maxlength="12" v-show="isPasswordOpen" autocomplete="off" @click-right-icon="isPasswordOpen=!isPasswordOpen"
          :right-icon="require('../assets/img/eyeOpen.png')" :placeholder="`${$t('login.userPasswordTips')}`" />
        <van-field v-show="!isPasswordOpen" v-model.trim="registerForm.password" :maxlength="12" autocomplete="off" :placeholder="`${$t('login.userPasswordTips')}`" 
          :right-icon="require('../assets/img/eyeCloseR.png')" type="password" @click-right-icon="isPasswordOpen=!isPasswordOpen"/>
        
        <van-field v-show="isPasswordOpenConfirm" v-model.trim="registerForm.confirmPassword" :maxlength="12" autocomplete="off" :placeholder="`${$t('register.confirmNewPassword')}`" 
          :right-icon="require('../assets/img/eyeOpen.png')" @click-right-icon="isPasswordOpenConfirm=!isPasswordOpenConfirm"/>
        <van-field v-show="!isPasswordOpenConfirm" v-model.trim="registerForm.confirmPassword" :maxlength="12" autocomplete="off" :placeholder="`${$t('register.confirmNewPassword')}`" 
          :right-icon="require('../assets/img/eyeCloseR.png')" type="password"  @click-right-icon="isPasswordOpenConfirm=!isPasswordOpenConfirm"/>
        <p class="pswTip">{{ $t('my.enterLeastSix') }}</p>

        <div v-show="!canRegInfo" class="infoConfirm confirmBtn">{{ $t('common.confirm') }}</div>
        <div v-show="canRegInfo" @click="regInfo" class="infoConfirm confirmBtn hasValue">{{ $t('common.confirm') }}</div>
      </div>
    </div>

    <!-- 绑定银行卡 -->
    <div v-show="activeStep===3">
      <div class="title">
        <span>{{ $t('my.addCard.title') }}</span>
        <div @click="$router.push('/home')" class="title_ellipsis">
          {{ $t('register.jumpOver') }}
        </div>
      </div>

      <div class="getCodeBox bankBox">
        <van-field :label="`${$t('my.addCard.realName')}`" input-align="right" v-model.trim="bankInfo.trueName" autocomplete="off" :placeholder="`${$t('my.addCard.realNameTips')}`"/>
        <van-field :label="`${$t('my.finance.bankNo')}`" input-align="right" v-model.trim="bankInfo.cardNo" autocomplete="off" :placeholder="`${$t('my.finance.bankNoTips')}`"/>
        <van-field :label="`${$t('my.finance.bankName')}`" input-align="right" v-model.trim="bankInfo.bankName" autocomplete="off"
          @click="openModal" :placeholder="`${$t('my.finance.bankNameTips')}`"/>
        <van-field :label="`${$t('my.addCard.branch')}`" input-align="right" v-model.trim="bankInfo.bankCity" autocomplete="off" :placeholder="`${$t('my.addCard.branchTips')}`"/>
        
        <div v-show="active == 0">
          <van-field v-model.trim="registerForm.phoneHide" disabled autocomplete="off">
            <template #button>
              <van-button @click="confirmAccount" v-show="isGetBankCode && registerForm.phone" color="linear-gradient(90deg,#fa7c87,#fc9799)" round size="small" type="primary">{{$t('common.getCode')}}</van-button>
              <van-button v-show="!isGetBankCode || !registerForm.phone" color="linear-gradient(90deg,#fa7c87,#fc9799)" disabled round size="small" type="primary">{{getBankCodeVal}}</van-button>
            </template>
          </van-field>

          <van-field :label="`${$t('my.addCard.code')}`" input-align="right" v-model.trim="bankInfo.code" :maxlength="4" autocomplete="off" :placeholder="$t('register.entryCodeTips')" />
        </div>

        <div v-show="active == 1">
          <van-field :label="`${$t('my.proxy.phoneNumber')}`" input-align="right" v-model.trim="registerForm.phone" :maxlength="10" 
            @keyup="registerForm.phone=registerForm.phone.replace(/[^\d]/g,'')"  autocomplete="off" :placeholder="`${$t('my.proxy.phoneNumberTips')}`"/>
          <van-field v-model.trim="bankInfo.code" autocomplete="off" :placeholder="$t('register.entryCodeTips')">
            <template #button>
              <van-button @click="confirmAccount" color="#fa7c87" v-show="isGetBankCode && registerForm.phone" round size="small" type="primary">{{$t('common.getCode')}}</van-button>
              <van-button v-show="!isGetBankCode || !registerForm.phone" disabled color="#fa7c87" round size="small" type="primary">{{getBankCodeVal}}</van-button>
            </template>
          </van-field>
        </div>

        <van-field :label="`${$t('my.addCard.withdrawPassword')}`" input-align="right" v-model.trim="bankInfo.cashPassword" autocomplete="off" :maxlength="6" :placeholder="`${$t('my.addCard.withdrawPasswordTips')}`"/>

        <div @click="userBank" class="infoConfirm confirmBtn hasValue">{{ $t('my.addCard.title') }}</div>
      </div>
    </div>

    <!-- 开户银行 -->
    <van-action-sheet v-model:show="showBankName" :title="`${$t('my.addCard.chooseBank')}`" class="currencyModel">
      <div>
        <!-- <van-search v-model.trim="bankInfo.bankName" autocomplete="off" shape="round" :placeholder="`${$t('my.finance.bankNameTips')}`" /> -->
        <div class="bankListCont">
          <div v-for="(item, index) in bankList" :key="`${index}--bankList`" @click="bankItem(item, index)"
            class="bankListCont_item flexBetween">
            <div class="bankListCont_item_left flexCenter">
              <img v-real-img="item.img" />
              <div>
                <p>{{ item.bankCode }}</p>
                <p>{{ item.bankName }}</p>
              </div>
            </div>
            <div v-show="bankActive==index">
              <van-icon name="success" color="#fa7c87" size="0.5rem" />
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>

    <!-- 圆角弹窗（底部） -->
    <van-popup
    v-model:show="showAvatar"
    round
    position="bottom"
    style="overflow: hidden;"
    v-if="showAvatar"
    >
      <swiper
        ref="mySwiper"
        :grabCursor="true"
        :slidesPerView="5"
        class="mySwiper"
        :centeredSlides="true"
        :spaceBetween="30"
        :initialSlide="initialSlide"
        :pagination="{
        type: 'fraction',
        }"
      >
        <SwiperSlide v-for="(item, index) in avatarList" :key="`${index}-edituserinfo-avatarList`" @click="itemClick(`avactar${index}`)">
            <img :id="`avactar${index}`" :src="item"/>
        </SwiperSlide>
      </swiper>

      <van-uploader :before-read="beforeRead" :after-read="afterRead" class="editAitem">
          {{ $t('my.photoAlbum') }}
      </van-uploader>
      <!-- <van-uploader :before-read="beforeRead" :after-read="afterRead" class="editAitem borT">
          {{ $t('my.photograph') }}
      </van-uploader> -->

      <label class="editAitem borT" for="camera" style="cursor: pointer;">{{ $t('my.photograph') }}</label>
      <input id="camera" type="file" accept="image/*" capture="camera"  @change="startCamera" style="display: none;">

      <div class="jianju"></div>
      <div @click="showAvatar=false" class="editAitem editAitemB">{{ $t('common.cancel') }}</div>
    </van-popup>
  </div>
</template>

<script>
import { phoneIsRegiste, emailIsRegiste, userCaptcha, sendVcode, codeValidate,
  codeValidateEmail, phoneRegInfo, emailRegInfo,
  sendEmailVcode, userBank, getInfo, sendCashCode } from "@/api/index";
import { userBankList } from "@/api/config";
import { uploadAvatarR } from "@/api/uploadConfig";
import utils from "@/utils/index";
import { imgPreview } from "@/utils/imgPreview";
import md5 from 'js-md5';
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCards } from 'swiper/modules';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      active: 0, //0 手机 1 邮箱

      // 注册参数
      registerForm: {
        phone: "",
        email: "",
        code: "",
        password: "",
        confirmPassword: "",
        nickname: "",
        phoneHide: ""
      },
      options: null,
      showDialog: false,

      activeStep: 0, //步骤 0 输入手机/邮箱  1验证码  2 完善信息 3绑卡 
      sex: 1, //性别 1男 2 女

      // 验证码
      isGetCode: true,
      getCodeVal: this.$t('common.getCode'),
      timer: null,

      // 密码
      isPasswordOpen: false,
      isPasswordOpenConfirm: false,

      // 绑卡参数
      bankInfo: {
        code: "",
        cashPassword: "",
        bankCity: "",
        trueName: "",
        bankName: "",
        cardNo: "",
      },

      // 易盾网易校验
      loading: false,
      registerCaptch: {},
      captchaIns: null,
      captchaObj: {},

      // 绑卡银行验证码
      isGetBankCode: true,
      getBankCodeVal: this.$t('common.getCode'),
      timerBank: null,

      // 银行列表弹框
      showBankName: false,
      bankList: [],
      bankActive: 0,

      avatar: "", //头像地址
      puid: 0,

      isReging: false, // 提交资料中

      showAvatar: false,
      modules: [EffectCards],
      avatarList: [
        require('../assets/img/my/avatar/1.png'),
        require('../assets/img/my/avatar/2.png'),
        require('../assets/img/my/avatar/3.png'),
        require('../assets/img/my/avatar/4.png'),
        require('../assets/img/my/avatar/5.png'),
        require('../assets/img/my/avatar/6.png'),
        require('../assets/img/my/avatar/7.png'),
        require('../assets/img/my/avatar/8.png')
      ],
      initialSlide: 4
    }
  },
  computed: {
    ...mapState(['configBadge', 'member']),
    // 完善信息 确认按钮是否高亮
    canRegInfo() {
      let flag = false;
      if (this.registerForm.nickname && this.registerForm.password && this.registerForm.confirmPassword) {
        flag = true;
      }
      return flag;
    }
  },
  mounted() {
    this.userCaptcha();
    if (sessionStorage.getItem('puid')) {
      this.puid = sessionStorage.getItem('puid');
    }
  },
  unmounted () {
    this.destroyCap();
    clearInterval(this.timer);
    clearInterval(this.timerBank);
  },
  methods: {
    startCamera() {
      const file = event.target.files[0];
      if (!file) {
          return;
      }
      
      if (!file.type.startsWith('image/')) {
          return this.$toast(this.$t('my.imgLoadTips'));
      }
      
      this.$store.commit("setState", { isLoadForLoad: true });
      let formData = new FormData();
      imgPreview(file, (files) => {
        formData.append("file", files);
        formData.append("type", "app_avatar");
        uploadAvatarR(formData).then(res => {
          if (res.code === 0 || res.code === '0') {
            // 上传成功之后 需要进行保存处理，调用用户头像保存
            this.$toast(this.$t('register.uploadedSucs'));
            this.avatar = "https://" + res.data.filePath;
            this.showAvatar = false;
          } else {
            if (res.msg) this.$toast(res.msg);
          }
          this.$store.commit("setState", { isLoadForLoad: false });
        }).catch(() => {
          console.log('catch');
          this.$store.commit("setState", { isLoadForLoad: false });
        })
      });
    },
    itemClick(id) {
      var img = document.getElementById(id);
      var fileName = 'avactar.png';
      var file = this.imgToFile(img, fileName);
      
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "app_avatar");
      this.$store.commit("setState", { isLoadForLoad: true });
      uploadAvatarR(formData).then(res => {
          if (res.code === 0 || res.code === '0') {
            // 上传成功之后 需要进行保存处理，调用用户头像保存
            this.$toast(this.$t('register.uploadedSucs'));
            this.avatar = "https://" + res.data.filePath;
            this.showAvatar = false;
          } else {
            if (res.msg) this.$toast(res.msg);
          }
          this.$store.commit("setState", { isLoadForLoad: false });
      }).catch(() => {
          console.log('catch');
          this.$store.commit("setState", { isLoadForLoad: false });
      })
    },
    imgToFile(img, fileName) {
      // 获取img元素的画布
      var canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      
      // 将画布内容转换为DataURL
      var dataURL = canvas.toDataURL('image/png');
      
      // 将DataURL转换为Blob
      return this.dataURLToBlob(dataURL, fileName);
    },
    dataURLToBlob(dataURL, fileName) {
      var arr = dataURL.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      
      while(n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      // 创建Blob对象
      var blob = new Blob([u8arr], {type: mime});
      
      // 创建File对象
      var file = new File([blob], fileName, {type: mime});
      
      return file;
    },
    // 上传图标 格式校验
    beforeRead(file) {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
            this.$toast(this.$t('my.imgLoadTips'));
            return false;
        }
        return true;
    },
    // 上传图片 , detail
    afterRead(file) {
      // let that = this;
      // let index = detail.index;
      //上传的图片可能分为单张上传和多张上传
      if (file instanceof Array) {
        for (let i = 0; i < file.length; i++) {
          (function (arg) {
            let formData = new FormData();
            imgPreview(file[arg].file, async (files) => {
              formData.append("file", files);
              formData.append("kind", "indexPic");
            });
          })(i);
        }
      } else {
        let formData = new FormData();
        imgPreview(file.file, (files) => {
          formData.append("file", files);
          formData.append("type", "app_avatar");
          uploadAvatarR(formData).then(res => {
            if (res.code === 0 || res.code === '0') {
              // 上传成功之后 需要进行保存处理，调用用户头像保存
              this.$toast(this.$t('register.uploadedSucs'));
              this.avatar = "https://" + res.data.filePath;
              this.showAvatar = false;
            } else {
              if (res.msg) this.$toast(res.msg);
            }
          }).catch(() => {
              console.log('catch');
          })
        });
      }
    },
    // 确认手机 或 邮箱 弹框确认
    confirmAccount() {
      // 网易云校验 在发送手机验证码
      this.captchaIns && this.captchaIns.refresh()
      this.captchaIns && this.captchaIns.popUp();
      // 初始化失败
      if(!this.captchaIns){
          this.initNeCaptchas(true)
      }
    },
    // 选择银行
    bankItem(item, index) {
      this.bankActive = index;
      this.showBankName = false;
      this.bankInfo.bankName = item.bankName;
    },
    // 获取注册验证码
    sendVcoderegister() {
      if (!this.isGetCode) return;
      let uid = localStorage.getItem('uid') || utils.guid(), fun = null;
      let param = {
        os: 0,
        sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
        timestamp: new Date().getTime(),
        udid: localStorage.getItem('uid') || utils.guid(),
        type: 1
      }

      if (this.active == 0) {
        param.mobile = this.registerForm.phone;
        fun = sendVcode;
      } else {
        param.mobile = this.registerForm.email;
        fun = sendEmailVcode;
      }

      fun(param).then(res => {
        if (res.code === 0 || res.code === '0') {
            this.$toast(this.$t('common.getCodeSuccess'));
            this.activeStep = 1;
            let time = 179;
            let that = this;
            this.isGetCode = false;
            this.timer = setInterval(() => {
                if (time > 0) {
                    time --;
                    that.getCodeVal = time+"s";
                } else {
                    clearInterval(this.timer);
                    that.getCodeVal = this.$t('common.getCode');
                    that.isGetCode = true;
                }
            }, 1000);
          } else {
            clearInterval(this.timer);
            this.getCodeVal = this.$t('common.getCode');
            this.isGetCode = true;
            if (res.msg) this.$toast(res.msg);
          }
        }).catch(() => {
          clearInterval(this.timer);
          this.getCodeVal = this.$t('common.getCode');
          this.isGetCode = true;
        });
    },
    // 绑定银行卡获取验证码
    getCode() {
      if (!this.isGetBankCode) {
          return;
      }
      let params = {
        mobile: this.registerForm.phone,
        areaCode: this.member && this.member.areaCode ? this.member.areaCode : '84',
        captchaValidate: '',	//网易行为验证返回编码
        type: 5,
        captchaType: 1, //字段逻辑待添加
        verificationNo: ''
      }
      this.isGetBankCode = false;
      let that = this;
      sendCashCode(params).then(res => {
          if (res.code == 0) {
              this.$toast(this.$t('common.getCodeSuccess'));
              let time = 179;
              this.getBankCodeVal = time + 's';
              clearInterval(this.timerBank);
              this.timerBank = setInterval(() => {
                  if (time > 0) {
                      time--;
                      that.getBankCodeVal = time + 's';
                  } else {
                      that.getBankCodeVal = this.$t('common.getCode');
                      that.isGetBankCode = true;
                      clearInterval(this.timerBank);
                  }
              }, 1000);
          } else {
              if (res.msg) this.$toast(res.msg);
              that.getBankCodeVal = this.$t('common.getCode');
                that.isGetBankCode = true;
                clearInterval(this.timerBank);
          }
      }).catch(() => {
        that.getBankCodeVal = this.$t('common.getCode');
        that.isGetBankCode = true;
        clearInterval(this.timerBank);
      });
    },
    // 网易验证码
    initNeCaptchas() {
      var that = this;
      initNECaptcha({
          captchaId: this.captchaObj.verificationNo,
          element: '#captcha',
          // mode: 'popup',
          // appendTo: '#appendWrap',
          lang: "vi",
          enableClose: false,
          onClose: function () {
            console.log('onClose');
              // 弹出关闭结束后将会触发该函数
          },
          onVerify: function (err, data) {
              if (!err) {
                that.registerCaptch = data;
                // 验证成功后，调用 close 方法关闭弹框
                // TODO: 验证成功后继续进行业务逻辑
                if (that.activeStep != 3) {
                  that.sendVcoderegister();
                } else {
                  that.getCode();
                }
              }
          }
      }, function onload (instance) {
          // 初始化成功
          that.captchaIns = instance;
          if(that.loading){
            that.captchaIns && that.captchaIns.popUp();
          }
      }, function onerror () {
          // 验证码初始化失败处理逻辑，例如：提示用户点击按钮重新初始化
      })

    },
    // 获取行为验证
    userCaptcha() {
      userCaptcha().then(res => {
        if (res.code === 0 || res.code === '0') {
          this.captchaObj = res.data;
          this.initNeCaptchas();
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {})
    },
    // 手机号注册-验证码校验
    codeValidate() {
      let fun = null;
      let param = {
        vcode: this.registerForm.code
      }

      if (this.active == 0) {
        param.mobile = this.registerForm.phone;
        fun = codeValidate;
      } else {
        param.email = this.registerForm.email;
        fun = codeValidateEmail;
      }

      fun(param).then(res => {
        if (res.code === 0 || res.code === '0') {
          if(this.timer) clearInterval(this.timer);
          this.activeStep = 2;
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {})
    },
    // 完善信息
    regInfo() {
      if (this.isReging) return;
      if (!this.avatar) return this.$toast(this.$t('register.noHasAvactar'));
      if (this.registerForm.password.length < 6 || this.registerForm.password.length > 12) return this.$toast(this.$t("login.findPassword.limitPwdLength"));
      if (this.registerForm.password != this.registerForm.confirmPassword) return this.$toast(this.$t("register.inconsistentTwice"));

      this.isReging = true;
      let fun = null, uid = localStorage.getItem('uid') || utils.guid(),
      time = new Date().getTime(), sign = md5(`${uid}jgyh,kasd${time}`),
      param = {
        udid: uid,
        vcode: this.registerForm.code,
        password: this.registerForm.password,
        puid: this.puid,
        nickname: this.registerForm.nickname,
        sex: this.sex,
        os: 0,
        sign: sign,
        timestamp: time,
        version: "1.0.2",
        softVersion: "1.0.2",
        avatar: this.avatar
      }
      // if (this.avatar) {
      //   param.avatar = this.avatar;
      // }
      if (!this.puid) {
        param.domain = window.location.host;
      }

      if (this.active == 0) {
        param.mobile = this.registerForm.phone;
        fun = phoneRegInfo;
      } else {
        param.email = this.registerForm.email;
        fun = emailRegInfo;
      }

      fun(param).then(res => {
        if (res.code === 0 || res.code === '0') {
          let dlInfo = {
            account: !this.active ? this.registerForm.phone : this.registerForm.email
          },
          liveAE = {
            randomKey: res.data.randomKey,
            randomVector: res.data.randomVector
          }
          this.$store.commit("setState", { liveAE });
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem('dlInfo', JSON.stringify(dlInfo));
          this.registerForm.phoneHide = utils.phoneHide(this.registerForm.phone);
          this.getInfo();
        } else {
          if (res.msg) this.$toast(res.msg);
          this.isReging = false;
        }
      }).catch(() => {
        this.isReging = false;
      })
    },
    // 绑定银行卡
    userBank() {
      let uid = localStorage.getItem('uid') || utils.guid(),
      time = new Date().getTime();
      let param = {
        os: 0,
        sign: md5(`${uid}jgyh,kasd${time}`),
        timestamp: time,
        udid: uid,
        mobile: this.registerForm.phone,
        phoneCode: this.bankInfo.code,
        cashPassword: this.bankInfo.cashPassword,
        bankCity: "unknown",
        trueName: this.bankInfo.trueName,
        bankName: this.bankInfo.bankName,
        cardNo: this.bankInfo.cardNo,
        bankCode: this.bankList[this.bankActive].bankCode,  
        bankProvince: "unknown",  //开户省
        bankSub: this.bankInfo.bankCity ? this.bankInfo.bankCity : 'unknown',  //支行名称 => 开户地址
        bindType: 1, //1手机绑定 2 邮箱绑定
        uid: this.member.uid
      }
      userBank(param).then(res => {
        if (res.code === 0 || res.code === '0') {
          this.$toast(this.$t('my.addCard.bindSuccessTips'));
          this.$router.push('/home');
          this.$store.dispatch('refreshUserInfo');
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {});
    },
    // 获取用户详情
    getInfo() {
      getInfo().then(res => {
        if (res.code == 0) {
          let member = JSON.parse(JSON.stringify(res.data)), badgeShowList = [];
          if (member.badgeList && member.badgeList.length > 0 && this.configBadge.length > 0) {
            badgeShowList = utils.matchBadgeList(member.badgeList, this.configBadge);
          }
          member.badgeShowList = JSON.parse(JSON.stringify(badgeShowList));
          this.$store.commit("setState", { member });
          if(this.timerBank) clearInterval(this.timerBank);
          this.activeStep = 3;
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    goOther(path) {
      this.$router.push(path);
    },
    // 下一步
    nextStep() {
      if (this.active == 0) {
        let phonerReg = /^0\d{9}$/;
        if(phonerReg.test(this.registerForm.phone)) {
          this.isRegiste(0);
        } else {
          this.$toast(this.$t("register.checkPhoneError"));
        }
      } else {
        let emailReg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
        if(emailReg.test(this.registerForm.email)) {
          this.isRegiste(1)
        } else {
          this.$toast(this.$t("register.checkEmailError"));
        }
      }
    },
    // 手机号/ 邮箱 是否注册 type
    isRegiste() {
      // type 1 注册 2忘记密码
      let uid = localStorage.getItem('uid') || utils.guid(), fun = null,
      time = new Date().getTime();
      let param = {
        os: 0,
        timestamp: time,
        sign: md5(`${uid}jgyh,kasd${time}`),
        udid: uid,
      }

      if (this.active == 0) {
        param.mobile = this.registerForm.phone;
        fun = phoneIsRegiste;
      } else {
        param.email = this.registerForm.email;
        fun = emailIsRegiste;
      }
      fun(param).then(res => {
        if (res.code === 0 || res.code === '0') {
          if (res.data == 1) {
            let msg = this.active == 0 ? this.$t('register.hasPhoneRegister') : this.$t('register.hasEmailRegister');
            this.$toast(msg);
          } else {
            this.showDialog = true;
          }
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {})
    },
    // 网易验证码 重置
    reset() {
      this.captchaIns.refresh()
    },

    destroyCap () {
      this.captchaIns.destroy()
    },
    openModal() {
      if (this.bankList.length > 0) {
        this.showBankName = true;
      } else {
        let params = {
          uid: this.member.uid
        }
        userBankList(params).then(res => {
            if (res.code == 0) {
                this.showBankName = true;
                this.bankList = res.data;
                this.bankActive = -1;
            } else {
                if (res.msg) this.$toast(res.msg);
            }
        }).catch(err => {
            console.log(err);
        });
      }
      
    },
  },
}
</script>

<style lang="scss" scoped>
.register {
  background-color: #fff;
  padding-top: 0.88rem;

  &.next .title {

    .title_ellipsis {
      font-size: 0.28rem;
    }
  }

  .nextStep {
    margin: 40vh auto 0;
  }

  .registerTitleBox {
    margin: 1rem 0.28rem 0;  
  }
  
  .registerSuTitle {
    font-weight: bold;
    margin-bottom: 0.2rem;
    font-size: 0.32rem;
    text-align: left;
  }

  .registerNumDialog {
    padding: 0.36rem 0.2rem 0.44rem;
    background: -webkit-linear-gradient(left,#fa7c87,#fc9799);
    background: linear-gradient(90deg,#fa7c87,#fc9799);
    color: #fff;
    font-size: 0.28rem;

    p:nth-child(1) {
      font-size: 0.32rem;
      margin-bottom: 0.52rem;
    }
  }

  .getCodeBox {
    text-align: left;
    border-top: 1px solid #ebeff1;
    padding: 0.6rem 0.28rem 0;

    p {
      color: #333;
      font-size: 0.32rem;

      &:nth-child(1) {
        margin-bottom: 0.1rem;
      }

      &:nth-child(2) {
        margin-bottom: 0.2rem;
      }
    }
  }

  .codeBox {
    text-align: right;
    color: #fa7c87;
    margin-top: 0.2rem;
  }

  .infoConfirm {
    margin: 0.8rem auto 0;
  }

  .infoBox {
    text-align: left;
    border-top: 1px solid #ebeff1;
    padding: 0.6rem 0.28rem 0;

    .infoPhoneBoxS {
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      margin: 0 auto;

      .infoPhoneBox {
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 50%;
        background-color: #F9F9F9;
        margin: 0 auto;
        position: relative;

        // 样式待调整
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 50%;
        }
        
        .photograph {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }

    .sex {
      margin-top: 0.4rem;
      border-bottom: 1px solid #fa7c87;
      padding-bottom: 0.2rem;

      span {
        margin-right: 0.4rem;
      }

      .radiovan {
        display: inline-block;
      }
    }

    .sexLoginPsw {
      margin-top: 0.4rem;
    }

    .pswTip {
      font-size: 0.24rem;
      margin-top: 0.1rem;
    }
  }

  .bankListCont {
    margin-bottom: 0.3rem;

    .bankListCont_item {
        padding: 0.35rem 0;
        margin: 0 0.28rem;
        border-bottom: 1px solid #EBEFF1;

        .bankListCont_item_left {
            text-align: left;
            color: #333;

            img {
              width: 0.64rem;
              height: 0.64rem;
              margin-right: 0.1rem;
            }

            div {

                p:first-child {
                    font-size: 0.32rem;
                    font-weight: bold;
                }

                p:last-child {
                    font-size: 0.25rem;
                }
            }
        }
    }
  }

  .swiper {
      width: 100%;
      margin: 0.4rem 0;
      overflow: initial;
  }

  .swiper-cards .swiper-slide {
      overflow: initial;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(0,0,0,0);

    &.swiper-slide-active {
      transform: inherit scale(1.2);
      img {
          transform: scale(1.2);
      }
    }

    img {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      border: 2px solid #FFCEE6;
      object-fit: cover;
    }
  }

  .editAitem {
      font-size: 0.36rem;
      color: #333;
      padding: 0.24rem 0;
      margin: 0 0.28rem;
      display: block;

      &.editAitemB {
          padding: 0.34rem 0;
      }
  }

  .jianju {
      height: 0.2rem;
      width: 100%;
      background-color: #f9f9f9;
  }

  .borT {
      border-top: 1px solid #f2f2f2;
  }
}
</style>

<style lang="scss">
  .register {
    .van-tabs__wrap {
      height: 0.75rem !important;
      border-bottom: 1px solid #ebeff1;
    }

    .van-tabs__content {
      margin-top: 0.6rem;
      text-align: left;
      padding: 0 0.6rem;
    }

    .van-cell {
      border-bottom: 1px solid #ebeff1;
      padding-left: 0 !important;
    }

    .infoBox .van-cell {
      margin-top: 0.2rem;
      border-color: #fa7c87;
    }

    .bankBox .van-cell {
      margin-top: 0.08rem;
    }

    .van-radio__icon .van-icon {
      border-color: #fa7c87 !important;
    }

    .van-radio--horizontal {
      margin-right: 0.6rem;
    }

    .currencyModel {
      .van-action-sheet__header {
          font-size: 0.34rem;
          background-color: #F9F9F9;
          font-weight: bold;
      }

      .van-action-sheet__close {
          font-size: 0.34rem;
      }
    }

    .editAitem .van-uploader__wrapper {
      justify-content: center;
    }
  }
</style>