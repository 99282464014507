<template>
    <div class="next proxy">
        <div class="title">
            <span> {{$t("my.proxy.agentJoin")}} </span>
            <div class="fixed" @click="goOtherPage('/mine')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <van-swipe class="proxySwipe">
            <van-swipe-item>
                <div class="proxyCont">
                    <div class="advertiseTitle">{{$t("my.proxy.tagentJoin")}}</div>
                    <div class="advertiseSuTitle" v-html="$t('my.proxy.agentJoinTips1')"></div>

                    <div class="proxyBtnBox">
                        <div class="advertise" v-html="$t('my.proxy.agentJoinTips2')"></div>
                        <div class="flexBetween advisory">
                            <div class="flexCenter">
                                <img src="../../../assets/img/my/next/customer.png" />
                                <div>
                                    <p class="jiameng">{{$t("my.proxy.joinConsultation")}}</p>
                                    <p class="entry">{{$t("my.proxy.clickEnterOnline")}}</p>
                                </div>
                            </div>
                            <div @click="$router.push('/customerService')" class="proxyBtn">
                                {{$t("my.proxy.consult")}}
                            </div>
                        </div>
                        <div class="cbtn" @click="joins">{{$t("my.proxy.ApplicationsMembership")}}</div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="proxySuCont">
                    <div class="proxySuContTitle">{{$t("my.proxy.dataAnalysisDetail")}}</div>

                    <div class="dataReport">
                        <div v-for="(item, index) in list" :key="`${index}--proxy-list`" :class="`item icon${index+1}`">
                            <div class="itemFont">
                                <p class="itemFontTitle">{{item.name}}</p>
                                <p class="itemFontCont">{{item.cont}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </van-swipe-item>
        </van-swipe>
    </div>
</template>
  
<script>
import { getInfo } from "@/api/index";
import utils from "@/utils/index";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            list: [
                {
                    name: this.$t("my.proxy.footer.memberStatistics"),
                    cont: this.$t("my.proxy.footer.memberStatisticsExplain")
                },
                {
                    name: this.$t('my.proxy.commissionReport'),
                    cont: this.$t('my.proxy.footer.commissionReportExplain')
                },
                {
                    name: this.$t('my.proxy.footer.memberWinsLoses'),
                    cont: this.$t('my.proxy.footer.memberWinsLosesExplain')
                },
                {
                    name: this.$t('my.proxy.commissionRules'),
                    cont: this.$t('my.proxy.footer.commissionRulesExplain')
                },
            ],
            isGetData: false
        }
    },
    computed: {
        ...mapState(['member', 'configBadge'])
    },
    created() {
        this.$store.commit("setState", { suEntry: this.$route.path });
    },
    methods: {
        goOtherPage(path) {
            this.$router.push(path);
        },
        joins() {
            if (this.isGetData) return;
            this.isGetData = true;
            getInfo().then(res => {
                this.isGetData = false;
                if (res.code === 0 || res.code === '0') {
                    let info = JSON.parse(JSON.stringify(res.data)), badgeShowList = [];
                    info.goldCoin = info.goldCoin ? utils.keepTwoDecimalFull(info.goldCoin) : '0.00';
                    if (info.badgeList && info.badgeList.length > 0 && this.configBadge.length > 0) {
                        badgeShowList = utils.matchBadgeList(info.badgeList, this.configBadge);
                    }
                    info.badgeShowList = JSON.parse(JSON.stringify(badgeShowList));
                    this.$store.commit("setState", { member: info });
                    if (!info.phone) {
                        this.$dialog.confirm({
                            message: this.$t('my.proxy.noBindPhoneTips'),
                            className: "confirmDialog",
                            confirmButtonText: this.$t('common.confirm'),
                            cancelButtonText: this.$t('common.cancel'),
                            confirmButtonColor: "#fa7c87"
                        }).then(() => {
                            this.$router.push('/mine/bindPhone');
                        }).catch(() => {
                            console.log("取消");
                        });
                    } else if (String(info.proxyStatus) == 0) {
                        this.$dialog({
                            message: this.$t('my.proxy.submitApplicationTips'),
                            className: "confirmDialog",
                            confirmButtonText: this.$t('common.confirm'),
                            confirmButtonColor: "#fa7c87"
                        }).then(() => {
                            
                        })
                    } else {
                        this.$router.push('/mine/proxy/join');
                    }
                } else {
                    if (res.msg) Toast(res.msg);
                }
            }).catch(err => {
                this.isGetData = false;
            });
        }
    }
}
</script>
  
<style lang="scss" scoped>
.proxy {
    padding-top: 0.88rem;
    height: 100vh;

    .proxyCont {
        height: calc(100vh - 0.88rem);
        width: 100%;
        background-image: url('../../../assets/img/my/next/proxyBg.png');
        background-size: 100% 100%;
        position: relative;

        .advertiseTitle {
            width: 100%;
            text-align: center;
            font-size: 0.48rem;
            color: #fffae7;
            font-weight: 700;
            text-shadow: 0 0.5px #BDA481, 0.5px 0 #BDA481, -0.5px 0 #BDA481, 0 -0.5px #BDA481;
            padding-top: 0.2rem;
            margin-bottom: 0.2rem;
        }

        .advertiseSuTitle {
            width: 100%;
            text-align: center;
            font-size: 0.28rem;
            color: #fffae7;
        }

        .proxyBtnBox {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 0.28rem 0.7rem 0.28rem;

            .advertise {
                // width: 4.62rem;
                font-size: 0.32rem;
                color: #fffae7;
                margin: 0 auto 0.5rem;
                font-weight: 700;
                text-shadow: 0 0.5px #d47a07, 0.5px 0 #d47a07, -0.5px 0 #d47a07, 0 -0.5px #d47a07;
                line-height: 0.4rem;
            }

            .advisory {
                background-color: #fff;
                padding: 0.16rem 0.28rem;
                margin-bottom: 0.4rem;
                border-radius: 0.1rem;
                text-align: left;
                font-size: 0.24rem;

                img {
                    width: 1.2rem;
                    margin-right: 0.1rem;
                }

                .proxyBtn {
                    padding: 0.18rem 0.3rem;
                    border: 1px solid #fa7c87;
                    color: #fa7c87;
                    border-radius: 0.1rem;
                }

                .jiameng {
                    font-size: 0.28rem;
                    color: #333;
                    margin-bottom: 0.1rem
                }

                .entry {
                    color: #788597;
                }
            }
        }
    }

    .proxySuCont {
        height: calc(100vh - 0.88rem);
        width: 100%;
        background-image: url('../../../assets/img/my/next/proxyBg2.png');
        background-size: 100% 100%;
        position: relative;

        .proxySuContTitle {
            color: #fff;
            font-size: 0.48rem;
            width: 100%;
            text-align: center;
            padding: 0.5rem 0 0.4rem;
            font-weight: 700;
        }

        .dataReport {
            width: 100%;
            padding: 0 0.2rem;

            .item {
                width: calc(50% - 0.2rem);
                height: 4.28rem;
                position: relative;
                display: inline-block;
                margin-right: 0.2rem;
                margin-bottom: 0.1rem;

                &:nth-child(2n) {
                    margin-right: 0;
                }

                &.icon1 {
                    background-image: url('../../../assets/img/proxy/count.png');
                    background-size: 100% 100%;
                }

                &.icon2 {
                    background-image: url('../../../assets/img/proxy/report.png');
                    background-size: 100% 100%;
                }

                &.icon3 {
                    background-image: url('../../../assets/img/proxy/winLose.png');
                    background-size: 100% 100%;
                }

                &.icon4 {
                    background-image: url('../../../assets/img/proxy/rule.png');
                    background-size: 100% 100%;
                }

                .itemFont {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 0 0.24rem 0.64rem;
                    color: #333;

                    .itemFontTitle {
                        font-size: 0.32rem;
                        margin-bottom: 0.16rem;
                        text-align: center;
                    }

                    .itemFontCont {
                        font-size: 0.12rem;
                        text-align: justify;
                    }
                }
            }
        }
    }

    .cbtn {
        line-height: 0.88rem;
        height: 0.88rem;
        font-size: 0.32rem;
        color: #fa7c87;
        text-align: center;
        background-color: #fff;
        border-radius: 0.8rem;
        width: 100%;
    }
}
</style>
<style lang="scss">
.proxySwipe {
    .van-swipe__indicator {
        width: 0.16rem;
        height: 0.16rem;
        background-color: #000;
        border-radius: 0.1rem;
    }

    .van-swipe__indicator--active {
        background-color: #fa7c87;
    }
}
</style>