<template>
  <div class="home">
    <div class="topBox" :style="{ height: '0.88rem' }"></div>
    <div class="top" :style="{ top: topDom }">
      <div class="header flexBetweenTop">
        <img @click="goOtherPage('/customerService')" src="../../assets/img/customer.png" />
        <img @click="goOtherPage('/leaderboard')" src="../../assets/img/home/billboard.png" />
      </div>

      <div class="topTab" :style="{ top: topDom }">
        <van-tabs v-model:active="activeTab" background="transparent" color="#fff" title-active-color="#fff"
          @click-tab="changeTopTab" title-inactive-color="#fff">
          <van-tab v-for="(item, index) in topTab" :key="`${index}--topTab-home`" :title="item.tagNameShow"
            :name="item.code"></van-tab>
        </van-tabs>
      </div>
    </div>
    <van-pull-refresh v-model="isLoading" :disabled="isRefreshDisable" @refresh="onRefresh">
      <template #pulling>
        <van-loading />
      </template>

      <!-- 释放提示 -->
      <template #loosing>
        <van-loading />
      </template>

      <!-- 加载提示 -->
      <template #loading>
        <van-loading />
      </template>
      <!-- 游戏分块 -->
      <div v-if="activeTab == 'hall'" class="contBox gameBox" :style="{ height: liveHeight }">
        <div class="Mlayout">
          <!-- 轮播图 -->
          <div class="banner">
            <van-swipe height="2.88rem" :autoplay="3000">
              <van-swipe-item v-for="(item, index) in bannerList" :key="`${index}--bannerList`"
                @click="bannerItem(item, index)">
                <img v-real-img="item.content" />
              </van-swipe-item>
            </van-swipe>
          </div>

          <!-- 文字滚动 -->
          <div class="noticeBar">
            <div class="noticeBarIcon">
              {{ $t('home.announcement') }}
            </div>
            <van-notice-bar background="transparent" speed="35" color="#666">
              <span v-for="(item, index) in notice" :key="`${index}--notice-home`" class="noticeBarItem"
                @click="showCont(item)">{{ item.content }}</span>
            </van-notice-bar>
          </div>
        </div>


        <div v-for="(item, index) in gameList" :key="`${index}-home-gameList`" class="game"
          v-show="item.list && item.list.length">
          <div class="gameItem">
            <div class="gameTitle flexBetween">
              <div class="gameTitle_left flexCenter">
                <img v-real-img="item.img" />
                <span>{{ item.name }} </span>
              </div>
              <div @click="goMore(item)" class="gameTitle_right flexCenter">
                <span>{{ $t('common.more') }}</span>
                <img src="../../assets/img/record/go.png" />
              </div>
            </div>

            <div class="gameCont">
              <div v-if="item.list && item.list.length > 0 && (item.list.length) % 2 !== 0"
                @click="itemGo(item.list[0], item)" class="gameCont_itemAll_other gameCont_item">
                <img class="bg" v-real-img="item.list[0].icon" />
                <div class="name flexCenterBL">
                  {{ item.list[0].name }}
                </div>
              </div>
            </div>

            <div class="gameCont">
              <div v-for="(xitem, xindex) in item.list" :key="`${xindex}--item.list`" @click="itemGo(xitem, item)"
                v-show="((item.list.length) % 2 == 0 || ((item.list.length) % 2 !== 0 && xindex != 0))"
                class="gameCont_item"
                :class="{ 'marg': item.list && item.list.length > 0 && (item.list.length) % 2 !== 0 }">
                <div>
                  <img class="bg" v-real-img="xitem.icon" />
                  <div class="gameName">{{ xitem.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 直播 -->
      <div class="contBox liveBox" v-if="activeTab == 'live'" :style="{ height: liveHeight }">
        <div class="Mlayout">
          <!-- 轮播图 -->
          <div class="banner">
            <van-swipe height="2.88rem" :autoplay="3000">
              <van-swipe-item v-for="(item, index) in bannerList" :key="`${index}--bannerList`"
                @click="bannerItemLive(item, index)">
                <img v-real-img="item.content" />
              </van-swipe-item>
            </van-swipe>
          </div>

          <!-- 文字滚动 -->
          <div class="noticeBar">
            <div class="noticeBarIcon">
              {{ $t('home.announcement') }}
            </div>
            <van-notice-bar background="transparent" speed="35" color="#666">
              <span v-for="(item, index) in notice" :key="`${index}--notice-home`" class="noticeBarItem"
                @click="showCont(item)">{{ item.content }}</span>
            </van-notice-bar>
          </div>
        </div>

        <div v-if="recommendLiveLists.length > 0" class="flexBetween liveHeadBox">
          <div>{{ $t('home.recommendGames') }}</div>
          <div @click="$router.push('/game')" class="flexCenter">
            <p>{{ $t('common.more') }}</p>
            <img src="../../assets/img/my/right.png" />
          </div>
        </div>

        <div v-if="recommendLiveLists.length > 0" class="gameTopBox">
          <div class="gameTop flex">
            <div v-for="(item, index) in recommendLiveLists" :key="`${index}-recommendLiveLists-home`"
              @click="goGame(item)" class="gameItem">
              <img v-real-img="item.icon" />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>

        <div v-if="rocketLists.length > 0" class="flexBetween rocket">
          <div class="flexCenter rocketTitle">
            <img src="../../assets/img/home/rocketBG.png" />
            {{ $t('home.anchorRocket') }}
          </div>
          <div class="flexCenter rocketTice">
            <van-notice-bar background="transparent" speed="35" color="#969696">
              <span v-for="(item, index) in rocketNotice" :key="`${index}--rocketNoticeItem-home`"
                class="rocketNoticeItem">{{ item }}</span>
            </van-notice-bar>
          </div>
        </div>

        <div v-if="rocketLists.length > 0" class="gameTopBox">
          <div class="gameTop flex">
            <div v-for="(item, index) in rocketLists" :key="`${index}-rocketLists-home`" @click="goLive(item)"
              class="gameItem">
              <img v-real-img="item.avatar" />
              <p>{{ item.nickname }}</p>
            </div>
          </div>
        </div>

        <div class="flexBetween liveHeadBox">
          <div>{{ $t('footer.live') }}</div>
          <div @click="showSearch = true" class="searchBox">
            <img src="../../assets/img/search.png" />
          </div>
        </div>

        <!-- <div class="barTitle">
          <div class="flexCenter">
            <div v-for="(item, index) in bar" :key="`${index}--bar`" class="item" @click="changeTab(item, index)"
              :class="{ 'active': activeBar == item.type }">
              {{ item.name }}
            </div>
          </div>
        </div> -->

        <van-tabs class="barTab" v-model:active="showActiveBar" :swipe-threshold="2" :line-height="30" @click-tab="changeTab">
          <van-tab v-for="(item, index) in bar" :key="`${index}--bar`"  :title="`${item.name}`" >
          </van-tab>
        </van-tabs>

        <div class="live">
          <div v-show="tagLists.length > 0" class="gameItem">
            <div v-for="(xitem, xindex) in tagLists" :key="`${xindex}--tagLists`" @click="goLive(xitem)"
              class="gameCont_item live_item" :class="{ 'bigLiveItem': xitem.type == -1 }">
              <!-- flexBetween -->
              <div v-if="xitem.type !== -1" class="liveHead">
                <!-- <div v-if="xitem.liveStartLottery && xitem.liveStartLottery.length > 0" class="liveHeadCont">
                <p v-for="(sitem, sindex) in xitem.liveStartLottery" :key="`${sindex}-home-xitem.liveStartLottery`">
                  {{ sitem.lotteryName }}
                </p>
              </div> -->
                <img v-show="xitem.webStatus" class="liveLock" src="../../assets/img/home/app.png" />
                <img v-show="!xitem.webStatus && xitem.type == 3" class="liveLock"
                  src="../../assets/img/home/liveLock.png" />
                <img v-show="!xitem.webStatus && (xitem.type == 1 || xitem.type == 2)" class="liveLock"
                  src="../../assets/img/home/money.png" />
                  <img v-show="!xitem.webStatus && (xitem.pking || xitem.pkStatus)" class="liveLock" src="../../assets/img/home/pk.png" />
                <img v-show="!xitem.webStatus && xitem.voiceBase.isVoice" class="liveLock"
                  src="../../assets/img/home/lm.png" />
              </div>
              <img v-if="xitem.type !== -1" class="liveBg" v-real-img="xitem.avatar"
                :errorimg="require(`../../assets/img/errorImg/2.png`)" />
              <img v-if="xitem.type == -1" class="liveBg" v-real-img="xitem.content"
                :errorimg="require(`../../assets/img/errorImg/4.png`)" />
              <div v-if="xitem.type !== -1" class="liveBottom flexBetween">
                <div>{{ xitem.nickname }}</div>
                <div>
                  <img src="../../assets/img/home/liveUser.png" />
                  {{ xitem.rq }}
                </div>
              </div>
            </div>

            <div v-show="tagLists.length > 2" class="goTop flexCenterBL" @click="goTop">
              {{ $t('home.goTop') }}
              <img src="../../assets/img/home/goTop.png" />
            </div>
          </div>

          <div v-show="tagLists.length == 0" class="noData">
            <img src="../../assets/img/record/noData.png" />
            <p>{{ $t('common.noData') }}</p>
          </div>
        </div>
      </div>

      <!-- 活动 -->
      <!-- distributeType;  1 自动派发  2 客服申请 -->
      <div class="contBox content" id="tabHomeActivity" v-if="activeTab == 'activity'" :style="{ height: liveHeight }">
        <van-list class="vanList" v-model="isLoadActivity" :finished="finished"
          style="overflow-y:scroll;padding-bottom: 10px;" :style="{ height: liveHeight }" :offset="10"
          :immediate-check="check" :finish-text="`${$t('lottery.noMore')}`">
          <div class="recordList">
            <div class="recordListBox" v-if="list.length > 0">
              <div v-for="(item, index) in list" :key="`${item}--bar`" class="item" @click="itemClick(item, index)">
                <div v-show="item.distributeType == 1" class="topiconbox">{{ $t('discount.automatic') }}</div>
                <div v-show="item.distributeType == 2" class="topiconbox">{{ $t('discount.Application') }}</div>
                <van-image class="imgs" :src="item.activityHomePage">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>

              <div class="showAll" v-show="finished"> {{ $t('lottery.showAll') }} </div>

            </div>
            <div v-else class="noData">
              <img src="../../assets/img/record/noData.png" />
              <p>{{ $t('common.noData') }}</p>
            </div>
          </div>
        </van-list>
      </div>


    </van-pull-refresh>
    <!-- 公告弹框 -->
    <van-dialog :className="'noticeDialog'" width="100vw" v-model:show="showDialog" :closeOnClickOverlay="true"
      :showConfirmButton="false" @close="closeNoticeDialog">
      <div class="dialogCont">
        <div class="noticeContHead">{{ $t('home.announcement') }}</div>
        <div class="noticeContBox flexBetweenTop">
          <div class="noticeContBoxLeft">
            <div @click="activeAnnouncement = index" class="noticeContBoxLeftItem" v-for="(item, index) in announcement"
              :key="`${index}--announcement`" :class="{ 'active': activeAnnouncement == index }">
              <div class="contTitle">{{ item.title }}</div>
            </div>
          </div>
          <div class="noticeContBoxRight">
            <div v-for="(item, index) in announcement" :key="`${index}--announcement`"
              v-show="activeAnnouncement == index">
              <div class="contFot" v-html="item.content"></div>
            </div>
          </div>
        </div>
        <div class="noticeDialogColse"><van-icon @click="closeNoticeDialog" class="closeIcon" color="#666"
            name="close" />
        </div>
      </div>
    </van-dialog>

    <!-- 首存弹框 -->
    <van-dialog :className="'noticeDialog'" width="100vw" v-model:show="showAdvertise" :closeOnClickOverlay="true"
      :showConfirmButton="false">
      <div class="dialogRechange" v-if="rechangeAdvertise.content">
        <img @click="goDetail" v-real-img="rechangeAdvertise.content"
          :errorimg="require(`../../assets/img/errorImg/2.png`)">

        <div class="operateBox flexCenterAroundBL">
          <div @click="$router.push('/customerService')">{{ $t('home.nowApply') }}</div>
          <div @click="$router.push('/mine/finance/myDeposit')">{{ $t('home.nowRechange') }}</div>
        </div>
      </div>
    </van-dialog>

    <otherWeb></otherWeb>

    <!-- 搜索弹框 -->
    <searchComponents :showSearch="showSearch" @colseSearch="colseSearch"></searchComponents>
  </div>
</template>

<script>
import { getGameLists, getGames, startGame, rocketlist, tagList, gameConfig } from "@/api/index";
import { advert, configTag, baseUserActivity, noticeNew } from "@/api/config";
import { mapGetters, mapState } from 'vuex';
import md5 from 'js-md5';
import searchComponents from "@/components/search.vue";
import otherWeb from "@/pages/components/otherWeb.vue";
import interLive from '@/pages/mixin/interRoom';
import utils from '@/utils/index';
export default {
  name: "homeIndex",
  mixins: [interLive],
  components: {
    otherWeb,
    searchComponents
  },
  data() {
    return {
      bannerList: [],
      notice: [],
      gameList: [],
      showDialog: false,
      activeTab: '',
      bar: [],
      // activeBar: 1,
      rocketLists: [], // 火箭榜
      rocketNotice: [], //火箭榜上方的文字滚动
      recommendLiveLists: [], //推荐榜
      tagLists: [],

      // 活动
      list: [],
      // 滚动与下拉冲突
      scrollTop: 0,
      isRefreshDisable: false,
      finished: true, //是否已加载完
      loading: false, //下拉加载更多加载中动画
      check: false, //是否开始就检查
      isLoading: false,

      announcement: [], // 公告列表
      activeAnnouncement: 0,
      activeName: "",
      showSearch: false, //搜索弹框是否展示
      showAdvertise: false, // 首存弹框
      rechangeAdvertise: {}, //首存弹框内容
      firstRecharge: 0, // 是否可以弹框首存
      fromPath: "",
      height: 667,
      isLoadActivity: false, //活动数据请求中
      isLoadAdvert: false, // banner 滚动文字数据请求中
      isLoadGameList: false, // 大厅游戏数据请求中
      isLoadRocketlist: false,
      isGameConfig: false,
      isLoadConfigTag: false,
      domTimer: null,
      showActiveBar: 1
    }
  },
  computed: {
    ...mapState(['member', 'lotteryRouter', 'systemConfig', 'adverts', 'scrollHeight', 'showDownApp', 'activeBar']),
    ...mapGetters(['isLogin']),
    topTab() {
      let arr = [], supportTab = ['live', 'hall', 'activity'], last = [];
      if (this.systemConfig.length > 0) {
        let index = this.systemConfig.findIndex(item => {
          return item.code == 'home';
        });

        if (index != -1 && this.systemConfig[index].systemConfigTagList && this.systemConfig[index].systemConfigTagList.length > 0) {
          arr = this.systemConfig[index].systemConfigTagList;
        }
      }

      if (arr.length > 0) {
        arr.forEach(item => {
          let index = supportTab.findIndex(xitem => {
            return item.code == xitem;
          });

          if (index != -1) {
            last.push(item);
          }
        });
      }

      if (this.fromPath.indexOf('/home/bannerDetail/discount') != -1) {
        this.activeTab = 'activity';
        this.baseUserActivity()
      }

      if (this.fromPath.indexOf('/home/bannerDetail/home') != -1 || this.fromPath.indexOf('/lottery/') != -1) {
        this.activeTab = 'hall';
      }

      if (this.fromPath.indexOf('/home/bannerDetail/live') != -1) {
        this.activeTab = 'live';
      }

      last = last.sort(function (a, b) {
        return (a.tagSort - b.tagSort);
      });

      if (last.length > 0 && last[0].code == 'activity') {
        this.baseUserActivity()
      }

      return last;
    },
    isManage() {
      let flag = false;
      if (this.member && this.member.badgeList && this.member.badgeList.length > 0) {
        let index = this.member.badgeList.findIndex(item => {
          return item == '4'
        });

        if (index != -1) {
          flag = true;
        }
      }
      return flag;
    },
    isLoad() {
      let flag = false;
      if (this.activeTab == 'activity' && this.isLoadActivity) {
        flag = true;
      }

      if (this.activeTab == 'hall' && this.isLoadAdvert && this.isLoadGameList) {
        flag = true;
      }

      if (this.activeTab == 'live' && this.isLoadAdvert && this.isLoadRocketlist && this.isGameConfig && this.isLoadConfigTag) {
        flag = true;
      }
      return flag;
    },
    topDom() {
      let top = '0px';
      if (this.showDownApp) {
        top = '1rem'
      }
      return top;
    },
    liveHeight() {
      let height = `${this.height}px`;
      if (this.showDownApp) {
        height = `calc(${this.height}px - 1rem)`
      }
      return height;
    },
    hasObject() {
      return (val) => {
        if (this.systemConfig.length > 0) {
          let arr = [];
          let index = this.systemConfig.findIndex(item => {
            return item.code == 'home';
          });

          if (index != -1 && this.systemConfig[index].systemConfigTagList && this.systemConfig[index].systemConfigTagList.length > 0) {
            arr = this.systemConfig[index].systemConfigTagList;
          }

          return arr.some(item => item.code === val);
        } else {
          if (val == 'home') {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  },
  watch: {
    // , from
    $route(to) {
      if (to.path === '/home') {
        this.$store.commit("setState", { entry: "/home", gameType: "" });
        if (!sessionStorage.getItem("showAdvertise")) {
          this.noticeNew();
        }
      }
    },
    scrollTop(newval) {
      if (newval > 0) {
        this.isRefreshDisable = true
      } else {
        this.isRefreshDisable = false
      }
    },
    isLoad(val) {
      this.isLoading = val;
    }
  },
  created() {
    this.$store.commit("setState", { entry: "/home", gameType: "" });
    this.getAdvert();
    this.getGameLists();
    this.getRocketList();
    this.getRecommendLiveLists();
    this.configTag();

    if (!sessionStorage.getItem("showAdvertise")) {
      this.noticeNew();
    }
  },
  mounted() {
    this.safariHacks();

    clearTimeout(this.domTimer);
    this.domTimer = setTimeout(() => {
      const vantList = document.querySelector('.contBox')
      if (vantList) {
        this.resolveConflict(vantList)
      }
    }, 100);
  },
  methods: {
    // 回到顶部
    goTop() {
      let liveBox = document.getElementsByClassName('liveBox')[0];
      liveBox.scrollTop = 0;
    },
    // 界面下方高度
    safariHacks() {
      let windowsVH = this.windowsVH = window.innerHeight,
        topH = document.getElementsByClassName('topBox')[0].offsetHeight,
        footerH = document.getElementsByClassName('footer')[0].offsetHeight;
      if (!footerH) {
        footerH = 54;
      }
      this.height = `${windowsVH - footerH - topH}`;
    },
    onRefresh() {
      if (this.activeTab == 'activity') {
        this.baseUserActivity();
      }

      if (this.activeTab == 'hall') {
        this.getAdvert();
        this.getGameLists();
      }

      if (this.activeTab == 'live') {
        this.getAdvert();
        this.getRocketList();
        this.getRecommendLiveLists();
        this.configTag();
      }
    },
    /**
     * @description: 下拉刷新和滚动事件冲突
     * @param {*}
     * @return {*}
     */
    resolveConflict(val) {
      const vantList = val
      vantList.addEventListener('touchmove', () => {
        this.scrollTop = vantList.scrollTop
      })
      vantList.addEventListener('touchend', () => {
        this.scrollTop = vantList.scrollTop
      })
    },
    // 关闭搜索弹框
    colseSearch() {
      this.showSearch = false;
    },
    //关闭弹框公告事件
    closeNoticeDialog() {
      this.showDialog = false;
      if (this.firstRecharge && this.member && this.member.uid && this.rechangeAdvertise.content) {
        this.showAdvertise = true;
      }
    },
    // 弹框公告
    noticeNew() {
      let uid = this.member && this.member.uid ? this.member.uid : '';
      noticeNew(uid).then(res => {
        if (res.code === 0 || res.code === '0') {
          if (res.data.configAppNoticeList && res.data.configAppNoticeList.length > 0) {
            this.announcement = res.data.configAppNoticeList;
            this.showDialog = true;
            this.firstRecharge = res.data.firstRecharge;
            sessionStorage.setItem("showAdvertise", "false");
          }
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => { })
    },
    // 点击直播间
    goLive(item) {
      if (!this.member) return this.$router.push('/login');
      if (item.isAd && this.CheckUrl(item.adJumpUrl)) {
        //  window.location.href = item.adJumpUrl;
        this.$store.commit("setState", { otherWebUrl: item.adJumpUrl, isShowOtherWeb: true });
      }
      if (!item.anchorId) return;
      let liveList = this.tagLists.filter(item => {
        return item.liveId && !item.isAd
      });

      this.$store.commit("setState", { liveList });
      this.getAnchorBase(item);
    },
    // 校验是否是有效URL
    CheckUrl(url) {
      let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      return !!reg.test(url);
    },
    // 顶部tab切换
    changeTopTab(item) {
      this.activeTab = item.name;

      this.isLoadActivity = false; //活动数据请求中
      this.isLoadAdvert = false; // banner 滚动文字数据请求中
      this.isLoadGameList = false; // 大厅游戏数据请求中
      this.isLoadRocketlist = false;
      this.isGameConfig = false;
      this.isLoadConfigTag = false;

      if (item.name == 'activity' && this.list.length == 0) {
        this.baseUserActivity()
      }

      clearTimeout(this.domTimer);
      this.domTimer = setTimeout(() => {
        let name = item.name == 'activity' ? 'vanList' : 'contBox'
        const vantList = document.querySelector(`.${name}`)
        if (vantList) {
          this.resolveConflict(vantList)
        }
      }, 100);
    },
    // 推荐榜
    getRecommendLiveLists() {
      let str = this.member && this.member.uid ? this.member.uid : '';
      this.isGameConfig = true;
      gameConfig(str).then(res => {
        this.isGameConfig = false;
        if (res.code === 0 || res.code === '0') {
          this.recommendLiveLists = res.data.list;
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        this.isGameConfig = false;
        console.log(err);
      });
    },
    // 火箭榜
    getRocketList() {
      let params = {};
      if (this.member && this.member.uid) {
        params.uid = this.member.uid
      }
      this.isLoadRocketlist = true;
      rocketlist(params).then(res => {
        this.isLoadRocketlist = false;
        if (res.code === 0 || res.code === '0') {
          this.rocketLists = res.data;
          let rocketNotice = [];
          res.data.forEach(item => {
            rocketNotice.push(`Chúc mừng idol ${item.nickname}  đã nhận được đề xuất`)
          });
          this.rocketNotice = JSON.parse(JSON.stringify(rocketNotice));
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        this.isLoadRocketlist = false;
        console.log(err);
      });
    },
    // 主播标签
    configTag() {
      let str = this.member && this.member.uid ? this.member.uid : "";
      this.isLoadConfigTag = true;
      configTag(str).then(res => {
        if (res.code === 0 || res.code === '0') {
          this.bar = res.data;
          if (res.data.length > 0) {
            // this.tagList(1);
            let index = res.data.findIndex(item => {
              return item.type == this.activeBar;
            });

            if (index != -1) {
              this.showActiveBar = index;
              this.tagList(this.activeBar);
            }
          }
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 开播列表
    tagList(type) {
      let params = {
        uid: this.member && this.member.uid ? this.member.uid : "",
        type
      }
      this.tagLists = [];
      tagList(params).then(res => {
        this.isLoadConfigTag = false;
        if (res.code === 0 || res.code === '0') {
          this.tagLists = this.getTagList(res.data);

          let name = this.activeTab == 'activity' ? 'vanList' : 'contBox'
          const vantList = document.querySelector(`.${name}`);
          if (vantList) {
            this.resolveConflict(vantList)
          }
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        this.isLoadConfigTag = false;
        console.log(err);
      });
    },
    // 处理开播数据
    getTagList(arr) {
      let result = JSON.parse(JSON.stringify(arr)), res = [];
      if (this.adverts.length > 0 && arr.length > 6) {
        let advertsIndex = this.adverts.findIndex(item => {
          return item.type == 2
        });

        if (advertsIndex != -1) {
          for (let i = 0; i < arr.length; i = i + 6) {
            if (i + 6 < arr.length) {
              res.push(arr.slice(i, i + 6));
            } else {
              res.push(arr.slice(i));
            }
          }
          res.forEach((item) => {
            return item.length === 6 ? item.push({
              ...this.adverts[advertsIndex],
              type: -1
            }) : item;
          })
          result = [].concat.apply([], res);
        }
      }
      return result;
    },
    // 顶部tab
    changeTab(item) {
      let type = this.bar[item.name].type;
      this.$store.commit("setState", { activeBar: type });
      this.tagList(type)
    },
    //轮播图点击跳转
    bannerItem(item, index) {
      // openWay 0 站内 1 站外
      if (item.jumpUrl) {
        if (!item.openWay) {
          this.$store.commit("setState", { bannerDetail: JSON.parse(JSON.stringify(item)) });
          this.$router.push(`/home/bannerDetail/home${index}`);
        } else {
          // window.open(item.jumpUrl);
          // window.location.href = item.jumpUrl;
          this.$store.commit("setState", { otherWebUrl: item.jumpUrl, isShowOtherWeb: true });
        }
      }
    },
    //直播轮播图点击跳转
    bannerItemLive(item, index) {
      // openWay 0 站内 1 站外
      if (item.jumpUrl) {
        if (!item.openWay) {
          this.$store.commit("setState", { bannerDetail: JSON.parse(JSON.stringify(item)) });
          this.$router.push(`/home/bannerDetail/live${index}`);
        } else {
          // window.open(item.jumpUrl);
          // window.location.href = item.jumpUrl;
          this.$store.commit("setState", { isShowOtherWeb: true, otherWebUrl: item.jumpUrl });
        }
      }
    },
    // 下方右侧点击
    itemGo(xitem, item) {
      if (item.categoryId === 20000) {
        this.goLottery(xitem);
      } else {
        this.goGame(xitem);
      }
    },
    // 三方游戏跳转
    goGame(item) {
      if (sessionStorage.getItem('token')) {
        let uid = localStorage.getItem('uid') || utils.guid(),
          params = {
            os: 0,
            sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
            timestamp: new Date().getTime(),
            udid: localStorage.getItem('uid') || utils.guid(),
            gameId: item.gameId,
            gamePlatform: item.name,
            uid: this.member.uid,
            id: item.id
          }
        startGame(params).then(res => {
          if (res.code === 0 || res.code === '0') {
            // window.open(res.data.forwardUrl);
            // if (res.data.gamePlatform === 'horizontal') {
            //   this.$store.commit("setState", { isShowDownLoadApp: true });
            // } else {
            //   window.location.href = res.data.forwardUrl;
            // }
            // this.$store.commit("setState", { otherWebUrl: res.data.forwardUrl, isShowOtherWeb: true });
            if (res.data.gamePlatform === 'horizontal') {
                this.$store.commit("setState", { isShowDownLoadApp: true });
            } else if(res.data.gamePlatform === 'html') {
              const userAgent = navigator.userAgent.toLowerCase();
              if (userAgent.includes('safari')) {
                this.$dialog.confirm({
                  message: `${this.$t('gameCenter.GoToGame')}`,
                  showCancelButton: true,
                  confirmButtonText: `${this.$t('common.confirm')}`,
                  cancelButtonText:  `${this.$t('common.cancel')}`,
                  closeOnClickOverlay: true,
                  className: "confirmDialog",
                  confirmButtonColor: "#eb457e"
                })
                .then(() => {
                  let newPage = window.open('', '_blank');
                  newPage.document.open();
                  newPage.document.write(res.data.forwardUrl);
                  newPage.document.close();
                })
                .catch(() => {
                    // on cancel
                });
              } else {
                let newPage = window.open('', '_blank');
                newPage.document.open();
                newPage.document.write(res.data.forwardUrl);
                newPage.document.close(); 
              }
            } else {
              if (res.data.forwardUrl) {
                window.location.href = res.data.forwardUrl;
              } else {
                this.$toast(this.$t('gameCenter.gameAbnormality'))
              }
            }
          } else {
            if (res.msg) this.$toast(res.msg);
          }
        }).catch(() => {
          console.log('catch');
        });
      } else {
        this.$store.commit("setState", { member: null });
        this.$toast(this.$t('entry.loginInvalidTips'));
        this.$router.push("/login");
      }
    },
    // 彩票子游戏跳转
    goLottery(item) {
      if (item.link) {
        this.$router.push(item.link);
      } else {
        this.$toast('游戏开发中');
      }
    },
    // 更多跳转
    goMore(item) {
      this.$store.commit("setState", { gameType: item.remark });
      this.$router.push('/game');
    },
    // 获取三方游戏标签
    getGameLists() {
      this.isLoadGameList = true;
      getGameLists().then(res => {
        // // categoryId 10000 三方游戏  20000 彩票  30000 其他热门
        this.isLoadGameList = false;
        if (res.code === 0 || res.code === '0') {
          let arr = JSON.parse(JSON.stringify(res.data));
          this.gameList = arr.sort(function (a, b) {
            return (a.sort - b.sort);
          });

          this.gameList.forEach(item => {
            this.getGames(item.id);
          });
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {
        this.isLoadGameList = false;
        console.log('catch');
      });
    },
    // 获取首页三方子游戏
    getGames(id) {
      getGames(id).then(res => {
        if (res.code === 0 || res.code === '0') {
          let arr = JSON.parse(JSON.stringify(res.data));
          let index = this.gameList.findIndex(item => {
            return item.id == id;
          });

          if (index != -1) {
            if (this.gameList[index].categoryId == 20000) {
              arr.forEach(item => {
                let xindex = this.lotteryRouter.findIndex(xitem => {
                  return xitem == item.gameId
                });

                if (xindex != -1) {
                  item.link = `/lottery/${item.gameId}`;
                }
              });
            }

            this.gameList[index].list = arr;
          }
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {
        console.log('catch');
      });
    },
    // 轮播图与文字滚动数据获取
    getAdvert() {
      //   /** 横幅广告 */
      // HKAdvertType_Banner = 1,
      // /** 次级横幅广告 */
      // HKAdvertType_ListBanner = 2,
      // /** 首页飘屏文字广告 */
      // HKAdvertType_HotText = 3,
      // /** 进房文字广告 */
      // HKAdvertType_LiveText = 4,
      // /** 飘屏广告 */
      // HKAdvertType_Flutter = 5,
      // /** 房间活动 */
      // HKAdvertType_LiveActive = 6,

      // HKAdvertType_LiveImg = 7,
      // /** 游戏banner */
      // HKAdvertType_Game = 8,
      // /** 充值通告*/
      // HKAdvertType_topUp = 9,
      // /** 首存*/
      // HKAdvertType_firstDeposit = 10,
      this.isLoadAdvert = true;
      advert().then(res => {
        this.isLoadAdvert = false;
        if (res.code === 0 || res.code === '0') {
          let arr = [], notice = [], rechangeAdvertise = {};
          res.data.forEach((item) => {
            if (item.type == 1) {
              arr.push(item);
            }
            if (item.type == 3) {
              notice.push(item);
            }

            if (item.type == 10) {
              rechangeAdvertise = item;
            }
          });
          this.bannerList = JSON.parse(JSON.stringify(arr));
          this.notice = JSON.parse(JSON.stringify(notice));
          this.rechangeAdvertise = JSON.parse(JSON.stringify(rechangeAdvertise));
          this.$store.commit("setState", { adverts: res.data });
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {
        this.isLoadAdvert = false;
        console.log('catch');
      });
    },
    // 路由跳转
    goOtherPage(path) {
      if (!this.isLogin && path != '/customerService') {
        this.$router.push("/login");
      } else {
        this.$router.push(path);
      }
    },
    // 文字滚动点击事件
    showCont(item) {
      this.$dialog({
        // title: this.$t('common.hint'),
        message: item.content,
        className: "confirmDialog",
        confirmButtonText: this.$t('common.cancel'),
        confirmButtonColor: "#fa7c87"
      }).then(() => {
        // this.$router.push('/mine/mail');//地址待确认是否需要带参
      })
    },

    // 列表
    baseUserActivity() {
      this.isLoadActivity = true;
      baseUserActivity().then(res => {
        this.isLoadActivity = false;
        if (res.code === 0 || res.code === '0') {
          // distributeType;  1 自动派发  2 客服申请
          this.list = res.data;
          // 解决滚动条与下拉的冲突
          setTimeout(() => {
            const vantList = document.querySelector(`.vanList`)
            if (vantList) {
              this.resolveConflict(vantList)
            }
          }, 100)
        } else {
          this.list = [];
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
        this.list = [];
        this.isLoadActivity = false;
      });
    },
    //图点击跳转
    itemClick(item, index) {
      if (item.activityDetail) {
        let obj = JSON.parse(JSON.stringify(item));
        obj.jumpUrl = item.activityDetail;
        this.$store.commit("setState", { bannerDetail: JSON.parse(JSON.stringify(obj)) });
        this.$router.push(`/home/bannerDetail/discount${index}`);
      }
    },
    goDetail() {
      let obj = JSON.parse(JSON.stringify(this.rechangeAdvertise));
      this.$store.commit("setState", { bannerDetail: JSON.parse(JSON.stringify(obj)) });
      this.$router.push(`/home/bannerDetail/home55`);
    }
  },
  beforeRouteEnter(to, from, next) {
    next((e) => {
      e.fromPath = from.path;
      let className = e.activeTab == 'activity' ? 'vanList' : 'contBox';
      if (e.scrollHeight && e.scrollHeight.home && document.getElementsByClassName(className)[0]) {
        document.getElementsByClassName(className)[0].scrollTop = e.scrollHeight.home;
        setTimeout(() => {
          let name = e.activeTab == 'activity' ? 'vanList' : 'contBox'
          const vantList = document.querySelector(`.${name}`);
          if (vantList) {
            this.resolveConflict(vantList)
          }
        }, 100)
      }
    });
  },
  unmounted() {
    clearTimeout(this.domTimer);
  },
  beforeRouteLeave(to, from, next) {
    let scrollHeight = JSON.parse(JSON.stringify(this.scrollHeight));
    let className = this.activeTab == 'activity' ? 'vanList' : 'contBox';
    scrollHeight.home = document.getElementsByClassName(className)[0].scrollTop;
    this.$store.commit("setState", { scrollHeight });
    next();
  },
}
</script>
<style lang="scss" scoped>
.home {
  overflow: hidden;
  background-color: #f9f9f9;
  padding: 0.88rem 0 0 0;
  font-size: 0.28rem;
  position: relative;

  .topBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background: -webkit-linear-gradient(left, #ff97a0, #ff97a0);
    background: linear-gradient(90deg, #ff97a0, #ff97a0);
    width: 100%;

  }

  .top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 8;

    .header {
      height: 0.88rem;
      padding: 0.28rem 0.28rem 0;
      background: -webkit-linear-gradient(left, #ff97a0, #ff97a0);
      background: linear-gradient(90deg, #ff97a0, #ff97a0);
      color: #fff;
      font-size: 0.32rem;

      img {
        width: 0.42rem;
        height: 0.44rem;
      }

      div {
        border-bottom: 2px solid transparent;
        height: 0.52rem;

        &.active {
          border-color: #fff;
        }
      }
    }

    .topTab {
      position: fixed;
      top: 0.1rem;
      left: 0.78rem;
      width: calc(100% - 1.6rem);
      z-index: 6;
      background-color: transparent;
    }
  }

  .banner {
    height: 2.88rem;
    overflow: hidden;
    position: relative;
    width: calc(100vw - 0.56rem);
    margin: 0.2rem auto 0;

    img {
      width: 100%;
      height: 2.88rem;
      border-radius: 0.2rem;
    }
  }

  .contBox {
    position: relative;
    z-index: 4;
    background-color: #fff;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .game {
    background-color: #fff;
    border-radius: 0.2rem;
    font-size: 0.28rem;
    color: #626872;
    margin-bottom: 0.2rem;
    display: table;
    width: 100%;

    .gameItem {
      padding: 0.3rem 0.28rem 0.2rem;
    }

    .gameTitle {
      .gameTitle_left {
        img {
          width: 0.64rem;
          margin-right: 0.1rem;
        }
      }

      .gameTitle_right {
        img {
          width: 0.28rem;
          margin-left: 0.1rem;
        }
      }
    }

    .gameCont {
      .gameCont_item {
        height: 2.6rem;
        position: relative;
        width: calc(50% - 0.1rem);
        display: inline-block;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
        // box-shadow: 0px 1px 2px 1px rgba(250, 143, 175, 0.2);
        border-radius: 0.2rem;
        vertical-align: middle;

        &:nth-child(2n) {
          margin-right: 0;
        }

        &.marg {
          margin-right: 0rem;

          &:nth-child(2n) {
            margin-right: 0.2rem;
          }
        }

        .bg {
          // position: absolute;
          // top: 0;
          // left: 0;
          width: 100%;
          height: 2rem;
          border-radius: 0.1rem 0.1rem 0 0;
        }

        &.gameCont_item_top {
          height: 2.4rem;

          .bg {
            height: 2.4rem;
          }
        }

        .team {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 2.4rem;
          z-index: 10;
          color: #FFFFFF;

          .vs {
            width: 0.7rem;
            margin: 0 0.2rem;
          }
        }

        &.gameCont_itemAll {
          width: 100%;
          font-size: 0.32rem;
          height: 3.2rem;

          .bg {
            height: 3.2rem;
          }

          .team {
            height: 3.2rem;

            .vs {
              width: 1.2rem;
            }
          }

          .name {
            text-align: center;
            width: 100%;
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
          }
        }

        &.gameCont_itemAll_other {
          width: 100%;
          font-size: 0.32rem;
          height: 3.2rem;

          .bg {
            width: 100%;
            height: 2.4rem;
          }
        }

        .gameName {
          color: #626872;
          font-size: 0.24rem;
          text-align: center;
          margin-top: 0.05rem;
        }
      }
    }
  }

  .liveHeadBox {
    background: #fff;
    font-size: 0.28rem;
    padding: 0.2rem 0.28rem 0;

    div:nth-child(1) {
      margin-right: 0.4rem;
      padding-bottom: 0.05rem;
      border-bottom: 2px solid #f872a0;
    }

    .searchBox {
      width: 5.4rem;
      height: 0.56rem;
      line-height: 0.56rem;
      text-align: right;
      padding-right: 0.2rem;
      padding-top: 0.05rem;
      border-radius: 0.2rem;
      background: #f5f5f5;

      img {
        width: 0.3rem;
        height: 0.3rem;
      }
    }

    img {
      width: 0.28rem;
      height: 0.28rem;
    }
  }

  .rocket {
    background-color: #fff;
    padding: 0 0.28rem;

    img {
      width: 0.54rem;
      height: 0.32rem;
      margin-right: 0.1rem;
    }

    .rocketTitle {
      color: #fa7c87;
      margin-right: 0.32rem;
    }

    .rocketTice {
      color: #969696;
      font-size: 0.24rem;
      width: 3rem;

      .van-notice-bar {
        width: 3rem;
        padding: 0 !important;
      }

      .rocketNoticeItem {
        margin-right: 0.3rem;
        display: inline-block;
      }
    }
  }

  .gameTopBox {
    width: 100vw;
    overflow: hidden;
    background: #fff;
    padding: 0.32rem 0;

    .gameTop {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      padding: 0 0.28rem;
      text-align: left;

      &.flex {
        display: -webkit-box;
        /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
        display: -moz-box;
        /* Firefox 17- */
        display: -webkit-flex;
        /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
        display: -moz-flex;
        /* Firefox 18+ */
        display: -ms-flexbox;
        /* IE 10 */
        display: flex;
        /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
      }

      /* 隐藏滚动条 */
      scrollbar-width: none;
      /* firefox */
      -ms-overflow-style: none;
      /* IE 10+ */

      &::-webkit-scrollbar {
        width: 0;
        /* Safari,Chrome 隐藏滚动条 */
        height: 0;
        /* Safari,Chrome 隐藏滚动条 */
        display: none;
        /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }

      .gameItem {
        margin-right: 0.2rem;
        text-align: center;
        width: 1.44rem;
        display: inline-block;
        color: #343434;
        font-size: 0.28rem;

        img {
          width: 1.44rem;
          height: 1.22rem;
          border-radius: 0.1rem;
          object-fit: cover;
        }
      }
    }
  }

  // .barTitle {
  //   width: 100%;
  //   overflow: auto;
  //   -webkit-overflow-scrolling: touch;
  //   -webkit-flex: 0 0 auto;
  //   flex: 0 0 auto;
  //   background: #fff;
  //   height: 0.76rem !important;
  //   scroll-behavior: smooth;
  //   z-index: 10;
  //   padding: 0 0.28rem;

  //   /* 隐藏滚动条 */
  //   scrollbar-width: none;
  //   /* firefox */
  //   -ms-overflow-style: none;
  //   /* IE 10+ */

  //   &::-webkit-scrollbar {
  //     width: 0;
  //     /* Safari,Chrome 隐藏滚动条 */
  //     height: 0;
  //     /* Safari,Chrome 隐藏滚动条 */
  //     display: none;
  //     /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
  //   }

  //   div .item {
  //     text-align: center;
  //     height: 0.56rem;
  //     padding: 0 0.4rem;
  //     flex-shrink: 0;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     line-height: 0.56rem;
  //     box-sizing: border-box;
  //     color: #666666;
  //     font-size: 0.28rem;
  //     margin-top: 0.2rem;
  //     border-radius: 0.4rem;

  //     &.active {
  //       color: #fff;
  //       background: -webkit-linear-gradient(left, #ffb3b2, #ffb3b2);
  //       background: linear-gradient(90deg, #ffb3b2, #ffb3b2);
  //     }
  //   }
  // }

  .live {
    background-color: #fff;
    font-size: 0.28rem;
    color: #626872;
    margin-bottom: 0.4rem;
    position: absolute;
    width: 100%;
    min-height: 5rem;

    .noData {
      top: 40%;
    }

    .gameItem {
      padding: 0 0rem 0.2rem 0.28rem;
      text-align: left;
    }

    .goTop {
      text-align: center;
      margin-top: 0.2rem;
      color: #fa7c87;
      font-size: 0.24rem;
      text-align: center;
      color: #fa7c87;
      font-size: 0.24rem;
      margin: 0.2rem auto;
      max-width: 3rem;

      img {
        width: 0.24rem;
        margin-left: 0.2rem;
      }
    }

    .gameCont_item {
      height: 4rem;
      position: relative;
      width: calc(50% - 0.2rem);
      display: inline-block;
      margin-right: 0.2rem;
      margin-top: 0.2rem;
      box-shadow: 0px 1px 2px 1px rgba(250, 143, 175, 0.2);
      border-radius: 0.2rem;
      vertical-align: middle;

      &.live_item {
        box-shadow: 0px 1px 2px 1px rgba(194, 217, 249, 0);
        overflow: hidden;

        .liveBg {
          height: 4rem;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          object-fit: cover;
        }

        .liveHead {
          position: absolute;
          width: 100%;
          text-align: right;

          .liveLock {
            width: 0.4rem;
            margin: 0.1rem 0.1rem 0 0;
          }

          // .liveHeadCont {
          //   color: #fff;
          //   background-image: url('../../assets/img/home/liveHeadBg.png');
          //   background-size: 100% 100%;
          //   background-repeat: no-repeat;
          //   width: 2.12rem;
          //   min-height: 0.4rem;
          //   line-height: 0.32rem;
          //   font-size: 0.2rem;
          //   padding-left: 0.2rem;
          //   padding-top: 0.05rem;
          // }
        }

        .liveBottom {
          position: absolute;
          bottom: 0rem;
          padding: 0.1rem 0.16rem;
          color: #fff;
          font-size: 0.28rem;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.2);

          img {
            width: 0.26rem;
            margin-right: 0.05rem;
          }

          div:nth-child(2) {
            min-width: 0.7rem;
          }
        }

        &.bigLiveItem {
          width: calc(100% - 0.2rem);
          height: 2rem;

          .liveBg {
            height: 2rem;
          }
        }
      }

      &.marg {
        margin-right: 0rem;

        &:nth-child(2n) {
          margin-right: 0.2rem;
        }
      }

      .bg {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 2rem;
        border-radius: 0.1rem 0.1rem 0 0;
      }

      &.gameCont_item_top {
        height: 2.4rem;

        .bg {
          height: 2.4rem;
        }
      }

      .team {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2.4rem;
        z-index: 10;
        color: #FFFFFF;

        .vs {
          width: 0.7rem;
          margin: 0 0.2rem;
        }
      }

      &.gameCont_itemAll {
        width: 100%;
        font-size: 0.32rem;
        height: 3.2rem;

        .bg {
          height: 3.2rem;
        }

        .team {
          height: 3.2rem;

          .vs {
            width: 1.2rem;
          }
        }

        .name {
          text-align: center;
          width: 100%;
          color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
        }
      }

      &.gameCont_itemAll_other {
        width: 100%;
        font-size: 0.32rem;
        height: 3.2rem;

        .bg {
          width: 100%;
          height: 2.4rem;
        }
      }

      .gameName {
        color: #626872;
        font-size: 0.24rem;
        text-align: center;
        margin-top: 0.05rem;
      }
    }
  }

  .content {
    padding: 0.2rem 0.28rem 0.16rem 0.28rem;
    overflow: hidden;
    // /* 隐藏滚动条 */
    // scrollbar-width: none;
    // /* firefox */
    // -ms-overflow-style: none;
    // /* IE 10+ */
    // box-sizing: border-box;
    // -moz-box-sizing: border-box;
    // -webkit-box-sizing: border-box;
    // -o-box-sizing: border-box;
    // -ms-box-sizing: border-box;

    // /* 隐藏滚动条 */
    // scrollbar-width: none; /* firefox */
    // -ms-overflow-style: none; /* IE 10+ */

    // &::-webkit-scrollbar {
    //   width: 0;      /* Safari,Chrome 隐藏滚动条 */
    //   height: 0;     /* Safari,Chrome 隐藏滚动条 */
    //   display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
    // }

    .item {
      width: 100%;
      height: 2.92rem;
      position: relative;
      margin-bottom: 0.2rem;
      border-radius: 0.4rem;
      overflow: hidden;

      .imgs {
        width: 100%;
        height: 2.92rem;
        border-radius: 0.1rem;
      }

      .topiconbox {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        min-width: 2.16rem;
        height: 0.4rem;
        line-height: 0.3rem;
        font-size: 0.2rem;
        color: #fff;
        background: url('../../assets/img/discount/icon.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 0.05rem 0.15rem 0 0.1rem;
      }

      // .topiocn {
      //   position: absolute;
      //   top: 0;
      //   left: -0.05rem;
      //   z-index: 2;
      //   width: 1.56rem;
      //   height: 0.54rem;
      // }
    }

    .loadAll {
      font-size: 0.24rem;
      color: rgb(165, 169, 179);
    }
  }

  .showAll {
    font-size: 0.24rem;
    color: #626872;
    text-align: center;
    height: 0.8rem;
    line-height: 0.8rem;
  }

  .dialogCont {
    text-align: left;
    color: #666;
    font-size: 0.22rem;

    .noticeContTop {
      position: relative;
      z-index: 1;
      width: 100%;
    }

    .noticeContHead {
      width: 4.1rem;
      height: 0.5rem;
      line-height: 0.5rem;
      margin: 0 auto;
      position: absolute;
      z-index: 2;
      text-align: center;
      font-size: 0.32rem;
      color: #a44819;
      top: 3.2rem;
      left: 0;
      right: 0;
    }

    .noticeContBox {
      width: 6rem;
      height: 9rem;
      background-image: url("../../assets/img/postBg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin: 0 auto;
      padding-top: 0.6rem;

      .noticeContBoxLeft {
        width: 1.84rem;
        height: 5.2rem;
        padding-left: 0.3rem;
        margin-top: 2.8rem;

        .noticeContBoxLeftItem {
          background-image: url("../../assets/img/postBtn.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 100%;
          padding: 0.2rem 0.17rem 0.3rem;
          text-align: center;
          margin-top: 0.15rem;

          &.active {
            background-image: url("../../assets/img/postBtnA.png");
            color: #fff;
          }
        }
      }

      .noticeContBoxRight {
        width: 3.6rem;
        height: 5.2rem;
        padding-right: 0.4rem;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        margin-right: 0.1rem;
        margin-top: 2.9rem;
      }
    }

    .noticeDialogColse {
      margin-top: 0.4rem;
      text-align: center;

      i {
        font-size: 0.5rem;
      }
    }
  }

  .dialogRechange {
    text-align: left;
    color: #666;
    font-size: 0.22rem;
    position: relative;
    display: inline-block;

    img {
      max-width: 100%;
    }

    .operateBox {
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      width: 100%;
      white-space: nowrap;

      div {
        display: inline-block;
        color: #86001C;
        background: -webkit-linear-gradient(#FFF288, #F8977D);
        background: linear-gradient(#FFF288, #F8977D);
        padding: 0.1rem 0.3rem;
        border-radius: 0.2rem;
        font-size: 0.3rem;
      }
    }
  }
}
</style>
<style lang="scss">
.home {
  .van-swipe__indicators {
    bottom: 0.5rem;
  }

  .van-swipe__indicator {
    background-color: #fff;
    opacity: 0.8;
  }

  .van-swipe__indicator--active {
    background-color: #fa7c87;
    width: 0.16rem;
  }

  .noticeBar {
    text-align: left;
    padding-left: 1.6rem;
    position: relative;

    .noticeBarIcon {
      width: 2.08rem;
      height: 0.4rem;
      position: absolute;
      left: 0.28rem;
      top: 0.2rem;
      background-image: url('../../assets/img/home/noticeBar.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 0.2rem;
      color: #febd5c;
      font-size: 0.24rem;
    }

    .noticeBarItem {
      margin-left: 0.2rem;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .van-tabs__wrap {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    /* 隐藏滚动条 */
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */

    &::-webkit-scrollbar {
      width: 0;
      /* Safari,Chrome 隐藏滚动条 */
      height: 0;
      /* Safari,Chrome 隐藏滚动条 */
      display: none;
      /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
    }

    .van-tab {
      padding: 0 0.28rem;
    }

    .van-tabs__nav--line {
      padding-bottom: 0.2rem;
    }
  }

  .van-tab__text--ellipsis {
    white-space: nowrap;
  }

  .van-overlay {
    background: transparent !important;
  }

  .barTab {
    margin: 0.2rem 0 0;
    .van-tabs__line {
        display: none !important;
    }

    .van-tabs__wrap {
      height: 0.6rem !important;
    }

    .van-tab--grow {
      padding: 0 !important;

      .van-tab__text {
        text-align: center;
        height: .56rem;
        padding: .2rem .35rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: .56rem;
        box-sizing: border-box;
        border-radius: 20px;
      }

      &.van-tab--active {
        .van-tab__text {
          background: linear-gradient(90deg, #ffb3b2, #ffb3b2);
          border: 1px solid #ffb3b2;
          color: #fff;
        }
      }
    }
  }
}

.noticeDialog {
  &.van-dialog {
    background: transparent;
  }

  .van-dialog__content {
    padding: 0.8rem 0.4rem;
  }
}
</style>