<template>
    <div>
        <van-action-sheet class="liveRoomBetSureModel" v-model:show="isBetList" :title="`${$t('lottery.betConfirm')}`"
            @cancel="close" @close="close">
            <div class="betHead flexBetween">
                <div><span>{{ betPramas.chinese }}</span> {{$t("lottery.No")}} {{issue.expect}}</div>
                <div>
                    <span v-show="parseInt(second)<=54">{{ $t('lottery.inBet')}}</span>
                    <span v-show="parseInt(second)>54">{{$t('lottery.closure')}}</span>
                    <span v-show="parseInt(second)<=54">00:{{second > 9 ? second : '0'+second}}</span>
                    <span v-show="parseInt(second)>54">00:00</span>
                </div>
            </div>
            <div class="betList">
                <div v-for="(item, index) in lotteryBetData" :key="`${index}--lotteryActvieBet`" class="betItem flexBetween">
                    <div>
                        <p v-if="betPramas.name == 'sd' || betPramas.name == 'yuxx' || betPramas.name == 'yflhc'" class="red">{{item.playNameShow}}</p>
                        <p v-else class="red">{{item.methodShow}}</p>
                        <p>{{item.lotteryTypeShow}} <span>1 X {{ betNumActive }}</span></p>
                    </div>
                    <div>
                        <van-icon @click.stop="delChoose(index)" name="cross" />
                        <div @click.stop="eidtItem(index)" class="red">{{ item.money }} X {{betNumActive}}</div>
                    </div>
                </div>
            </div>
            <div class="betNumList flexCenter">
                <div v-for="(item, index) in betNum" :key="`${index}-lottery-betNum`" :class="betNumActive==item?'active':''" @click="betNumClick(item)">
                    X{{item}}
                </div>
            </div>
 
            <div class="total flexBetween">
                <div class="totalItem">
                    <div>
                        <span>{{$t('lottery.note')}}:</span>
                        <span class="num">{{lotteryBetData.length}}</span>
                    </div>
                    <div>
                        <span>{{$t('my.proxy.balance')}}:</span>
                        <span class="num">{{ member && member.goldCoin ? numFormat(member.goldCoin) : '0'}}</span>
                    </div>
                </div>
                <div class="totalItem">
                    <div>
                        <span>{{$t('lottery.totalAmount')}}:</span>
                        <span class="num">{{ total }}</span>
                    </div>

                    <div>
                        <div class="betBtn" @click="lotteryBet">{{$t('lottery.placeABet')}}</div>
                    </div>
                </div>
            </div>
        </van-action-sheet>

        <!-- 自定义金额弹框 -->
    <van-dialog v-model:show="customize" className="customizeDialog" :title="`${$t('lottery.enterCustomAmount')}`" 
        :before-close="onBeforeClose"
        @confirm="handleConfirm"
        show-cancel-button 
        :confirmButtonColor="'#fa7c87'"
        :confirmButtonText="$t('common.confirm')"
        :cancelButtonText="$t('common.cancel')"
        closeOnClickOverlay closeOnPopstate>
        <div class="customizeContent">
            <van-cell-group v-if="customize">
                <van-field v-model.trim="customizeValue" @keyup="customizeValue=customizeValue.replace(/[^\d]/g,'')" placeholder="5-20000" autocomplete="off" clearable/>
            </van-cell-group>
        </div>
    </van-dialog>
    </div>
</template>
  
<script>
import { lotteryBet } from "@/api/index";
import { mapState } from 'vuex';
export default {
    name: "liveRoomLotteryBetSure",
    props: ['lotteryActvieBet', 'second'],
    data() {
        return {
            isBetList: false,
            isLotteryBetting: false, 
            timer: null, //倒计时容器 展示
            // activeBet: [],
            betNum: [1, 2, 5, 10, 20],
            lotteryBetData: [],
            customizeValue: '',
            customize: false,
            chooseIndex: {}, //当前操作的item
        }
    },
    computed: {
        ...mapState(['lotteryRouter', 'member', 'weightList', 'issue', 'betPramas', 'weightListConfirm', 'customizeValueConfirm', "liveInfo"]),
        betNumActive() {
            return this.$store.state.multiple;
        },
        total() {
            let total = 0;
            if (this.lotteryBetData.length > 0) {
                this.lotteryBetData.forEach(item => {
                    total += item.money * this.betNumActive;
                });
            }

            return total;
        }
    },
    created() {
        let that = this;
        setTimeout(() => {
            that.isBetList = true;
            that.isLotteryBetting = false;
        }, 10)

        if (this.lotteryActvieBet.length > 0) {
            this.lotteryActvieBet.forEach(item => {
                let obj = JSON.parse(JSON.stringify(item));
                if (!item.money) {
                    obj.money = this.weightListConfirm == (this.weightList.length -1) && this.customizeValueConfirm ? this.customizeValueConfirm : this.weightList[this.weightListConfirm];
                }
                this.lotteryBetData.push(obj);
            });
        }
    },
    methods: {
        // 点击item金额
        eidtItem(index) {
            this.chooseIndex = index;
            this.customize = true;
        },
        // 关闭前校验开启
        onBeforeClose(action) {
            new Promise((resolve) => {
                if (action === 'confirm') {
                    resolve(false);
                } else {
                    // 拦截取消操作
                    // resolve(false);
                    this.customize = false; 
                }
            });
        },
        // 实例弹窗确认
        handleConfirm() {
            if (!this.customizeValue) return this.$toast(this.$t('lottery.entryNumberTips'));
            if (String(this.customizeValue) && (parseFloat(this.customizeValue) < 5 || parseFloat(this.customizeValue) > 20000)) return this.$toast(this.$t('lottery.entryNumberTips')) ;
            this.lotteryBetData[this.chooseIndex].money = this.customizeValue;
            this.customize = false;
        },
        betNumClick(item) {
            this.$store.commit("setState", { multiple: item });
        },
        // 下注
        lotteryBet() {
            if (this.isLotteryBetting) return;
            let params = {
                isHemai: 0,  // 写死
                isStop: 0, // 写死
                liveId: this.liveInfo.liveId,
                expect: [
                    {
                        expect: this.issue.expect, //期号
                        multiple: 1 // 写死
                    }
                ],
                lotteryName: this.betPramas.name,
                times: this.betNumActive, //倍数
                uid: this.member.uid,
                // betFrom: "game",
                timestamp: new Date().getTime()
            }
            let arr = [];
            this.lotteryBetData.forEach(item => {
                arr.push({
                    money: item.money, //金额
                    notes: 1, //写死
                    rebate: 0,  //写死
                    type: item.type,
                    type_text: item.lotteryType,
                    num: item.method
                });
            });
            params.playNum = JSON.parse(JSON.stringify(arr));
            params.betAmount = this.total;
            this.isLotteryBetting = true;

            lotteryBet(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('lottery.betSuccess'));
                    this.$emit('initLotteryBet');
                    this.close();
                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.isLotteryBetting = false;
                }
            }).catch(err => {
                console.log(err);
                this.isLotteryBetting = false;
            });
        },
        close() {
            this.isBetList = false;
            let that = this;
            clearInterval(this.timer);
            setTimeout(() => {
                that.$emit('colsePopup', 'showLotteryBetSure')
            }, 500)
        },
        // 删除投注项
        delChoose(index) {
            if (this.lotteryBetData.length == 1) {
                this.$dialog.confirm({
                    message: this.$t('lottery.sureClearBet'),
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    className: "confirmDialog",
                    confirmButtonColor: "#fa7c87"
                }).then(() => {
                    // this.activeBet.splice(index, 1);
                    this.lotteryBetData.splice(index, 1);
                    this.$emit('spliceLotteryItem', index);
                    if (this.lotteryBetData.length == 0) {
                        this.isBetList = false;
                    }
                }).catch(() => {
                    // console.log("取消");
                });
            } else {
                this.lotteryBetData.splice(index, 1)
                this.$emit('spliceLotteryItem', index);
                // this.activeBet.splice(index, 1);
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>
  
<style lang="scss" scoped>
.betNumList {
    margin: 0.2rem 0;
    padding: 0 0.28rem;

    div {
        width: calc(20% - 0.16rem);
        text-align: center;
        margin-right: 0.2rem;
        color: #788597;
        padding: 0.12rem 0;
        border: 1px solid #fa7c87;
        border-radius: 0.1rem;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            background-color: #fa7c87;
            color: #fff;
        }
    }
}

.total {
    color: #fff;
    padding: 0 0.28rem;
    text-align: left;
    margin-bottom: 0.2rem;

    &>div:last-child {
        text-align: right;
    }


    .betBtn {
        background: -webkit-linear-gradient(left,#fa7c87,#fc9799);
        background: linear-gradient(90deg,#fa7c87,#fc9799);
        color: #fff;
        text-align: center;
        border-radius: 0.5rem;
        padding: 0rem 0.28rem;
        line-height: 0.6rem;
        height: 0.6rem;
        min-width: 1.6rem;
    }

    .totalItem {
        &>div:first-child {
            margin-bottom: 0.1rem;
        }

        .num {
            color: #fa7c87;
            margin-left: 0.1rem;
        }
    }
}
</style>
<style lang="scss">
.liveRoomBetSureModel {
    background-color: rgba(0,0,0,0.8) !important;
    color: #fff;
    .van-action-sheet__header {
        font-size: 0.32rem;
        font-weight: bold;
        color: #fff;
    }

    .van-action-sheet__close {
        font-size: 0.34rem;
    }

    .ruleFont {
        min-height: 5.4rem;
        text-align: left;
        padding: 0.28rem;
    }

    .betHead {
        padding: 0.24rem 0.28rem;
        line-height: 0.34rem;
        color: #ffd96b;

        span {
            font-size: 0.2rem;
            margin-right: 0.1rem;
        }
    }

    .betList {
        color: #243050;
        min-height: 3.48rem;
        max-height: 5.6rem;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .betItem {
            padding: 0.16rem 0.28rem;
            background-color: #000;
            border-radius: 0.1rem;
            color: #fff;
            margin: 0 0.24rem 0.16rem;

            div:nth-child(1) {
                font-size: 0.4rem;
                text-align: left;

                p:nth-child(1) {
                    margin-bottom: 0.1rem;
                    font-size: 0.32rem;
                }

                p:nth-child(2) {
                    font-size: 0.24rem;

                    span {
                        display: inline-block;
                        margin-left: 0.2rem;
                    }
                }
            }

            div:nth-child(2) {
                text-align: right;
                font-size: 0.28rem;

                i {
                    font-size: 0.36rem;
                    color: #fff;
                }

                div {
                    margin-top: 0.05rem;
                }
            }

            .red {
                color: #fa7c87;
            }
        }
    }

}
</style>