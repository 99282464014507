<template>
  <div>
     <!-- 筹码弹框 -->
     <van-popup
        v-model:show="isShowChips"
        round
        class="gameListDialog"
        closeable
        :safe-area-inset-bottom="true"
        position="bottom"
        @close="closeLotteryPopup"
        >
        <div class="gameListDialogTitle">{{ $t('liveRoom.setChips') }}</div>
        <div class="weightList">
            <div class="weightItem" v-for="(item, index) in weightList" :key="`${index}--weightList`" :class="weightListActive == index ? `weightListActive weight${item}` : `weight${item}`"  @click="weightListClick(item, index)">
                <span v-if="!isNaN(item)">{{ item }}</span>
                <span v-else>{{ index == (weightList.length - 1) && customizeValue ? customizeValue : $t('lottery.customize')}}</span>
            </div>
        </div>
        <div class="confirmBtn hasValue" @click="confirmWeight">{{$t('common.confirm')}}</div>
    </van-popup>

    <!-- 自定义金额弹框 -->
    <van-dialog v-model:show="customize" className="customizeDialog" :title="`${$t('lottery.enterCustomAmount')}`" 
        :before-close="onBeforeClose"
        @confirm="handleConfirm"
        show-cancel-button 
        :confirmButtonColor="'#fa7c87'"
        :confirmButtonText="$t('common.confirm')"
        :cancelButtonText="$t('common.cancel')"
        closeOnClickOverlay closeOnPopstate>
        <div class="customizeContent">
            <van-cell-group>
                <van-field v-model.trim="customizeValue" @keyup="customizeValue=customizeValue.replace(/[^\d]/g,'')" placeholder="5-20000" autocomplete="off" clearable/>
            </van-cell-group>
        </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "liveRoomLotteryChips",
    data() {
        return {
            isShowChips: false,
            customize: false,
            weightListActive: 0,//砝码随机选择
            customizeValue: "",
        }
    },
    computed: {
        ...mapState(['weightList', 'weightListConfirm', 'customizeValueConfirm'])
    },
    created() {
        let that = this;
        setTimeout(() => {
            that.isShowChips = true;
        }, 10);

        this.weightListActive = this.weightListConfirm;
        if (this.weightListConfirm == this.weightList.length - 1) {
            this.customizeValue = this.customizeValueConfirm;
        }
    },
    methods: {
        closeLotteryPopup() {
            this.isShowChips = false;
            let that = this;
            setTimeout(()=> {
                that.$emit('closeLotteryPopup', 'showChips')
            }, 500)
        },
        confirmWeight() {
            if (this.weightListActive == this.weightList.length - 1) {
                this.$store.commit("setState", { weightListConfirm: this.weightListActive, customizeValueConfirm: this.customizeValue });
            } else {
                this.$store.commit("setState", { weightListConfirm: this.weightListActive, customizeValueConfirm: "" });
            }
            this.closeLotteryPopup();
        },
        // 关闭前校验开启
        onBeforeClose(action) {
            new Promise((resolve) => {
                if (action === 'confirm') {
                    resolve(false);
                } else {
                    // 拦截取消操作
                    // resolve(false);
                    this.customize = false; 
                }
            });
        },
        // 实例弹窗确认
        handleConfirm() {
            if (!this.customizeValue) return this.$toast(this.$t('lottery.entryNumberTips'));
            if (String(this.customizeValue) && (parseFloat(this.customizeValue) < 5 || parseFloat(this.customizeValue) > 20000)) return this.$toast(this.$t('lottery.entryNumberTips')) ;
            if (String(this.customizeValue) && (parseFloat(this.customizeValue) >= 5 || parseFloat(this.customizeValue) <= 20000)) {
                this.weightListActive = this.weightList.length -1;
            }
            this.customize = false;
        },
        // 筹码选中逻辑
        weightListClick(item, index) {
            if (item == 'customize') {
                this.customize = true;
            } else {
                this.weightListActive = index;
            }
        },
    }
}
</script>

<style lang="scss">
.gameListDialogTitle {
    font-size: 0.34rem;
    margin:  0.4rem 0;
}

.weightList {
    padding: 0.34rem 0.4rem 0.3rem;
    min-height: 2rem;

    &.ruleFont {
        min-height: 60vh;
    }

    .weightItem {
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        line-height: 1.5rem;
        margin-right: 0.2rem;
        text-align: center;
        font-size: 0.36rem;
        color: #fff;
        margin-bottom: 0.2rem;
        border: 1px solid transparent;
        border-radius: 0.1rem;

        &.weightListActive {
            border: 1px solid #fa7c87;
            background-color: rgba(250, 124, 135, 0.2);
        }

        &:nth-child(4n) {
            margin-right: 0;
        }

        &.weightcustomize {
            font-size: 0.28rem;
        }
    }
} 
.confirmBtn {
    margin: 0 auto 0.2rem;
}
.weight1, .weight5 {
    background-image: url("../../../assets/img/lottery/weight/5.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.weight10 {
    background-image: url("../../../assets/img/lottery/weight/10.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.weight20 {
    background-image: url("../../../assets/img/lottery/weight/20.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.weight50 {
    background-image: url("../../../assets/img/lottery/weight/50.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.weight100 {
    background-image: url("../../../assets/img/lottery/weight/100.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.weight200 {
    background-image: url("../../../assets/img/lottery/weight/200.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.weight500 {
    background-image: url("../../../assets/img/lottery/weight/500.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.weightcustomize {
    background-image: url("../../../assets/img/lottery/weight/customize.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.customizeDialog {
    .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
        border-width: 0;
    }

    .van-dialog__header {
        padding: 0.4rem 0;
        font-weight: bold;
    }

    .van-cell {
        margin: 0 0.4rem 0.4rem;
        border: 1px solid #DCE4F8;
        border-radius: 0.2rem;
        width: auto;
    }
}
</style>