<template>
  <div>
    <!-- 用户卡片-->
    <van-popup v-model:show="isShowCard" round :style="{ width: '6rem' }" @close="closePopup">
        <div class="userDetail">
            <div v-show="(liveInfo.roomManager || isManage) && userDetail.uid != member.uid" class="manage topItem">
                <van-popover v-model:show="showManage">
                    <div class="manageBox">
                        <p v-show="userDetail.isBlackChat && userDetail.uid != liveInfo.anchorId" @click="silence(false)">{{ $t('liveRoom.cancelSilence') }}</p>
                        <p v-show="!userDetail.isBlackChat && userDetail.uid != liveInfo.anchorId" @click="silence(true)">{{ $t('liveRoom.silence') }}</p>
                        <p v-show="userDetail.uid != liveInfo.anchorId" @click="kickOut">{{ $t('liveRoom.kickOutLive') }}</p>
                        <p v-show="isManage" @click="eidtBlackUser(1)">{{ $t('liveRoom.disabledNum') }}</p>
                        <p v-show="isManage" @click="eidtBlackUser(2)">{{ $t('liveRoom.disabledAir') }}</p>
                    </div>
                    <template #reference>
                        <div class="giftNumBtnItem giftNumBtn">{{  $t('liveRoom.manage') }}</div>
                    </template>
                </van-popover>
            </div>
            <div v-show="userDetail.uid != member.uid" @click="userReject(userDetail)" class="pullBlack topItem">
                {{ userDetail.isReject ? $t('liveRoom.blocked') : $t('liveRoom.pullBlack') }}
            </div>

            <div class="userAvatar" :class="getIsNoble(userDetail)">
                <img v-real-img="userDetail.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
            </div>

            <div class="identityBox flexCenterBL">
                <span>{{ userDetail.nickname }}</span>
                <div v-if="userDetail && userDetail.userLevel" class="userLevel">
                    <img v-real-img="getImg(userDetail.userLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                    <span :class="{'small':userDetail.userLevel<10}">{{ userDetail.userLevel ? userDetail.userLevel : 0 }}</span>
                </div>

                <div v-if="userDetail && userDetail.badgeList && userDetail.badgeList.length > 0 && userDetail.badgeList.indexOf(2) != -1" class="userLevel">
                    <img v-real-img="getImg(userDetail.anchorLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                    <span :class="{'small':userDetail.anchorLevel<10}">{{userDetail.anchorLevel}}</span>
                </div>

                <img v-if="userDetail.sex == 2" src="../../assets/img/my/icon/woman.png" />
                <img v-else src="../../assets/img/my/icon/man.png" />

                <div class="userIcon flexCenter" v-if="userDetail && userDetail.badgeShowList && userDetail.badgeShowList.length > 0">
                    <img v-for="(item, index) in userDetail.badgeShowList" :key="`${index}--liveRoom-userCard`" v-real-img="item.logoUrl" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                </div>
            </div>

            <div class="userIDBox flexCenterBL">
                <div class="flexCenter" v-if="userDetail.vipUid">
                    <img class="userIconBox" v-for="(item, index) in userDetail.badgeShowList"
                     :key="`${index}`" v-real-img="item.logoUrl" :errorimg="require(`../../assets/img/errorImg/3.png`)" v-show="userDetail.badgeList[index] > 5 && userDetail.badgeList[index] <= 10"/>
                    {{ $t("my.noble.prettyNum") }}: {{ userDetail.vipUid }}
                </div>
                <div v-else>ID: {{ userDetail.uid }}</div>
                <div @click="copyClick(userDetail.vipUid ? userDetail.vipUid : userDetail.uid)">{{ $t('common.copy') }}</div>
            </div>

            <div class="userFunList flexBetween" :class="{'borderBottom': userDetail.uid != member.uid}">
                <div class="funItem">
                    <p>{{ userDetail.follows }}</p>
                    <p>{{ $t('my.follows') }}</p>
                </div>
                <div class="funItem">
                    <p>{{ userDetail.fans }}</p>
                    <p>{{ $t('my.fans') }}</p>
                </div>
                <div class="funItem">
                    <p>{{ userDetail.sendCoin }}</p>
                    <p>{{ $t('liveRoom.hasSend') }}</p>
                </div>
                <div class="funItem">
                    <p>{{ userDetail.receiveCoin }}</p>
                    <p>{{ $t('liveRoom.received') }}</p>
                </div>
            </div>

            <div v-show="userDetail.uid != member.uid" class="userFunList flexBetween">
                <div @click.stop="follow(userDetail)"  class="funItem" :class="userDetail.isFollow ? '' : 'active'">
                    {{ userDetail.isFollow ? $t('my.follow.followed') :  `${$t('my.follows')}` }}
                </div>
                <div @click="showPrivateLetter=true" class="funItem">
                    {{ $t('my.privateLetter') }}
                </div>
                <div @click="qUser" class="funItem">
                    @{{ $t('liveRoom.qHer') }}
                </div>
                <div @click="goUserDetail" class="funItem">
                    {{ $t('liveRoom.homePage') }}
                </div>
            </div>
        </div>
    </van-popup>

    <privateLetter v-if="showPrivateLetter" @closePrivateLetter="closePrivateLetter"></privateLetter>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { liveFollow, userReject } from "@/api/index"; 
import { userCardInfo, blackChat, banUser, blackUser } from "@/api/live";
import privateLetter from "@/pages/components/privateLetter.vue";
import utils from "@/utils/index";
export default {
    name: "liveRoomUserInfo",
    props: ['showUserDetail'],
    components: {
        privateLetter
    },
    data() {
        return {
            isShowCard: false,
            userDetail: {},
            showPrivateLetter: false,
            showManage: false
        }
    },
    computed: {
        ...mapState(['betPramas', 'liveInfo', 'configBadge', 'member', 'avatarList']),
        isManage() {
            let flag = false;
            if (this.member && this.member.badgeList && this.member.badgeList.length > 0) {
                let index = this.member.badgeList.findIndex(item => {
                    return item == '4'
                });

                if (index != -1) {
                    flag = true;
                }
            }
            return flag;
        }
    },
    created() {
        let that = this;
        this.getInfo();
        setTimeout(()=> {
            that.isShowCard = true;
        }, 10)
    },
    methods: {
        // 头像列表 贵族的class
        getIsNoble(item) {
            let className = '';
            if (item.badgeList && item.badgeList.length > 0) {
                item.badgeList.forEach(bitem => {
                    if(bitem > 5 && bitem <= 10) {
                        className = `isNoble${bitem}`
                    }
                })
            }

            return className;
        },
        // 封机 封号 封人结束  调用踢人接口
        eidtBlackUser(type) {
            let params = {
                uid: this.userDetail.uid,
                type
            }
            blackUser(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (type == 1) {
                        this.$toast(this.$t('liveRoom.disabledNumSecc'));
                    } else {
                        this.$toast(this.$t('liveRoom.disabledAirSecc'));
                    }
                    this.kickOut();
                    this.closePopup();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {})
        },
        // @她
        qUser() {
            this.$emit('qUser', this.userDetail);
        },
        // 管理
        onSelect(val) {
            this[val.event]();
        },
        // 禁言
        silence(flag) {
            let params = {
                isBlack: flag, //禁言/解禁
                liveId: this.liveInfo.liveId,
                uid: this.userDetail.uid
            }
            blackChat(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let msg = flag ? this.$t('liveRoom.silenceSecc') : this.$t('liveRoom.cancelSilenceSecc');
                    this.$toast(msg);
                    this.closePopup();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 踢人
        kickOut(flag) {
            let params = {
                liveId: this.liveInfo.liveId,
                uid: this.userDetail.uid
            }
            banUser(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (flag) {
                        this.$toast(this.$t('liveRoom.kickOutLiveSecc'))
                    }

                    let avatarList = this.avatarList.length > 0 ? JSON.parse(JSON.stringify(this.avatarList)) : [],
                    index = avatarList.findIndex(item => {
                        item.uid == this.userDetail.uid;
                    });

                    if (index != -1) {
                        avatarList.splice(index, 1);
                        this.$store.commit("setState", { avatarList });
                    }
                    this.closePopup();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 主页
        goUserDetail() {
            this.$emit('openPopup', 'showUserInfo');
            this.closePopup();
        },
        // 关闭私信
        closePrivateLetter() {
            this.showPrivateLetter = false;
        },
        closePopup() {
            this.isShowCard = false;
            setTimeout(()=> {
                this.userDetail = {};
                this.$emit('closeUserCard')
            }, 10)
        },
        // 复制
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        // 匹配用户等级图片
        getImg(val) {
            if (val <= 10) {
                return require("../../assets/img/noble/level/level_1-10.png");
            }

            if (10 < val && val <= 20) {
                return require("../../assets/img/noble/level/level_11-20.png");
            }

            if (20 < val && val <= 30) {
                return require("../../assets/img/noble/level/level_21-30.png");
            }

            if (30 < val && val <= 40) {
                return require("../../assets/img/noble/level/level_31-40.png");
            }

            if (40 < val && val <= 50) {
                return require("../../assets/img/noble/level/level_41-50.png");
            }

            if (50 < val && val <= 60) {
                return require("../../assets/img/noble/level/level_51-60.png");
            }

            if (60 < val && val <= 70) {
                return require("../../assets/img/noble/level/level_61-70.png");
            }

            if (70 < val && val <= 80) {
                return require("../../assets/img/noble/level/level_71-80.png");
            }

            if (80 < val && val <= 90) {
                return require("../../assets/img/noble/level/level_81-90.png");
            }

            if (90 < val) {
                return require("../../assets/img/noble/level/level_91-105.png");
            }
        },
        // 获取用户详情
        getInfo() {
            let params = {
                uid: this.betPramas.uid,
                anchorId: this.liveInfo.anchorId,
                liveId: this.liveInfo.liveId
            };
            userCardInfo(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let userInfo = JSON.parse(JSON.stringify(res.data)), badgeShowList=[];
                    if (userInfo.badgeList && userInfo.badgeList.length > 0 && this.configBadge.length > 0) {
                        badgeShowList = utils.matchBadgeList(userInfo.badgeList, this.configBadge);
                    }
                    userInfo.badgeShowList = JSON.parse(JSON.stringify(badgeShowList));
                    this.userDetail = JSON.parse(JSON.stringify(userInfo));
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 关注 - 取关
        follow(item) {
            let params = {
                isFollow: item.isFollow ? false : true,
                targetId: item.uid
            }
            liveFollow(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let str = item.isFollow ? this.$t('my.follow.Unfollowed') : this.$t('my.follow.followeSuccess');
                    this.$toast(str);
                    this.getInfo();

                    if (this.betPramas.anchorId) {
                        this.$emit('refresh', 'anchorBase')
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 设置-取消 黑名单
        userReject(info) {
            let params = {
                isReject: info.isReject ? false : true,
                uid: info.uid
            }
            userReject(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let str = info.isReject ? this.$t('liveRoom.blockedTips') : this.$t('liveRoom.pullBlackTips');
                    this.$toast(str);
                    this.getInfo();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
 .userDetail {
    padding: 0.4rem 0.28rem 0.28rem;
    font-size: 0.24rem;
    position: relative;

    .topItem {
        position: absolute;
        top: 0.2rem;
        font-size: 0.24rem;

        &.manage {
            left: 0.28rem;
            color: #d6af48;
        }

        &.pullBlack {
            right: 0.28rem;
            color: #333;
        }
    }

    .userAvatar {
        display: inline-block;
        overflow: hidden;
        margin: 0 auto 0.3rem;
        padding: 0.1rem 0.12rem 0.07rem;

        img {
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 50%;
            object-fit: cover;
        }

        &.isNoble6 {
            background-image: url("../../assets/img/noble/headEmblem/6.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        &.isNoble7 {
            background-image: url("../../assets/img/noble/headEmblem/7.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        &.isNoble8 {
            background-image: url("../../assets/img/noble/headEmblem/8.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        &.isNoble9 {
            background-image: url("../../assets/img/noble/headEmblem/9.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        &.isNoble10 {
            background-image: url("../../assets/img/noble/headEmblem/10.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }

    .identityBox {
        margin-bottom: 0.2rem;

        img {
            width: 0.44rem;
            margin-left: 0.1rem;
        }

        .userLevel {
            width: 0.7rem;
            height: 0.35rem;
            line-height: 0.35rem;
            position: relative;
            margin-left: 0.1rem;

            img {
                width: 100%;
                margin-left: 0;
            }

            span {
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0.06rem;
                color: #fff;

                &.small {
                    right: 0.12rem;
                }
            }
        }
    }

    .userIDBox {
        color: #333;

        div:nth-child(1) {
            border-right: 1px solid #333;
            padding-right: 0.2rem;
            margin-right: 0.1rem;
        }

        div:nth-child(2) {
            padding: 0.02rem 0.2rem 0.04rem;
            border-radius: 0.1rem;
            background-color: #fa7c87;
            color: #fff;
            font-size: 0.2rem;
        }

        .userIconBox {
            width: 0.4rem;
            margin-right: 0.05rem;
        }
    }

    .userFunList {
        color: #666;
        font-size: 0.2rem;
        padding: 0.3rem 0.2rem;

        &.borderBottom {
            border-bottom: 1px solid #ebeff1;
        }

        &:not(.borderBottom) {
            color: #333;
        }

        .funItem{
            color: #666;

            &.active {
                color: #fa7c87;
            }
        }
    }
}
</style>
<style lang="scss">
.manageBox {
    font-size: 0.32rem;
    padding: 0.2rem 0.4rem 0.2rem 0.2rem;

    p {
        margin-bottom: 0.3rem;
    }
}
</style>