<template>
    <div class="myNoble next">
        <div class="title" :class="detail && detail.levelId ? `level${detail.levelId}`:''">
            <span>{{ $t('my.listView.myNoble') }}</span>
            <div class="fixed" @click="goOtherPage('/mine')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#fff" p-id="12457"></path>
                </svg>
            </div>
        </div>


        <div class="listTop" :class="detail && detail.levelId ? `level${detail.levelId}`:''">
            <img v-if="activeLevel.level" v-real-img="require(`../../../assets/img/noble/icon/${activeLevel.level}.png`)" :errorimg="require(`../../../assets/img/errorImg/2.png`)"/>
            <p>{{ $t('my.noble.expireDate') }}  {{ formatDate(detail.endTime) }}</p>
            <div class="funBtn">
                <span @click="reNewClick">{{ $t('my.noble.renew') }}</span>
                <span @click="upVipClick">{{ $t('my.noble.upgrade') }}</span>
            </div>
        </div>

        <div class="listBox" :class="detail && detail.levelId ? `level${detail.levelId}`:''">
            <div class="listItem has">
                <div class="imgBox flexCenterBL">
                    <img src="../../../assets/img/noble/list/wzzm.png" />
                </div>
                <p class="listItemTitle"> {{ $t("my.noble.wzzm") }}</p>
                <p class="listItemDetail">{{ $t("my.noble.wzzmDetail1") }} {{ activeLevel.name }} {{ $t("my.noble.wzzmDetail2") }}
                </p>
            </div>

            <div class="listItem has">
                <div class="imgBox flexCenterBL">
                    <img src="../../../assets/img/noble/list/pk.png" />
                </div>
                <p class="listItemTitle">{{ $t("my.noble.pk") }}</p>
                <p class="listItemDetail"> {{ activeLevel.pkAddition }} {{ $t("my.noble.pkDetail") }} </p>
            </div>

            <div class="listItem has">
                <div class="imgBox flexCenterBL">
                    <img src="../../../assets/img/noble/list/zslw.png" />
                </div>
                <p class="listItemTitle">{{ $t("my.noble.zslw") }}</p>
                <p class="listItemDetail">{{ $t("my.noble.zslwDetail") }} {{ activeLevel.name }} {{ $t("my.noble.zslwDetail1") }}</p>
            </div>

            <div class="listItem" :class="{'has': [2, 3, 4, 5].indexOf(detail.levelId) != -1 }">
                <div class="imgBox flexCenterBL">
                    <img src="../../../assets/img/noble/list/gzlh.png" />
                </div>
                <p class="listItemTitle">{{ $t("my.noble.gzlh") }}</p>
                <p v-show="!activeLevel.num" class="listItemDetail">
                    {{ $t("my.noble.gzlhNo") }}
                </p>
                <p v-show="activeLevel.num" class="listItemDetail">
                    {{ activeLevel.num }} {{ $t("my.noble.gzlhDetail") }}
                </p>
            </div>

            <div class="listItem"  :class="{'has': [3, 4, 5].indexOf(detail.levelId) != -1 }">
                <div class="imgBox flexCenterBL">
                    <img src="../../../assets/img/noble/list/cjft.png" />
                </div>
                <p class="listItemTitle">{{ $t("my.noble.cjft") }}</p>
                <p v-show="[5].indexOf(detail.level) == -1" class="listItemDetail">{{ $t("my.noble.cjftDetail") }}</p>
                <p v-show="[5].indexOf(detail.level) != -1" class="listItemDetail">{{ $t("my.noble.cjftDetail1") }}</p>
            </div>

            <div class="listItem" :class="{'has': [3, 4, 5].indexOf(detail.levelId) != -1 }">
                <div class="imgBox flexCenterBL">
                    <img src="../../../assets/img/noble/list/smcl.png" />
                </div>
                <p class="listItemTitle">{{ $t("my.noble.smcl") }}</p>
                <p class="listItemDetail">{{ $t("my.noble.smclDetail") }}</p>
            </div>

            <div class="listItem" :class="{'has': [4, 5].indexOf(detail.levelId) != -1 }">
                <div class="imgBox flexCenterBL">
                    <img src="../../../assets/img/noble/list/cjys.png" />
                </div>
                <p class="listItemTitle">{{ $t("my.noble.cjys") }}</p>
                <p v-show="[5].indexOf(detail.levelId) == -1" class="listItemDetail">
                    {{ $t("my.noble.cjysDetail1") }}
                </p>
                <p v-show="[5].indexOf(detail.levelId) != -1" class="listItemDetail">
                    {{ $t("my.noble.cjysDetail") }} 
                </p>
            </div>
        </div>

        <div class="nobleNum" :class="detail && detail.levelId ? `level${detail.levelId}`:''">
            <p>{{ $t("my.noble.nobleName") }}</p>
            <p>{{ $t("my.noble.prettyNum") }}：{{ activeLevel.num ? detail.vipUid : $t('my.noble.gzlhNo') }}</p>
            <p>{{ activeLevel.num ? (activeLevel.num  + $t('my.noble.gzlhDetail')) : '' }}</p>
        </div>

        <div class="invisibility" :class="detail && detail.levelId ? `level${detail.levelId}`:''">
            <p>{{ $t("my.noble.stealthFun") }}</p>
            <div @click="hide('roomHide')" :class="detail.roomHide ? 'turnOn' : 'default'">{{ $t("my.noble.roomStealth") }}</div>
            <div @click="hide('chatHide')" :class="detail.chatHide ? 'turnOn' : 'default'">{{ $t("my.noble.chatStealth") }}</div>
            <div @click="hide('rankHide')" :class="detail.rankHide ? 'turnOn' : 'default'">{{ $t("my.noble.topStealth") }}</div>
        </div>
    </div>
</template>
  
<script>
import { configVip } from "@/api/config";
import { vipInfo, reNewVip, upVip, vipHide } from "@/api/index";
import { mapState } from 'vuex';
import moment from 'moment';
export default {
    data() {
        return {
            list: [],
            userLevel: {},
            vipHeight: '',
            tableHiehgt: "",
            listImg: [
                { src: require('../../../assets/img/noble/list/wzzm.png') },
                { src: require('../../../assets/img/noble/list/pk.png') },
                { src: require('../../../assets/img/noble/list/zslw.png') },
                { src: require('../../../assets/img/noble/list/gzlh.png') },
                { src: require('../../../assets/img/noble/list/cjft.png') },
                { src: require('../../../assets/img/noble/list/smcl.png') },
                { src: require('../../../assets/img/noble/list/cjys.png') },
            ],
            detail: {},
            activeLevel: {},
            allList: []
        }
    },
    computed: {
        ...mapState(['member', 'entry']),
    },
    created() {
        this.vipInfo();
        this.$store.commit("setState", { isLoadForLoad: false });
    },
    mounted() {
        // this.vipHeight = document.body.clientHeight;
        // let topHead_username = document.getElementsByClassName('topHead_username')[0].clientHeight,
        //     detailTitle = document.getElementsByClassName('detailTitle')[0].clientHeight,
        //     detailTable_head = document.getElementsByClassName('detailTable_head')[0].clientHeight;
        // this.tableHiehgt = document.body.clientHeight - topHead_username - detailTitle - detailTable_head;
    },
    methods: {
        // 隐身功能
        hide(val) {
            let obj = {'roomHide': 1,'chatHide': 2,'rankHide': 3},
            params = {
                group: 0,
                chatHide: this.detail.chatHide,
                rankHide: this.detail.rankHide,
                roomHide: this.detail.roomHide,
                type: obj[val]
            }

            let str = "", str1 = "", 
            index = this.allList.findIndex(item => {
               return item.id == 4
            }),
            index1  = this.allList.findIndex(item => {
                return item.id == 5
            });

            if (index != -1) {
                str = this.allList[index].name;
            }

            if (index1 != -1) {
                str1 = this.allList[index1].name;
            }
            // 隐身是否有权限
            if (val == 'roomHide' && [4, 5].indexOf(this.detail.levelId) == -1) return this.$toast(`${this.$t('my.noble.noPermissions')}  ${str} ${str1}`);
            if (val != 'roomHide' && this.detail.levelId != 5) return this.$toast(this.$t('my.noble.noPermissions') + str1);
            
            // 过期提示
            if (this.detail.endTime <= new Date().getTime()) {
                this.$dialog.confirm({
                    message: this.$t('my.noble.expiredTips'),
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    className: "confirmDialog",
                    confirmButtonColor: "#fa7c87"
                }).then(() => {
                    this.$router.push('/mine/noble');
                }).catch(() => {
                    // console.log("取消");
                });
            } else {
                if (params[val]) {
                    params[val] = 0
                } else {
                    params[val] = 1 
                }

                this.$store.commit("setState", { isLoadForLoad: true });
                vipHide(params).then(res => {
                    if (res.code === 0 || res.code === '0') {
                        let str = "";
                        if (val == 'roomHide') str = this.$t('my.noble.roomStealth');
                        if (val == 'chatHide') str = this.$t('my.noble.chatStealth');
                        if (val == 'rankHide') str = this.$t('my.noble.topStealth');

                        if (params[val]) {
                            this.$toast(`${this.$t('my.noble.turnedOn')}  ${str}`);
                        } else {
                            this.$toast(`${this.$t('my.noble.turnedOff')}  ${str}`);
                        }

                        this.vipInfo();
                        this.$store.dispatch('refreshUserInfo');
                    } else {
                        if (res.code == 14004 || res.code == 2008 || res.code == 14001 ) {
                            this.$dialog.confirm({
                                message: this.$t('my.noble.expiredTips'),
                                confirmButtonText: this.$t('common.confirm'),
                                cancelButtonText: this.$t('common.cancel'),
                                className: "confirmDialog",
                                confirmButtonColor: "#fa7c87"
                            }).then(() => {
                                this.$router.push('/mine/noble');
                            }).catch(() => {
                                // console.log("取消");
                            });
                        } else {
                            if (res.msg) this.$toast(res.msg);
                        }
                        this.$store.commit("setState", { isLoadForLoad: false });
                    }
                }).catch(err => {
                    this.$store.commit("setState", { isLoadForLoad: false });
                })
            }
        },
        formatDate(val) {
            if (val) {
                return moment(val).format('DD-MM-YYYY');
            } else {
                return "";
            }
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 升级点击
        upVipClick() {
            let that = this;
            this.$dialog.confirm({
                message: `${this.$t('my.noble.upgradeConfirmationTips')}`,
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.cancel'),
                className: "confirmDialog",
                confirmButtonColor: "#fa7c87"
            }).then(() => {
                that.upVip();
            }).catch(() => {
                // console.log("取消");
            });
        },
        // 升级
        upVip() {
            let params = {
                levelId: this.detail.levelId == 5 ? this.detail.levelId : (this.detail.levelId+1)
            }
            upVip(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('my.noble.upgradeSuccess'));
                    this.vipInfo();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => { })
        },
        // 续费
        reNewClick() {
            let that = this;
            this.$dialog.confirm({
                message: `${this.$t('my.noble.upgradeTips')}`,
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.cancel'),
                className: "confirmDialog",
                confirmButtonColor: "#fa7c87"
            }).then(() => {
                that.reNewVip();
            }).catch(() => {
                // console.log("取消");
            });
        },
        // 续费接口调用
        reNewVip() {
            let params = {
                levelId: this.detail.levelId
            }
            reNewVip(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('my.noble.renewSuccess'));
                    this.vipInfo();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => { })
        },
        // 获取用户贵族信息
        vipInfo() {
            let params = {
                uid: this.member.uid
            }
            vipInfo(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.detail = res.data;
                    this.configVip();
                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.$store.commit("setState", { isLoadForLoad: false });
                }
            }).catch(() => { 
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        // 获取贵族列表
        configVip() {
            configVip().then(res => {
                if (res.code === 0 || res.code === '0') {
                    let index = res.data.findIndex(item => {
                        return item.id == this.detail.levelId;
                    })

                    this.activeLevel.logoUrl = "";
                    this.allList = res.data;
                    if (index != -1) {
                        this.activeLevel = res.data[index];
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(err => {
                console.log(err);
                this.$store.commit("setState", { isLoadForLoad: false });
            });
        }
    }
}
</script>
  
<style lang="scss" scoped>
.myNoble {
    background-color: #230e15;
    color: #fff;
    font-size: 0.28rem;
    height: 25rem !important;
    padding-top: 0.88rem;
    padding-bottom: 0.4rem;

    &.next .title {
        box-shadow: 0 0 0 0 rgba($color: #000000, $alpha: 0);
        color: #fff;
        background-color: #23180e;

        &.level1 {
            background-color: #242313;
        }
        &.level2 {
            background-color: #0e1123;
        }

        &.level3 {
            background-color: #1f1432;
        }

        &.level4 {
            background-color: #23180e;
        }
        &.level5 {
            background-color: #230e15;
        }
    }

    .listTop {
        height: 5.02rem;

        &.level1 {
            background-color: #242313;
            background-image: url('../../../assets/img/noble/vip/level1/bg1.png');
        }
        &.level2 {
            background-color: #0e1123;
            background-image: url('../../../assets/img/noble/vip/level2/bg1.png');
        }

        &.level3 {
            background-color: #1f1432;
            background-image: url('../../../assets/img/noble/vip/level3/bg1.png');
        }

        &.level4 {
            background-color: #23180e;
            background-image: url('../../../assets/img/noble/vip/level4/bg1.png');
        }
        &.level5 {
            background-color: #230e15;
            background-image: url('../../../assets/img/noble/vip/level5/bg1.png');
        }

        background-repeat: no-repeat;
        background-size: contain;
        /* margin-top: 0.4rem; */
        background-position-y: 100%;

        img {
            width: 2.14rem;
            // height: 2.14rem;
            margin-top: 0.5rem;
        }

        p {
            margin: 0.2rem 0 0.3rem;
        }

        .funBtn {
            span {
                display: inline-block;
                padding: 0.1rem 0.4rem;
                background: -webkit-linear-gradient(#f9e8b3, #ffd195);
                background: linear-gradient(#f9e8b3, #ffd195);
                color: #8b632c;
                border-radius: 0.4rem;

                &:nth-child(1) {
                    margin-right: 1rem;
                }
            }
        }
    }

    .listBox {
        text-align: left;
        padding: 0.5rem 0.28rem 0;

        &.level1 {
            background-image: url('../../../assets/img/noble/vip/level1/bg2.png');
        }
        &.level2 {
            background-image: url('../../../assets/img/noble/vip/level2/bg2.png');
        }

        &.level3 {
            background-image: url('../../../assets/img/noble/vip/level3/bg2.png');
        }

        &.level4 {
            background-image: url('../../../assets/img/noble/vip/level4/bg2.png');
        }
        &.level5 {
            background-image: url('../../../assets/img/noble/vip/level5/bg2.png');
        }

        background-repeat: no-repeat;
        background-size: 100% 100%;
        vertical-align: top;
        margin-top: -0.2rem;

        .listItem {
            display: inline-block;
            width: 33.3%;
            text-align: center;
            margin-bottom: 0.5rem;
            padding: 0 0.2rem;
            vertical-align: top;
            opacity: 0.5;

            &.has {
                opacity: 1;
            }

            .listItemTitle {
                color: #eec97f;
                margin: 0.2rem 0;
            }

            .imgBox {
                width: 100%;
                height: 0.9rem;
            }

            .listItemDetail {
                font-size: 0.24rem;
                // color: #6f5e55;
                color: #fff;
            }

            img {
                width: 0.6rem;
            }
        }
    }

    .nobleNum {
        height: 3.3rem;
        &.level1 {
            background-image: url('../../../assets/img/noble/vip/level1/bg3.png');
        }
        &.level2 {
            background-image: url('../../../assets/img/noble/vip/level2/bg3.png');
        }

        &.level3 {
            background-image: url('../../../assets/img/noble/vip/level3/bg3.png');
        }

        &.level4 {
            background-image: url('../../../assets/img/noble/vip/level4/bg3.png');
        }
        &.level5 {
            background-image: url('../../../assets/img/noble/vip/level5/bg3.png');
        }
        
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-size: 0.4rem;
        padding-top: 0.5rem;

        p {
            margin-bottom: 0.35rem;

            &:nth-child(1) {
                color: #eec97f;
            }
        }
    }

    .invisibility {
        height: 5.8rem;
        
        &.level1 {
            background-image: url('../../../assets/img/noble/vip/level1/bg4.png');
        }
        &.level2 {
            background-image: url('../../../assets/img/noble/vip/level2/bg4.png');
        }

        &.level3 {
            background-image: url('../../../assets/img/noble/vip/level3/bg4.png');
        }

        &.level4 {
            background-image: url('../../../assets/img/noble/vip/level4/bg4.png');
        }
        &.level5 {
            background-image: url('../../../assets/img/noble/vip/level5/bg4.png');
        }
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 0.6rem;

        p {
            font-size: 0.44rem;
            color: #eec97f;
        }

        div {
            font-size: 0.32rem;
            width: calc(100% - 0.56rem);
            height: 0.84rem;
            line-height: 0.84rem;
            margin: 0.4rem auto 0;
            border-radius: 0.1rem;

            &.default {
                background-color: #c5c5c5;
                color: #666;
            }

            &:last-child {
                margin-bottom: 0.4rem;
            }

            &.turnOn {
                background: -webkit-linear-gradient(#f9e8b3, #ffd195);
                background: linear-gradient(#f9e8b3, #ffd195);
                color: #8b632c;
            }
        }
    }
}
</style>