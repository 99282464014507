<template>
    <div class="next withdraw">
        <div class="title">
            <span> {{ $t("my.finance.goldWithdrawal") }} </span>
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="withdrawBox">
            <div @click="$router.push('/record/withdraw')" class="withdrawRecord"> {{ $t("my.finance.withdrawalRecords") }} </div>
            <div class="gold flexCenterBL">
                <img src="../../../assets/img/gold.png" />
                {{ $t("my.finance.currency") }}
            </div>
            <div class="goldNum"> {{ member && member.goldCoin ? numFormat(member.goldCoin) : '0' }} </div>
            <div class="quota">{{ $t("my.finance.withdrawableAmount") }}： {{ numFormat(statementObj.goinCoin*baseInfo.currency) }} {{ baseInfo.currencySymbol }}</div>
            <div>{{ $t("my.finance.currentTotalTurnover") }}：{{ numFormat(statementObj.allNowStatement*baseInfo.currency) }} {{ baseInfo.currencySymbol }}</div>
            <div>{{ $t("my.finance.totalHistory") }}：{{ numFormat(statementObj.allStatement*baseInfo.currency) }} {{ baseInfo.currencySymbol }}</div>
            <div @click="$router.push('/mine/finance/withdraw/next')" class="withdrawBtn">{{ $t("my.finance.withdrawal") }}</div>
            <div>{{ $t("my.finance.currencyExchangeRate") }}：1{{ $t("my.finance.currency") }}= {{ numFormat(baseInfo.currency) }} {{ baseInfo.currencySymbol }}</div>
            <div>{{ $t("my.finance.betAmountThan") }} {{ numFormat(statementObj.needStatement*baseInfo.currency) }}{{ baseInfo.currencySymbol }}  {{ $t("my.finance.betAmountThan1") }}</div>
            <div>{{ $t("my.finance.promotionBonus") }}：{{ statementObj.activityGoinCoin }} {{ $t("my.finance.currency") }}</div>
            <div>{{ $t("my.finance.promotionalWinnings") }}：{{ statementObj.activityGoinCoinRecord }} {{ $t("my.finance.currency") }}</div>
        </div>

        <div class="rule">
            <div>{{ $t("my.finance.withdrawalRules") }}</div>
            <div class="ruleCont" v-html="statementObj.withDrawContent"></div>
        </div>
    </div>
</template>

<script>
import { statement } from "@/api/index";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            statementObj: {}
        }
    },
    created() {
        this.statement();
    },
    computed: {
        ...mapState(['member', 'baseInfo', 'entry']),
    },
    methods: {
        goBack() {
            this.$router.push(this.entry);
        },
        statement() {
            statement().then(res => {
                if (res.code === 0 || res.code === '0') {
                    let obj = JSON.parse(JSON.stringify(res.data));
                    obj.withDrawContent = obj.withDrawContent.replace(/\n/g,'<br/>');
                    this.statementObj = JSON.parse(JSON.stringify(obj));
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.withdraw {
    background-color: #fff;
    padding-top: 0.92rem;

    .withdrawBox {
        margin: 0.4rem auto 0;
        width: calc(100% - 0.56rem);
        padding: 0.4rem;
        // background: -webkit-linear-gradient(left, #fe9194, #ffce97);
        // background: linear-gradient(90deg, #fe9194, #ffce97);
        background-image: url("../../../assets/img/my/next/withdraw.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 0.1rem;
        color: #fff;
        font-size: 0.24rem;

        div {
            margin: 0.12rem 0;
        }

        .withdrawRecord {
            text-align: right;
        }

        .gold {
            img {
                width: 0.28rem;
                height: 0.28rem;
                margin-right: 0.1rem;
            }
        }

        .goldNum {
            font-size: 0.64rem;
            margin: 0.2rem 0;
        }

        .quota {
            background-color: #fa7c87;
            display: inline-block;
            padding: 0.05rem 0.6rem;
            border-radius: 0.2rem;
            font-size: 0.28rem;
        }

        .withdrawBtn {
            display: inline-block;
            color: #fa7c87;
            background-color: #fff;
            padding: 0.05rem 0.6rem;
            border-radius: 0.2rem;
            font-size: 0.28rem;
        }
    }

    .rule {
        font-size: 0.28rem;
        margin-top: 0.4rem;

        div {
            margin-top: 0.2rem;
            padding: 0 0.28rem;
            color: #333;

            &.ruleCont {
                color: #666;
            }

            &:last-child {
                text-align: left;
            }
        }
    }

    
}

</style>

<style lang="scss">
.withdraw ol {
    list-style: inside;
    list-style-type: decimal;
}
</style>
